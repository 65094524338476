import { CloudCredentialsV2Query } from "src/graphql";

import { BIGQUERY_USER_ROLE, BIGQUERY_DATAVIEWER_ROLE } from "./bigquery";
import {
  DEFAULT_LIGHTNING_CODE_TITLE,
  DEFAULT_LIGHTNING_REASON,
} from "./default";
import {
  DEFAULT_LIGHTNING_SCHEMA_CONFIG,
  WarehouseSchemaConfig,
} from "src/components/sources/setup/form-method";

export type Context = {
  definitionName: string;
  configuration: Record<string, unknown> | undefined;
  credential: CloudCredentialsV2Query["getCloudCredentials"][0] | undefined;
  plannerDatabase: string | undefined;
  schema?: WarehouseSchemaConfig;
};

export type Code = {
  title: (ctx: Context) => string;
  code: (ctx: Context) => string;
};

export type Permissions = {
  default?: { reason: (ctx: Context) => string; code: Code[] };
  lightning?: { reason: (ctx: Context) => string; code: Code[] };
};

export const DEFAULT_PERMISSIONS: Permissions = {
  lightning: {
    reason: DEFAULT_LIGHTNING_REASON,
    code: [
      {
        code: (ctx) => {
          const schemaNames = Array.from(
            new Set(
              Object.values(ctx.schema ?? DEFAULT_LIGHTNING_SCHEMA_CONFIG),
            ),
          ).sort();
          return [
            "CREATE USER hightouch_user WITH PASSWORD '********';",
            ...schemaNames.map(
              (schema) => `CREATE SCHEMA IF NOT EXISTS ${schema};`,
            ),
            ...schemaNames.map(
              (schema) =>
                `GRANT CREATE, USAGE ON SCHEMA ${schema} TO hightouch_user;`,
            ),
          ].join("\n");
        },
        title: DEFAULT_LIGHTNING_CODE_TITLE,
      },
    ],
  },
};

function getSchemaLabel(schema: WarehouseSchemaConfig) {
  const schemaNames = Array.from(new Set(Object.values(schema))).sort();
  const fmtSchema = (name) => `\`${name}\``;
  return schemaNames.length === 1
    ? `a schema (${fmtSchema(schemaNames)})`
    : `schemas (${schemaNames
        .slice(0, schemaNames.length - 1)
        .map(fmtSchema)
        .join(", ")} and ${fmtSchema(schemaNames.at(-1))})`;
}

export const PERMISSIONS: Record<string, Permissions> = {
  snowflake: {
    lightning: {
      reason: ({ schema = DEFAULT_LIGHTNING_SCHEMA_CONFIG }) => {
        const schemaLabel = getSchemaLabel(schema);
        return [
          `This SQL snippet creates a dedicated Snowflake user and role for Hightouch.`,
          `It also provisions ${schemaLabel} for storing logs of previously-synced data.`,
        ].join(" ");
      },
      code: [
        {
          code: ({
            configuration,
            schema = DEFAULT_LIGHTNING_SCHEMA_CONFIG,
          }) => {
            const schemaNames = Array.from(
              new Set(Object.values(schema)),
            ).sort();
            return [
              `CREATE USER HIGHTOUCH_USER PASSWORD = '********';`,
              `CREATE ROLE IF NOT EXISTS HIGHTOUCH_ROLE;`,
              `GRANT ROLE HIGHTOUCH_ROLE TO USER HIGHTOUCH_USER;`,
              `GRANT USAGE ON DATABASE ${
                configuration?.database || "<YOUR DATABASE>"
              } TO HIGHTOUCH_ROLE;`,
              `GRANT USAGE ON WAREHOUSE ${
                configuration?.warehouse || "<YOUR WAREHOUSE>"
              } TO HIGHTOUCH_ROLE;`,
              ...schemaNames.map(
                (s) => `CREATE SCHEMA IF NOT EXISTS ${s.toUpperCase()};`,
              ),
              ...schemaNames.map(
                (s) =>
                  `GRANT OWNERSHIP ON SCHEMA ${s.toUpperCase()} TO HIGHTOUCH_ROLE;`,
              ),
            ].join("\n");
          },
          title: DEFAULT_LIGHTNING_CODE_TITLE,
        },
      ],
    },
  },
  databricks: {
    lightning: {
      reason: ({ schema = DEFAULT_LIGHTNING_SCHEMA_CONFIG }) =>
        [
          `This SQL snippet provisions ${getSchemaLabel(
            schema,
          )} for storing logs of previously-synced data, which helps improve sync speed.`,
          `Then, it grants the necessary permissions to your Databricks user or service principal.`,
          `Before running the snippet, make sure to include your own username, which can be found in the top right corner of the Databricks web console.`,
          `Alternatively, you can create an API-only service principal by following [these instructions](https://docs.databricks.com/administration-guide/users-groups/service-principals.html).`,
        ].join(" "),
      code: [
        {
          code: ({ schema = DEFAULT_LIGHTNING_SCHEMA_CONFIG }) => {
            const schemaNames = Array.from(
              new Set(Object.values(schema ?? DEFAULT_LIGHTNING_SCHEMA_CONFIG)),
            ).sort();
            return [
              ...schemaNames.map((s) => `CREATE SCHEMA IF NOT EXISTS ${s};`),
              ...schemaNames.map(
                (s) => `GRANT ALL PRIVILEGES ON SCHEMA ${s} TO <YOUR_USER>;`,
              ),
            ].join("\n");
          },
          title: DEFAULT_LIGHTNING_CODE_TITLE,
        },
      ],
    },
  },
  bigquery: {
    lightning: {
      reason: ({ schema = DEFAULT_LIGHTNING_SCHEMA_CONFIG }) =>
        [
          `By default, your GCP service account does not have permission to read data from BigQuery.`,
          `This access needs to be granted by assigning the \`bigquery.user\` and \`bigquery.dataViewer\` roles to the service account.`,
          `You can do this in the Google Cloud web console or by running these snippets in the Cloud Shell.`,
          `After doing so, run the last SQL snippet to provision ${getSchemaLabel(
            schema,
          )}, used for storing logs of previously-synced data, in the BigQuery Studio SQL editor.`,
        ].join(" "),
      code: [
        BIGQUERY_USER_ROLE,
        BIGQUERY_DATAVIEWER_ROLE,
        {
          code: ({ credential, plannerDatabase, configuration, schema }) => {
            let database = plannerDatabase || configuration?.project || "";
            if (database) {
              database += ".";
            }

            const account =
              credential?.stripped_config.client_email ||
              "<YOUR_SERVICE_ACCOUNT>";
            const schemaNames = Array.from(
              new Set(Object.values(schema ?? DEFAULT_LIGHTNING_SCHEMA_CONFIG)),
            ).sort();
            return [
              ...schemaNames.map(
                (s) => `CREATE SCHEMA IF NOT EXISTS \`${database}${s}\`;`,
              ),
              ...schemaNames.map(
                (s) =>
                  `GRANT \`roles/bigquery.dataViewer\`, \`roles/bigquery.dataEditor\` ON SCHEMA \`${database}${s}\` TO "serviceAccount:${account}";`,
              ),
            ].join("\n");
          },
          title: () =>
            "Create schemas for Lightning sync engine and grant permission to read/write data",
        },
      ],
    },
    default: {
      reason: () =>
        `By default, your GCP service account does not have permission to read data from BigQuery. This access needs to be granted by assigning the \`bigquery.user\` and \`bigquery.dataViewer\` roles to the service account. You can do this in the Google Cloud web console or by running these snippets in the Cloud Shell.`,
      code: [BIGQUERY_USER_ROLE, BIGQUERY_DATAVIEWER_ROLE],
    },
  },
  // It would be nice to switch on the configuration.connector here and provide
  // connector-specific setup instructions.
  trino: {
    lightning: {
      reason: ({ schema = DEFAULT_LIGHTNING_SCHEMA_CONFIG }) => {
        const schemaNames = new Set(Object.values(schema));
        const schemaLabel = schemaNames.size === 1 ? "a schema" : "schemas";
        return [
          `This SQL snippet provisions ${schemaLabel} for storing logs of previously-synced data.`,
          `This snippet applies to a Hive connector and needs to be run in Trino for a specific catalog.`,
          `Modify as needed for your connector.`,
        ].join(" ");
      },
      code: [
        {
          code: (ctx) => {
            const catalog = ctx.configuration?.catalog ?? "<catalog>";
            const schemaNames = Array.from(
              new Set(
                Object.values(ctx.schema ?? DEFAULT_LIGHTNING_SCHEMA_CONFIG),
              ),
            ).sort();
            return [
              ...schemaNames.map(
                (schema) => `CREATE SCHEMA IF NOT EXISTS ${catalog}.${schema};`,
              ),
            ].join("\n");
          },
          title: DEFAULT_LIGHTNING_CODE_TITLE,
        },
      ],
    },
  },
};
