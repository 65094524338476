import { FC, useCallback } from "react";

import { Heading, Row, Text } from "@hightouchio/ui";
import { LinkButton } from "src/router";
import { capitalize } from "lodash";
import { useNavigate } from "src/router";

import genericPlaceholder from "src/assets/placeholders/generic.svg";
import {
  EventForwardingDestinationType,
  eventWarehouseDestinationDefinitions,
} from "src/events/types";
import { useEventDestinationsQuery } from "src/graphql";
import { PageTable, TableColumn } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { openUrl } from "src/utils/urls";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { Page } from "src/components/layout";

export const EventDestinations: FC = () => {
  const navigate = useNavigate();
  const {
    data: destinations,
    isFetching,
    error,
  } = useEventDestinationsQuery(
    { forwardingTypes: Object.values(EventForwardingDestinationType) },
    {
      select: (data) => [
        ...data.event_warehouse_destinations,
        ...data.destinations,
      ],
    },
  );

  const onRowClick = useCallback(
    (destination, event) =>
      openUrl(`/events/destinations/${destination.id}`, navigate, event),
    [navigate],
  );

  return (
    <Page title="Event destinations" sx={{ p: 0 }}>
      <PageTable
        header={
          <>
            <Heading isTruncated size="xl">
              Event destinations
            </Heading>
            <Row>
              <LinkButton
                href="/events/destinations/new"
                variant="primary"
                onClick={() => {}}
              >
                Create destination
              </LinkButton>
            </Row>
          </>
        }
        columns={columns}
        data={destinations}
        error={Boolean(error)}
        loading={isFetching}
        placeholder={placeholder}
        onRowClick={onRowClick}
      />
    </Page>
  );
};

const columns: TableColumn[] = [
  {
    name: "Name",
    cell: (destination) => {
      const definition =
        destination.definition ??
        eventWarehouseDestinationDefinitions[destination.type];

      return (
        <TextWithTooltip fontWeight="medium">
          {destination.name ?? definition.name}
        </TextWithTooltip>
      );
    },
  },
  {
    name: "Type",
    cell: (destination) => {
      const definition =
        destination.definition ??
        eventWarehouseDestinationDefinitions[destination.type];
      const name = definition?.name ?? capitalize(destination.type);
      return (
        <Row align="center" gap={2}>
          <IntegrationIcon name={name} src={definition?.icon} />
          <Text fontWeight="medium">{name}</Text>
        </Row>
      );
    },
  },
  LastUpdatedColumn,
];

const placeholder = {
  image: genericPlaceholder,
  title: "No event destinations",
  body: "Create a new event destination",
  error: "Event destinations failed to load, please try again.",
};
