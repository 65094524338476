export type DemoDefinition = {
  logo_url: string;
  from_name: string;
  // uuid, message definition
  messages: Record<string, DemoMessageDefinition>;
  // uuid, collection definition
  collections: Record<string, DemoCollectionDefinition>;
};

type DemoCollectionDefinition = {
  items: { name: string; image: string }[];
};

type WithoutConfigurableVariables<T> = Omit<T, "configurable_variables">;

type DemoMessageDefinition = WithoutConfigurableVariables<
  Variant1Definition | Variant2Definition | Variant3Definition
>;

// This variant has:
// 1. A hero section with an image and heading
// 2. A subheading section
// 3. A recommendation section with 4 items (could be categories, products, etc.)
// 4. A call to action button
type Variant1Definition = {
  variant: 1;
  base_variables: {
    hero_image: string;
  };
  configurable_variables: {
    subject: string[];
    heading: string[];
    subheading: string[];
    cta: string[];
  };
};

// This variant has:
// 1. A hero section with an image and heading and call to action link
// 2. A subheading section with the subheading and 3 reasons to use the product
// 3. A callout section with a callout message and a call to action button
type Variant2Definition = {
  variant: 2;
  base_variables: {
    hero_image: string;
    reason_1: string;
    reason_2: string;
    reason_3: string;
    callout: string;
  };
  configurable_variables: {
    subject: string[];
    heading: string[];
    subheading: string[];
    hero_cta: string[];
    cta: string[];
  };
};

// This variant has:
// 1. A hero section with an image and heading and call to action link
// 2. A content section with a title, block of text and an image
// 3. A callout section with a callout message and a call to action button
type Variant3Definition = {
  variant: 3;
  base_variables: {
    hero_image: string;
    block_title: string;
    block_content: string;
    block_image: string;
    callout: string;
  };
  configurable_variables: {
    subject: string[];
    heading: string[];
    hero_cta: string[];
    cta: string[];
  };
};

export const EXAMPLE_DEMO_DEFINITION: DemoDefinition = {
  logo_url:
    "https://upload.wikimedia.org/wikipedia/commons/thumb/7/75/Deezer_logo%2C_2023.svg/1024px-Deezer_logo%2C_2023.svg.png",
  from_name: "Deezer",
  messages: {
    "4b4ffba2-444d-4aa7-b1aa-b43f7ae876c7": {
      variant: 1,
      base_variables: {
        hero_image:
          "https://images.unsplash.com/photo-1630713815198-17ff8a8a7103?q=80&w=3270&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },
    },
    "cff373c5-a0a9-456f-b3f3-f9c27006dab4": {
      variant: 2,
      base_variables: {
        hero_image:
          "https://images.unsplash.com/photo-1478147427282-58a87a120781?q=80&w=3570&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        reason_1: "Ad-free streaming for uninterrupted listening",
        reason_2: "Offline mode to enjoy your music anywhere",
        reason_3: "Unlimited skips to keep the music fresh",
        callout: "Upgrade today for the ultimate music experience on Deezer!",
      },
    },
    "bd75c5d4-5cf7-4256-b8c0-f9b45a8b4cd0": {
      variant: 3,
      base_variables: {
        hero_image:
          "https://images.unsplash.com/photo-1478147427282-58a87a120781?q=80&w=3570&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        block_title: "No Ads, Just Music",
        block_content:
          "Enjoy a seamless listening experience with Deezer Premium. Say goodbye to interruptions and hello to endless music, all without the ads.",
        block_image:
          "https://images.unsplash.com/photo-1415201364774-f6f0bb35f28f?q=80&w=600&h=600&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        callout:
          "Upgrade to Deezer Premium and enjoy the music you love without limits!",
      },
    },
  },

  collections: {
    "234fd47b-a5a9-45d7-8292-e4b2cd8b44be": {
      items: [
        {
          name: "Rock",
          image:
            "https://images.unsplash.com/photo-1629276301313-7a7266703011?q=80&h=800&w=800&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {
          name: "Electronic",
          image:
            "https://images.unsplash.com/photo-1535979014625-490762ceb2ff?q=80&w=800&h=800&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {
          name: "Pop",
          image:
            "https://images.unsplash.com/photo-1512830414785-9928e23475dc?q=80&w=800&h=800&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {
          name: "Classical",
          image:
            "https://images.unsplash.com/photo-1690742319740-9472091ed48f?q=80&w=800&h=800&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
      ],
    },
  },
};

export const RANDOM_NAMES = [
  "Olivia Johnson",
  "Liam Smith",
  "Emma Brown",
  "Noah Jones",
  "Ava Garcia",
  "Elijah Martinez",
  "Sophia Rodriguez",
  "James Wilson",
  "Isabella Anderson",
  "Benjamin Thomas",
  "Charlotte Taylor",
  "Lucas Moore",
  "Mia Jackson",
  "Henry Martin",
  "Amelia White",
  "Alexander Harris",
  "Harper Thompson",
  "Michael Lopez",
  "Evelyn Clark",
  "Daniel King",
  "Abigail Wright",
  "Matthew Scott",
  "Ella Torres",
  "Joseph Nguyen",
  "Scarlett Perez",
  "David Rivera",
  "Avery Jenkins",
  "Gabriel Kelly",
  "Sofia Gray",
  "Samuel Ramirez",
  "Aria Foster",
  "Nathan Gonzalez",
  "Zoey Bailey",
  "Andrew Cooper",
  "Penelope Sanders",
  "Christopher Howard",
  "Lillian Long",
  "Joshua Ward",
  "Mila Flores",
  "Ryan Cox",
  "Hannah Rivera",
  "Caleb Hughes",
  "Luna Reed",
  "Nicholas Peterson",
  "Layla Collins",
  "Isaac Morgan",
  "Chloe Bell",
  "Jonathan Murphy",
  "Ellie Martinez",
  "Aaron Nguyen",
  "Nora Diaz",
  "Ethan Kim",
  "Madison Coleman",
  "Anthony Cooper",
  "Grace Ramirez",
  "Charles Watson",
  "Hazel Rogers",
  "Thomas Brooks",
  "Aurora Simmons",
  "Sebastian Hernandez",
  "Victoria Russell",
  "Jack Sanders",
  "Savannah Baker",
  "Adam Griffin",
  "Zoey Hughes",
  "Jose Bennett",
  "Bella Henderson",
  "Aiden Crawford",
  "Lucy Perry",
  "Owen Butler",
  "Paisley Myers",
  "Leo Barnes",
  "Addison Freeman",
  "Julian Ross",
  "Eleanor Hudson",
  "Levi Edwards",
  "Willow Perkins",
  "Isaiah Morales",
  "Lily Ortiz",
  "David James",
  "Samantha Carter",
  "Christopher Bailey",
  "Violet Diaz",
  "Jack Cooper",
  "Stella Hall",
  "Robert Bell",
  "Zoe Mitchell",
  "Kevin Parker",
  "Riley Ward",
  "Thomas Lee",
  "Natalie Hughes",
  "William Coleman",
  "Ruby Phillips",
  "John Hayes",
  "Alice Walker",
  "Jacob Sanchez",
  "Emilia Bennett",
  "Logan Gray",
  "Hailey Butler",
  "Luke Reed",
  "Ivy Howard",
  "Mason Collins",
  "Sophie Ward",
  "Matthew Hernandez",
];
