import { FC } from "react";

import { TraitConfig, TraitType } from "@hightouch/lib/query/visual/types";
import {
  Column,
  DrawerBody,
  DrawerFooter,
  Row,
  Text,
  useToast,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import { useNavigate, useParams } from "src/router";

import { Drawer } from "src/components/drawer";
import { PermissionedButton } from "src/components/permission";
import { useRestoreTraitMutation, useTraitQuery } from "src/graphql";
import { NotFound } from "src/pages/not-found";
import { TraitType as TraitPageType } from "src/pages/traits/types";

import { useResourcePermission } from "../permission/use-resource-permission";
import { ArchiveTrait } from "./archive-trait";
import { CalculationSummary } from "./calculation-summary";
import { DeleteTrait } from "./delete-trait";
import { Header } from "./header";
import { PreviewTrait } from "./preview-trait";
import { UsageTable } from "./usage-table";

type Props = {
  type: TraitPageType;
};

export const ViewTrait: FC<Readonly<Props>> = ({ type }) => {
  const { traitId } = useParams();
  const navigate = useNavigate();
  const { toast } = useToast();

  const { data, isLoading } = useTraitQuery(
    { id: traitId ?? "" },
    { enabled: traitId != null },
  );
  const restoreTrait = useRestoreTraitMutation();

  const trait = data?.trait_definitions_by_pk;

  const { isPermitted: hasUpdatePermission } = useResourcePermission({
    v2: { resource: "model", grant: "can_update", id: trait?.parent_model.id },
    v1: { resource: "audience_schema", grant: "update" },
  });

  const onClose = () => {
    if (type === TraitPageType.Active) {
      navigate("/traits/active");
    } else if (type === TraitPageType.Templates) {
      navigate("/traits/templates");
    } else if (type === TraitPageType.Archived) {
      navigate("/traits/archived");
    }
  };

  const handleRestoreTrait = async () => {
    if (!trait?.id) return;

    try {
      await restoreTrait.mutateAsync({ id: trait.id });
      navigate(
        `/traits/${trait.is_template ? "templates" : "active"}/${trait.id}`,
      );

      toast({
        id: "restore-trait",
        title: "Trait was restored successfully",
        variant: "success",
      });
    } catch (error) {
      toast({
        id: "restore-trait",
        title: "Trait failed to be restored",
        variant: "error",
      });
      Sentry.captureException(error);
    }
  };

  return (
    <Drawer isOpen size="xl" isLoading={isLoading} onClose={onClose}>
      {!trait ? (
        <DrawerBody>
          <NotFound />
        </DrawerBody>
      ) : (
        <>
          <Row
            p={6}
            borderBottom="1px solid"
            borderColor="base.border"
            width="100%"
          >
            <Header
              id={trait.id}
              name={trait.name}
              description={trait.description}
              parentModel={trait.parent_model}
              isTemplate={trait.is_template}
              isArchived={Boolean(trait.archived_at)}
              isEditable={hasUpdatePermission}
              onClose={onClose}
            />
          </Row>

          <DrawerBody>
            <Column flex={1} overflowY="auto">
              <Row justifyContent="space-between">
                <Text fontWeight="medium">Calculation summary</Text>
                {!trait.archived_at && (
                  <PermissionedButton
                    permission={{
                      v1: { resource: "audience_schema", grant: "update" },
                      v2: {
                        resource: "model",
                        grant: "can_update",
                        id: trait.parent_model.id,
                      },
                    }}
                    size="sm"
                    onClick={() => navigate("edit")}
                  >
                    Edit
                  </PermissionedButton>
                )}
              </Row>
              <Row
                mt={2}
                borderRadius="6px"
                border="1px solid"
                borderColor="base.border"
                padding={4}
              >
                <CalculationSummary
                  type={trait.type as TraitType}
                  config={trait.config as TraitConfig}
                  model={trait.relationship?.to_model}
                />
              </Row>
              <Column mt={4} gap={2}>
                <PreviewTrait
                  parentModel={trait.parent_model}
                  trait={{
                    name: trait.name,
                    type: trait.type as TraitType,
                    config: trait.config,
                    relationshipId: trait.relationship?.id,
                  }}
                />
              </Column>
              {!trait.archived_at && (
                <Column mt={6}>
                  <Text fontWeight="medium" mb={4}>
                    Usage
                  </Text>
                  <UsageTable trait={trait} />
                </Column>
              )}
            </Column>
          </DrawerBody>

          <DrawerFooter>
            {trait?.archived_at ? (
              <>
                <PermissionedButton
                  size="lg"
                  permission={{
                    v1: { resource: "audience_schema", grant: "update" },
                    v2: {
                      resource: "model",
                      grant: "can_update",
                      id: trait.parent_model.id,
                    },
                  }}
                  isDisabled={restoreTrait.isLoading}
                  isLoading={restoreTrait.isLoading}
                  onClick={handleRestoreTrait}
                >
                  Restore trait
                </PermissionedButton>
                <DeleteTrait trait={trait} />
              </>
            ) : (
              <>
                {trait.is_template ? (
                  <PermissionedButton
                    variant="primary"
                    permission={{
                      v1: { resource: "audience_schema", grant: "create" },
                      v2: {
                        resource: "model",
                        grant: "can_update",
                        id: trait.parent_model.id,
                      },
                    }}
                    size="lg"
                    onClick={() =>
                      navigate(
                        `/traits/active/new_from_template?template_id=${trait.id}`,
                      )
                    }
                  >
                    Create new trait
                  </PermissionedButton>
                ) : (
                  <PermissionedButton
                    variant="primary"
                    permission={{
                      v1: { resource: "audience_schema", grant: "update" },
                      v2: {
                        resource: "model",
                        grant: "can_update",
                        id: trait.parent_model.id,
                      },
                    }}
                    size="lg"
                    onClick={() => navigate("duplicate")}
                  >
                    Duplicate
                  </PermissionedButton>
                )}
                <ArchiveTrait trait={trait} />
              </>
            )}
          </DrawerFooter>
        </>
      )}
    </Drawer>
  );
};
