import {
  AssetType,
  AttributionMethodType,
  AttributionTimeWindow,
  AudienceSplit,
  Column,
  ColumnReference,
  ColumnType,
  ColumnValue,
  EventTraitColumn,
  InlineAggregatedTrait,
  InlineFormulaTrait,
  InteractionLookbackWindow,
  InteractionType,
  RawColumn,
  RelatedColumn,
  MergedColumn,
  SplitSamplingType,
  TraitColumn,
  TransformedColumn,
  ValueType,
  isColumnTypeValue,
  isInlineTraitColumn,
  isMergedColumn,
  isRawColumn,
  isRelatedColumn,
  isTraitColumn,
  isTransformedColumn,
} from "@hightouch/lib/query/visual/types";
import {
  AndCondition,
  AndOrCondition,
  Condition,
  ConditionType,
  EventCondition,
  FunnelCondition,
  NumberOfCondition,
  OrCondition,
  PropertyCondition,
  ReferencedPropertyCondition,
  RootCondition,
  SegmentSetCondition,
  TimeType,
  Window,
} from "@hightouch/lib/query/visual/types/condition";
import {
  AttributionBasis,
  AttributionWindow,
  AudienceAggregationType,
  Goal,
  GoalConfig,
  UserDefinedMetricConfig,
  PerUserAggregationType,
  PredefinedMetric,
  SumMetricConfig,
} from "@hightouch/lib/query/visual/types/goals";
import {
  IntervalUnit,
  RelativeDirection,
} from "@hightouch/lib/query/visual/types/interval";
import {
  BooleanOperator,
  JsonArrayOperator,
  NumberOperator,
  Operator,
  StringOperator,
  TimestampOperator,
} from "@hightouch/lib/query/visual/types/operator";
import {
  RawSqlTraitConfig,
  TraitType,
} from "@hightouch/lib/query/visual/types/trait-definitions";

import {
  AudienceParentFragment,
  AudienceQuery,
  FilterableColumnFragment,
  RelationshipFragment,
  TraitDefinitionFragment,
} from "src/graphql";
import { Option } from "src/ui/select";

type Audience = AudienceQuery["segments_by_pk"];

type NonNullableAudience = NonNullable<Audience>;

export { AnalyticsFrequency } from "@hightouch/lib/query/visual/types/analytics";
export type { AnalyticsMetricDefinition } from "@hightouch/lib/query/visual/types/analytics";
export type {
  SizeCap,
  SplitTestDefinition,
  VisualQueryFilter,
} from "@hightouch/lib/query/visual/types/filter";
export { IntervalUnit } from "@hightouch/lib/query/visual/types/interval";
export type { IntervalValue } from "@hightouch/lib/query/visual/types/interval";
export type { Operator } from "@hightouch/lib/query/visual/types/operator";
export type { TimeRangeValue } from "@hightouch/lib/query/visual/types/time-range";
export { exhaustiveCheck } from "@hightouch/lib/util/exhaustive-check";
export {
  AssetType,
  AttributionBasis,
  AttributionMethodType,
  AttributionTimeWindow,
  AudienceAggregationType,
  BooleanOperator,
  ColumnType,
  ConditionType,
  InteractionType,
  NumberOperator,
  PerUserAggregationType,
  PredefinedMetric,
  RelativeDirection,
  SplitSamplingType,
  StringOperator,
  TimeType,
  TimestampOperator,
  TraitType,
  ValueType,
  isColumnTypeValue,
};
export type {
  AndCondition,
  AndOrCondition,
  AttributionWindow,
  Audience,
  AudienceParentFragment as AudienceParent,
  AudienceSplit,
  Column,
  ColumnReference,
  ColumnValue,
  Condition,
  EventCondition,
  EventTraitColumn,
  FilterableColumnFragment as FilterableColumn,
  FunnelCondition,
  Goal,
  GoalConfig,
  UserDefinedMetricConfig,
  InteractionLookbackWindow,
  MergedColumn,
  NonNullableAudience,
  NumberOfCondition,
  OrCondition,
  PropertyCondition,
  RawColumn,
  RawSqlTraitConfig,
  ReferencedPropertyCondition,
  RelatedColumn,
  RelationshipFragment as Relationship,
  RootCondition,
  SegmentSetCondition,
  SumMetricConfig,
  TraitColumn,
  TraitDefinitionFragment as TraitDefinition,
  TransformedColumn,
  Window,
};
export const ColumnDateTypes = [ColumnType.Date, ColumnType.Timestamp];

const operatorToLabel = (operator: string) => {
  return operator
    .replace(/([A-Z])/g, " $1")
    .toLowerCase()
    .trim();
};

export const stringOperatorOptions = Object.keys(StringOperator).map((key) => ({
  label: operatorToLabel(key),
  value: StringOperator[key],
}));

export const numberOperatorOptions = Object.keys(NumberOperator).map((key) => ({
  label: operatorToLabel(key),
  value: NumberOperator[key],
}));

export const numberOfOperatorOptions = [
  {
    label: "exactly",
    value: NumberOperator.Equals,
  },
  {
    label: "not",
    value: NumberOperator.DoesNotEqual,
  },
  { label: "greater than", value: NumberOperator.GreaterThan },
  { label: "less than", value: NumberOperator.LessThan },
  {
    label: "greater than or equal to",
    value: NumberOperator.GreaterThanOrEqualTo,
  },
  { label: "less than or equal to", value: NumberOperator.LessThanOrEqualTo },
];

export const eventOperatorOptions = [
  {
    label: "at least",
    value: NumberOperator.GreaterThanOrEqualTo,
  },
  { label: "at most", value: NumberOperator.LessThanOrEqualTo },
  { label: "exactly", value: NumberOperator.Equals },
];

export const segmentSetOperatorOptions = [
  {
    label: "Is included in",
    value: true,
  },
  {
    label: "Is not included in",
    value: false,
  },
];

export const timestampOperatorOptions = [
  { label: "before", value: TimestampOperator.Before },
  { label: "after", value: TimestampOperator.After },
  { label: "within", value: TimestampOperator.Within },
  { label: "not within", value: TimestampOperator.NotWithin },
  { label: "exists", value: TimestampOperator.Exists },
  { label: "does not exist", value: TimestampOperator.DoesNotExist },
  { label: "anniversary", value: TimestampOperator.Anniversary },
  { label: "between", value: TimestampOperator.BetweenInclusive },
  { label: "between (exclusive)", value: TimestampOperator.Between },
];

export const windowOperatorOptions = [
  {
    value: TimestampOperator.Within,
    label: "within",
  },
  {
    value: TimestampOperator.NotWithin,
    label: "not within",
  },
  {
    value: TimestampOperator.BetweenInclusive,
    label: "between",
  },
  {
    value: TimestampOperator.Between,
    label: "between (exclusive)",
  },
  {
    value: TimestampOperator.After,
    label: "after",
  },
  {
    value: TimestampOperator.Before,
    label: "before",
  },
];

export const funnelWindowOperatorOptions = [
  {
    value: TimestampOperator.Within,
    label: "within",
  },
  {
    value: TimestampOperator.NotWithin,
    label: "not within",
  },
  {
    value: TimestampOperator.BetweenInclusive,
    label: "between",
  },
  {
    value: TimestampOperator.Between,
    label: "between (exclusive)",
  },
];

export const timestampSuggestionsOperatorOptions = [
  { label: "before", value: TimestampOperator.Before },
  { label: "after", value: TimestampOperator.After },
  { label: "exists", value: TimestampOperator.Exists },
  { label: "does not exist", value: TimestampOperator.DoesNotExist },
  { label: "anniversary", value: TimestampOperator.Anniversary },
  { label: "between", value: TimestampOperator.Between },
];

export const booleanOperatorOptions = [
  { label: "equals", value: BooleanOperator.Equals },
  { label: "does not equal", value: BooleanOperator.DoesNotEqual },
  { label: "exists", value: BooleanOperator.Exists },
  { label: "does not exist", value: BooleanOperator.DoesNotExist },
];

export const jsonArrayNumbersOperatorOptions = [
  {
    label: "array contains",
    subLabel: "array contains one of the input values",
    value: JsonArrayOperator.Contains,
  },
  {
    label: "array does not contain",
    subLabel: "array does not contain any of the input values",
    value: JsonArrayOperator.DoesNotContain,
  },
  {
    label: "array is not empty",
    subLabel: "array contains at least one non-null value",
    value: JsonArrayOperator.ContainsNonNull,
    supportedSources: ["postgres", "snowflake", "bigquery", "databricks"],
  },
];
export const jsonArrayStringsOperatorOptions = [
  {
    label: "array contains (exact)",
    subLabel:
      "array contains an item which exactly matches one of the input values",
    value: JsonArrayOperator.Contains,
  },
  {
    label: "array does not contain (exact)",
    subLabel:
      "array does not contain any items which exactly match any of the input values",
    value: JsonArrayOperator.DoesNotContain,
  },
  {
    label: "array contains (partial)",
    subLabel:
      "array contains an item which partially matches one of the input values",
    value: JsonArrayOperator.ContainsLike,
    supportedSources: ["postgres", "snowflake", "bigquery"],
  },
  {
    label: "array does not contain (partial)",
    subLabel:
      "array does not contain any items which partially match any of the input values",
    value: JsonArrayOperator.DoesNotContainLike,
    supportedSources: ["postgres", "snowflake", "bigquery"],
  },
  {
    label: "array is not empty",
    subLabel: "array contains at least one non-null value",
    value: JsonArrayOperator.ContainsNonNull,
    supportedSources: ["postgres", "snowflake", "bigquery", "databricks"],
  },
];

export const DateIntervalOptions = [
  {
    value: IntervalUnit.Day,
    label: "day(s)",
  },
  {
    value: IntervalUnit.Week,
    label: "week(s)",
  },
  {
    value: IntervalUnit.Month,
    label: "month(s)",
  },
  {
    value: IntervalUnit.Year,
    label: "year(s)",
  },
];

export const IntervalOptions = [
  {
    value: IntervalUnit.Minute,
    label: "minute(s)",
  },
  {
    value: IntervalUnit.Hour,
    label: "hour(s)",
  },
  ...DateIntervalOptions,
];

export const OperatorOptions: Record<ColumnType, Array<Option>> = {
  [ColumnType.Boolean]: booleanOperatorOptions,
  [ColumnType.Number]: numberOperatorOptions,
  [ColumnType.BigInt]: numberOperatorOptions,
  [ColumnType.String]: stringOperatorOptions,
  [ColumnType.Timestamp]: timestampOperatorOptions,
  [ColumnType.Date]: timestampOperatorOptions,
  [ColumnType.JsonArrayStrings]: jsonArrayStringsOperatorOptions,
  [ColumnType.JsonArrayNumbers]: jsonArrayNumbersOperatorOptions,
  // Not supported
  [ColumnType.Unknown]: stringOperatorOptions,
  [ColumnType.Json]: stringOperatorOptions,
  [ColumnType.Null]: stringOperatorOptions,
};

const referenceStringOperatorOptions = [
  {
    label: "equals",
    value: StringOperator.Equals,
  },
  {
    label: "does not equal",
    value: StringOperator.DoesNotEqual,
  },
];

const referenceTimestampOperatorOptions = [
  { label: "before", value: TimestampOperator.Before },
  { label: "after", value: TimestampOperator.After },
];

export const ReferencePropertyOperatorOptions: Record<
  ColumnType,
  Array<Option>
> = {
  [ColumnType.Boolean]: [
    { label: "equals", value: BooleanOperator.Equals },
    { label: "does not equal", value: BooleanOperator.DoesNotEqual },
  ],
  [ColumnType.Number]: numberOperatorOptions,
  [ColumnType.BigInt]: numberOperatorOptions,
  [ColumnType.String]: referenceStringOperatorOptions,
  [ColumnType.Timestamp]: referenceTimestampOperatorOptions,
  [ColumnType.Date]: referenceTimestampOperatorOptions,
  // Not supported
  [ColumnType.JsonArrayStrings]: jsonArrayStringsOperatorOptions,
  [ColumnType.JsonArrayNumbers]: jsonArrayNumbersOperatorOptions,
  [ColumnType.Unknown]: referenceStringOperatorOptions,
  [ColumnType.Json]: referenceStringOperatorOptions,
  [ColumnType.Null]: referenceStringOperatorOptions,
};

export const ReferencePropertyDefaultOperators: Record<ColumnType, string> = {
  [ColumnType.Boolean]: BooleanOperator.Equals,
  [ColumnType.Number]: NumberOperator.Equals,
  [ColumnType.BigInt]: NumberOperator.Equals,
  [ColumnType.String]: StringOperator.Equals,
  [ColumnType.Timestamp]: TimestampOperator.Before,
  [ColumnType.Date]: TimestampOperator.Before,
  // Not supported
  [ColumnType.JsonArrayStrings]: JsonArrayOperator.Contains,
  [ColumnType.JsonArrayNumbers]: JsonArrayOperator.Contains,
  [ColumnType.Unknown]: StringOperator.Equals,
  [ColumnType.Json]: StringOperator.Equals,
  [ColumnType.Null]: StringOperator.Equals,
};

export const DefaultOperators: Record<ColumnType, string> = {
  [ColumnType.Boolean]: BooleanOperator.Equals,
  [ColumnType.Number]: NumberOperator.Equals,
  [ColumnType.BigInt]: NumberOperator.Equals,
  [ColumnType.String]: StringOperator.Equals,
  [ColumnType.Timestamp]: TimestampOperator.Within,
  [ColumnType.Date]: TimestampOperator.Within,
  [ColumnType.JsonArrayStrings]: JsonArrayOperator.Contains,
  [ColumnType.JsonArrayNumbers]: JsonArrayOperator.Contains,
  // Not supported
  [ColumnType.Unknown]: StringOperator.Equals,
  [ColumnType.Json]: StringOperator.Equals,
  [ColumnType.Null]: StringOperator.Equals,
};

export const OperatorsWithoutValue = [
  StringOperator.Exists,
  StringOperator.ExistsAndIsNotEmpty,
  NumberOperator.Exists,
  TimestampOperator.Exists,
  TimestampOperator.DoesNotExist,
  TimestampOperator.Anniversary,
  JsonArrayOperator.ContainsNonNull,
];

export const IntervalOperators = [
  TimestampOperator.Within,
  TimestampOperator.NotWithin,
  TimestampOperator.Between,
  TimestampOperator.BetweenInclusive,
];

export const AbsoluteRelativeTimestampOperators = [
  TimestampOperator.Before,
  TimestampOperator.After,
  TimestampOperator.Between,
  TimestampOperator.BetweenInclusive,
];

export const RelativeOnlyTimestampOperators = [
  TimestampOperator.Within,
  TimestampOperator.NotWithin,
];

export const initialPropertyCondition: PropertyCondition = {
  type: ConditionType.Property,
  propertyType: null,
  property: null,
  operator: null,
  value: null,
};

export const initialRootPropertyCondition: {
  type: ConditionType.And;
  conditions: PropertyCondition[];
} = {
  type: ConditionType.And,
  conditions: [initialPropertyCondition],
};

export const initialEventCondition: EventCondition = {
  type: ConditionType.Event,
  operator: NumberOperator.GreaterThanOrEqualTo,
  value: 1,
  eventModelId: null,
  relationshipId: null,
  subconditions: [],
};

export const initialEventWindow: Window = {
  operator: TimestampOperator.Within,
  timeType: TimeType.Relative,
  value: {
    interval: IntervalUnit.Day,
    quantity: 7,
    direction: RelativeDirection.Backward,
  },
};

export const initialNumberOfCondition: NumberOfCondition = {
  type: ConditionType.NumberOf,
  relationshipId: null,
  operator: NumberOperator.GreaterThanOrEqualTo,
  value: 1,
  subconditions: [],
};

export const initialSetCondition: SegmentSetCondition = {
  type: ConditionType.SegmentSet,
  modelId: null,
  includes: true,
};

export const initialFunnelCondition: FunnelCondition = {
  type: ConditionType.Funnel,
  eventModelId: null,
  relationshipId: null,
  didPerform: false,
  subconditions: [],
};

export const initialFunnelWindow: Window = {
  operator: TimestampOperator.Within,
  timeType: TimeType.Relative,
  value: {
    interval: IntervalUnit.Day,
    quantity: 7,
    direction: RelativeDirection.Forward,
  },
};

export const MultiValueColumnTypes = [
  ColumnType.String,
  ColumnType.Number,
  ColumnType.JsonArrayStrings,
  ColumnType.JsonArrayNumbers,
];

export const MultiValueOperatorsByColumnType = {
  [ColumnType.String]: [
    StringOperator.Equals,
    StringOperator.DoesNotEqual,
    StringOperator.Contains,
    StringOperator.DoesNotContain,
  ],
  [ColumnType.Number]: [NumberOperator.Equals, NumberOperator.DoesNotEqual],
  [ColumnType.JsonArrayStrings]: [JsonArrayOperator.ContainsLike],
  [ColumnType.JsonArrayNumbers]: [JsonArrayOperator.ContainsLike],
};

type OperatorsWithMultiValues =
  | StringOperator
  | NumberOperator
  | JsonArrayOperator;

export const MultiValueOperators: OperatorsWithMultiValues[] = Object.values(
  MultiValueOperatorsByColumnType,
).flatMap((operators: OperatorsWithMultiValues[]) => operators);

export const PercentileOperators = [
  NumberOperator.Equals,
  NumberOperator.DoesNotEqual,
  NumberOperator.LessThan,
  NumberOperator.LessThanOrEqualTo,
  NumberOperator.GreaterThan,
  NumberOperator.GreaterThanOrEqualTo,
];

export const FunnelValueOptions = [
  {
    label: "value",
    value: ConditionType.Property,
  },
  {
    label: "property",
    value: ConditionType.ReferenceProperty,
  },
];

export const TraitTypeOptions = [
  {
    label: "Sum",
    value: TraitType.Sum,
  },
  { label: "Count", value: TraitType.Count },
  {
    label: "Average",
    value: TraitType.Average,
  },
  {
    label: "Most frequent",
    value: TraitType.MostFrequent,
  },
  {
    label: "Least frequent",
    value: TraitType.LeastFrequent,
  },
  { label: "First", value: TraitType.First },
  {
    label: "Last",
    value: TraitType.Last,
  },
  {
    label: "SQL",
    value: TraitType.RawSql,
  },
];

export type AdditionalColumn = { alias: string; column: RelatedColumn };

export interface TraitCondition extends PropertyCondition {
  property: {
    type: "related";
    path: string[];
    column: TraitColumn;
  };
}

export const getInitialTraitColumn = (
  trait: TraitDefinitionFragment,
): RelatedColumn | TransformedColumn => {
  if (trait.type !== TraitType.Formula && trait.relationship) {
    return {
      type: "related",
      path: [trait.relationship.id],
      column: {
        type: "trait",
        traitDefinitionId: trait.id,
        conditions: trait.config.conditions ?? [],
      },
    };
  } else {
    return {
      type: "transformed",
      column: {
        type: "trait",
        traitDefinitionId: trait.id,
        conditions: [],
      },
    };
  }
};

export interface InlineAggregatedTraitCondition extends PropertyCondition {
  property: {
    type: "related";
    path: string[];
    column: InlineAggregatedTrait;
  };
}

interface InlineFormulaTraitCondition extends PropertyCondition {
  property: {
    type: "transformed";
    column: InlineFormulaTrait;
  };
}

export interface InlineTraitCondition extends PropertyCondition {
  property:
    | InlineAggregatedTraitCondition["property"]
    | InlineFormulaTraitCondition["property"];
}

export const isColumnReference = (
  property: unknown,
): property is ColumnReference => {
  return typeof property === "object";
};

export {
  isInlineTraitColumn,
  isMergedColumn,
  isRawColumn,
  isRelatedColumn,
  isTraitColumn,
  isTransformedColumn,
};

export const isPropertyCondition = (
  condition: any,
): condition is PropertyCondition => condition?.type === ConditionType.Property;

export const isTraitCondition = (
  condition: Condition,
): condition is TraitCondition =>
  condition.type === ConditionType.Property &&
  isRelatedColumn(condition.property) &&
  isTraitColumn(condition.property.column);

export const isFormulaTraitCondition = (
  condition: Condition,
): condition is InlineFormulaTraitCondition =>
  condition.type === ConditionType.Property &&
  isTransformedColumn(condition.property) &&
  condition.property.column.type === "trait";

export const isInlineTraitCondition = (
  condition: Condition,
): condition is InlineTraitCondition =>
  condition.type === ConditionType.Property &&
  isRelatedColumn(condition.property) &&
  isInlineTraitColumn(condition.property.column);

export const isInlineAggregatedTraitCondition = (
  condition: Condition,
): condition is InlineAggregatedTraitCondition => {
  return (
    isInlineTraitCondition(condition) &&
    condition.property.column.traitType !== TraitType.Formula
  );
};

/**
 * Returns `true` if `newOperator` cannot suppport the same list of time types as the `oldOperator`
 */
export const shouldResetTimeType = (
  oldOperator: Operator,
  newOperator: Operator,
): boolean => {
  return (
    AbsoluteRelativeTimestampOperators.includes(oldOperator) &&
    RelativeOnlyTimestampOperators.includes(newOperator)
  );
};

/**
 * When using the Between operator, the value is of type `TimeRange` (supports relative and absolute).
 * All other timestamp operators use the value type `IntervalValue` (for relative) or `string` (for absolute).
 * So if the operator transitioned to or from Between, we know its value type has changed.
 */
export const isNewTimeValueTypeDifferent = (
  oldOperator: Operator,
  newOperator: Operator,
): boolean => {
  return (
    [oldOperator, newOperator].includes(TimestampOperator.Between) ||
    [oldOperator, newOperator].includes(TimestampOperator.BetweenInclusive)
  );
};

/**
 * Tests for all conditions where the value type has changed due to the change in operator.
 * If the value _type_ has changed, then we should reset the value.
 */
export const shouldResetValue = (
  columnType: ColumnType | null,
  oldOperator: Operator,
  newOperator: Operator,
) => {
  const isOldOperatorMultiValue =
    columnType &&
    MultiValueOperatorsByColumnType[columnType]?.includes(oldOperator);
  const isNewOperatorTypeMultiValue =
    columnType &&
    MultiValueOperatorsByColumnType[columnType]?.includes(newOperator);

  const changedFromIntervalToNonIntervalOperator =
    IntervalOperators.includes(oldOperator) &&
    !IntervalOperators.includes(newOperator);

  const isOperatorWithoutValue = OperatorsWithoutValue.includes(newOperator);

  return (
    isOldOperatorMultiValue !== isNewOperatorTypeMultiValue ||
    changedFromIntervalToNonIntervalOperator ||
    isOperatorWithoutValue ||
    isNewTimeValueTypeDifferent(oldOperator, newOperator)
  );
};

export const shouldResetPercentile = (newOperator: any): boolean => {
  return !PercentileOperators.includes(newOperator);
};
