import { FC, useState } from "react";

import { TraitConfig, TraitType } from "@hightouch/lib/query/visual/types";
import {
  Alert,
  Button,
  ButtonGroup,
  ChakraModal,
  ChakraModalBody,
  ChakraModalContent,
  ChakraModalFooter,
  ChakraModalHeader,
  ChakraModalOverlay,
  Column,
  DrawerBody,
  DrawerFooter,
  Heading,
  Row,
  useToast,
} from "@hightouchio/ui";
import { captureException } from "@sentry/react";
import capitalize from "lodash/capitalize";
import { useNavigate, useParams } from "src/router";

import { Drawer } from "src/components/drawer";
import {
  DiscardButton,
  Form,
  SaveButton,
  useHightouchForm,
} from "src/components/form";
import { useFormErrorContext } from "src/contexts/form-error-context";
import {
  TraitDefinitionsConstraint,
  TraitDefinitionsSetInput,
  useTraitQuery,
  useUpdateTraitMutation,
} from "src/graphql";
import { NotFound } from "src/pages/not-found";

import { DUPLICATE_TRAIT_NAME_ERROR_MESSAGE } from "./constants";
import { Header } from "./header";
import { TraitCalculationForm } from "./trait-calculation-form";
import { TraitReferences, isTraitReferenced } from "./trait-references";
import {
  formatTraitConfig,
  traitTypeToCalculationMethod,
  validateConfig,
  validateTraitConfig,
} from "./utils";

type Props = {
  isTemplate?: boolean;
};

export const EditTrait: FC<Readonly<Props>> = ({ isTemplate }) => {
  const { traitId } = useParams();
  const navigate = useNavigate();
  const { toast } = useToast();
  const [showSaveModal, setShowSaveModal] = useState(false);

  const { hasValidationErrors } = useFormErrorContext();

  const updateTrait = useUpdateTraitMutation();

  const { data, isLoading } = useTraitQuery(
    { id: traitId ?? "" },
    { enabled: traitId != null },
  );
  const trait = data?.trait_definitions_by_pk;

  const onCancel = () =>
    navigate(`/traits/${isTemplate ? "templates" : "active"}/${traitId}`);
  const onCloseDrawer = () =>
    navigate(`/traits/${isTemplate ? "templates" : "active"}`);

  const form = useHightouchForm({
    // TODO(samuel): Set up trait validation with yup
    values: trait
      ? {
          is_template: trait.is_template,
          relationship_id: trait.relationship?.id,
          calculation_method: traitTypeToCalculationMethod[trait.type],
          type: trait.type as TraitType,
          config: trait.config as TraitConfig,
        }
      : {
          relationship_id: undefined,
          type: TraitType.Count,
          config: {},
        },
    onSubmit: async (formData) => {
      if (!trait) {
        return;
      }

      if (
        hasValidationErrors() ||
        !validateConfig(formData.type, formData.config)
      ) {
        throw new Error("Trait form has validation errors");
      }
      try {
        const input: TraitDefinitionsSetInput = {
          relationship_id: formData.relationship_id,
          type: formData.type,
          config: formatTraitConfig(
            formData.type,
            formData.config,
            trait?.parent_model,
          ),
        };

        await updateTrait.mutateAsync({
          id: trait.id,
          input,
        });

        navigate(
          `/traits/${trait.is_template ? "templates" : "active"}/${traitId}`,
        );
      } catch (error) {
        // Add a more helpful error message
        throw error.message.includes(
          TraitDefinitionsConstraint.TraitDefinitionsNameParentModelIdKey,
        )
          ? new Error(DUPLICATE_TRAIT_NAME_ERROR_MESSAGE)
          : error;
      }
    },
    onError: (error) => {
      if (error.message !== DUPLICATE_TRAIT_NAME_ERROR_MESSAGE) {
        captureException(error);
      }
    },
  });

  const type = form.watch("type");
  const config = form.watch("config");

  const isSaveDisabled = updateTrait.isLoading || !validateConfig(type, config);

  const traitType = isTemplate ? "template" : "trait";

  return (
    <Drawer isLoading={isLoading} isOpen size="xl" onClose={onCloseDrawer}>
      {!trait ? (
        <DrawerBody>
          <NotFound />
        </DrawerBody>
      ) : (
        <Form form={form}>
          <Row
            p={6}
            borderBottom="1px solid"
            borderColor="base.border"
            width="100%"
          >
            <Header
              id={trait.id}
              name={trait.name}
              description={trait.description}
              parentModel={trait.parent_model}
              isTemplate={trait.is_template}
              isArchived={Boolean(trait.archived_at)}
              isEditable
              onClose={onCloseDrawer}
            />
          </Row>
          <DrawerBody>
            <Column overflowY="auto" flex={1}>
              <TraitCalculationForm
                parentModel={trait.parent_model}
                isAssociatedToTemplate={Boolean(trait.trait_template_id)}
                onPreviewValidation={() =>
                  validateTraitConfig(hasValidationErrors, toast)
                }
              />
            </Column>
          </DrawerBody>

          <DrawerFooter>
            <ButtonGroup>
              {isTraitReferenced(trait) ? (
                <Button
                  isDisabled={isSaveDisabled}
                  size="lg"
                  variant="primary"
                  onClick={() => setShowSaveModal(true)}
                >
                  Save changes
                </Button>
              ) : (
                <SaveButton />
              )}
              <DiscardButton />
            </ButtonGroup>
            <Button size="lg" onClick={onCancel}>
              Cancel
            </Button>
          </DrawerFooter>

          <ChakraModal
            closeOnEsc
            closeOnOverlayClick
            isCentered
            scrollBehavior="inside"
            isOpen={showSaveModal}
            onClose={() => setShowSaveModal(false)}
          >
            <ChakraModalOverlay>
              <ChakraModalContent
                maxHeight="90vh"
                maxWidth="500px"
                padding={0}
                my="auto"
              >
                <ChakraModalHeader
                  alignItems="center"
                  padding={6}
                  borderBottom="1px solid"
                  borderColor="base.border"
                >
                  <Row alignItems="center" width="100%">
                    <Heading>Save trait changes?</Heading>
                  </Row>
                </ChakraModalHeader>
                <ChakraModalBody px={6} pb={6} m={0}>
                  <Alert
                    variant="inline"
                    type="warning"
                    title={`${capitalize(traitType)} in use`}
                    message={`Saving this ${traitType} will update the following active audiences and syncs.`}
                  />
                  <TraitReferences trait={trait} />
                </ChakraModalBody>
                <ChakraModalFooter
                  p={4}
                  m={0}
                  borderTop="1px solid"
                  borderColor="base.border"
                >
                  <Button onClick={() => setShowSaveModal(false)}>
                    Cancel
                  </Button>
                  <SaveButton />
                </ChakraModalFooter>
              </ChakraModalContent>
            </ChakraModalOverlay>
          </ChakraModal>
        </Form>
      )}
    </Drawer>
  );
};
