import {
  AssetTypeToPredefinedMetricOrder,
  Delimiter,
} from "src/pages/campaigns/constants";
import {
  ConversionMetric,
  MetricWithAttributionMethodId,
  PredefinedMetric,
} from "src/pages/campaigns/types";
import {
  AssetType,
  AudienceAggregationType,
  PerUserAggregationType,
} from "src/types/visual";

export const getMetricsToMeasure = (
  predefinedMetrics: PredefinedMetric[],
  conversionMetrics: ConversionMetric[],
) => {
  const metricsToMeasure: MetricWithAttributionMethodId[] = [];

  predefinedMetrics.forEach((metric) => {
    metricsToMeasure.push({
      goalId: metric.id,
      attributionMethodId: undefined,
    });
  });

  conversionMetrics.forEach((metric) => {
    metricsToMeasure.push({
      goalId: metric.id,
      attributionMethodId: metric.attributionMethod.id,
    });
  });

  return metricsToMeasure;
};

export function toConversionMetricKey(conversionMetric: {
  goalId: string;
  attributionMethodId: string;
}): string {
  return `${conversionMetric.goalId}${Delimiter}${conversionMetric.attributionMethodId}`;
}

export function fromConversionMetricKey(conversionMetricKey: string): {
  goalId: string;
  attributionMethodId: string;
} {
  const [goalId, attributionMethodId] = conversionMetricKey.split(Delimiter);
  if (!goalId || !attributionMethodId) {
    throw new Error(
      `Failed to parse conversionMetricKey: ${conversionMetricKey}`,
    );
  }

  return { goalId, attributionMethodId };
}

export function convertMetricsFromBackendToCorrectType(
  goals: (Omit<ConversionMetric, "aggregation" | "audience_aggregation"> & {
    aggregation: string;
    audience_aggregation: string;
  })[],
): ConversionMetric[];
export function convertMetricsFromBackendToCorrectType(
  goals: (Omit<PredefinedMetric, "aggregation" | "audience_aggregation"> & {
    aggregation: string;
    audience_aggregation: string;
  })[],
): PredefinedMetric[];
export function convertMetricsFromBackendToCorrectType(
  goals: (any & {
    aggregation: string;
    audience_aggregation: string;
  })[],
): PredefinedMetric[] | ConversionMetric[] {
  return goals.map((goal) => ({
    ...goal,
    aggregation: goal.aggregation as PerUserAggregationType,
    audience_aggregation: goal.audience_aggregation as AudienceAggregationType,
  }));
}

export function sortPredefinedMetricsByColumnName(
  assetType: AssetType,
  predefinedMetrics: PredefinedMetric[],
): PredefinedMetric[] {
  const sortedPredefinedMetrics = predefinedMetrics.sort(
    (a, b) =>
      AssetTypeToPredefinedMetricOrder[assetType].indexOf(
        a.config.predefinedMetric,
      ) -
      AssetTypeToPredefinedMetricOrder[assetType].indexOf(
        b.config.predefinedMetric,
      ),
  );

  return sortedPredefinedMetrics;
}
