import { Fragment, useEffect, useMemo, useState } from "react";

import {
  ArrowRightIcon,
  AudienceIcon,
  Box,
  Button,
  ButtonGroup,
  CloseIcon,
  Column,
  Combobox,
  ErrorIcon,
  GroupedCombobox,
  IconButton,
  Radio,
  RadioGroup,
  Row,
  Select,
  Text,
  TextInput,
  Tooltip,
  TraitIcon,
  WandIcon,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import get from "lodash/get";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { isPresent } from "ts-extras";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { usePermissionContext } from "src/components/permission/permission-context";
import { useMatchboostingStatsQuery } from "src/graphql";
import { flattenOptions } from "src/ui/select";
import { automap, suggest } from "src/utils/automap";
import { NEW_ICON_MAP } from "src/utils/destinations";

import {
  ExtendedOption,
  ExtendedTypesSync,
  fromExtendedOptiontoOption,
  Option,
} from "@hightouch/formkit";
import { isColumnReference } from "src/types/visual";
import { ArrayInlineMapper } from "./array-inline-mapper";
import {
  ColumnOption,
  useFormkitContext,
} from "src/formkit/components/formkit-context";
import { SyncType } from "src/formkit/components/sync-type";
import {
  JsonColumnProps,
  MappingType,
  SelectedOptionContext,
} from "src/formkit/components/types";
import {
  isMatchBoosterSetUpToUse,
  isMatchBoostingAvailableForSyncConfig,
  isMatchboostingEnabled,
  resolveFromValue,
} from "src/formkit/components/utils";
import { AssociationMapper, AssociationOptions } from "./association-mapper";
import { InlineMapper } from "./inline-mapper";
import { Mapper } from "./mapper";
import { MappingsHeader } from "./mappings-header";
import { MatchBoosterMappingToggle } from "./matchbooster";
import {
  extractEligibleInlineMapperColumns,
  isValidJsonColumnMetadata,
} from "./utils";
import { useUser } from "src/contexts/user-context";
import { useParams } from "src/router";
import { TypeSelect } from "./type-select";

export type GetIsValidProps = {
  errors: any;
  index?: number;
  mappingDirection: "from" | "lookup.by" | "lookup.from" | "to";
  mappingName: string;
};

type Props = {
  name: string;
  options?: ExtendedOption[];
  customColumns?: ColumnOption[];
  asyncOptions?: boolean;
  loading?: boolean;
  error?: string;
  required?: string;
  creatable?: boolean;
  /** `creatableTypes` are the types the user can select from when creating their own field. */
  creatableTypes?: ExtendedOption[];
  autoSyncColumnsDefault?: boolean;
  excludeMappings?: string[];
  reload?: () => void;
  advanced?: boolean;
  associationOptions?: AssociationOptions;
  templates?: { name: string; description: string; placeholders?: string[] }[];

  allEnabled?: boolean;
  allEnabledKey?: string;
  allEnabledLabel?: string;
  enableInLineMapper?: boolean;
  componentSupportsMatchBoosting?: boolean;
  matchboosterSemanticColumnsToMap?: string[];

  allowCreatableAutomapperWithFields?: string[];
  allowIgnoreNullForAssociations?: boolean;
};

type OptionChangeArgs = {
  destinationFieldOptions: ExtendedOption[] | undefined;
  data: any;
  value: string;
  isCreatedOption?: boolean;
  typeOptions?: Record<string, boolean | number | string>;
};

export const formatOptionLabel = (object) => {
  return (
    object.label + (object?.object?.label ? ` (${object.object.label})` : "")
  );
};

const clearMappingError = (
  errors: any,
  index: number,
  setErrors: (error: Error) => void,
): void => {
  const updatedErrors = { ...errors };
  const errorKeys = [
    `mappings[${index}].from`,
    `mappings[${index}].to`,
    `mappings[${index}].lookup.from`,
    `mappings[${index}].lookup.by`,
  ];

  errorKeys.forEach((key) => {
    if (updatedErrors[key]) {
      delete updatedErrors[key];
    }
  });

  setErrors(updatedErrors);
};

const retrieveErrorMessage = (
  errors: any,
  index: number,
  name: string,
): string => {
  const fromError = errors?.[`${name}[${index}].from`];
  const toError = errors?.[`${name}[${index}].to`];
  if (fromError || toError) {
    for (const direction of ["from", "to"]) {
      const mappingPath = `${name}[${index}].${direction}`;
      const errorMessage = errors?.[mappingPath];
      if (typeof errorMessage === "string") {
        return errorMessage.replace(mappingPath, "This");
      }
    }
  }

  const byAssociationError = errors?.[`mappings[${index}].lookup.by`];
  const fromAssociationError = errors?.[`mappings[${index}].lookup.from`];
  if (byAssociationError || fromAssociationError) {
    for (const direction of ["by", "from"]) {
      const mappingPath = `${name}[${index}].lookup.${direction}`;
      const errorMessage = errors?.[mappingPath];
      if (typeof errorMessage === "string") {
        return errorMessage.replace(mappingPath, "This");
      }
    }
  }
  return "";
};

export const Mappings: React.FC<Readonly<Props>> = ({
  name,
  options,
  customColumns,
  required,
  loading,
  reload,
  creatable,
  creatableTypes,
  allEnabled,
  allEnabledLabel,
  allEnabledKey,
  autoSyncColumnsDefault = false,
  excludeMappings = [],
  advanced,
  associationOptions,
  templates,
  enableInLineMapper,
  componentSupportsMatchBoosting,
  matchboosterSemanticColumnsToMap,
  allowCreatableAutomapperWithFields,
  allowIgnoreNullForAssociations,
  asyncOptions,
}) => {
  const {
    model,
    destinationDefinition,
    sourceDefinition,
    columns: allColumns,
    supportsMatchboosting,
  } = useFormkitContext();
  const columns = customColumns ?? allColumns;
  const [jsonColumnProperties, setJsonColumnProperties] =
    useState<JsonColumnProps>({
      selectedColumnProps: undefined,
      allColumnsProps: undefined,
    });

  const { workspace } = useUser();
  const { errors, setErrors, sync, config } = useDestinationForm();
  const { sync_template_id } = useParams<{ sync_template_id?: string }>();
  const { watch, setValue } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    name,
  });

  // We want to get the last enrichment stats to confirm it's okay to display
  // boosted columns
  const { data: modelLastEnrichedStats } = useMatchboostingStatsQuery(
    {
      // use the parent model id if this an audience, otherwise the model id.
      modelId: model?.parent?.id || model?.id,
    },
    {
      enabled: Boolean(model?.id),
      select: (data) => data.matchboosting_stats[0],
    },
  );

  const autoSyncKey = allEnabledKey ?? "autoSyncColumns";
  const watchAutoSyncColumns = watch(autoSyncKey);
  const [isSuggested, setIsSuggested] = useState(false);
  const { appEnableInLineMapper, appMatchBoosting } = useFlags();

  const mbEnabledForWorkspace =
    appMatchBoosting || workspace?.organization?.entitlements?.matchbooster;

  useEffect(() => {
    if (watchAutoSyncColumns) {
      setValue(name, []);
    }
  }, [watchAutoSyncColumns]);

  const watchFieldArray = watch(name);

  useEffect(() => {
    if (!watchFieldArray) {
      setValue(name, []);
    }
  }, [watchFieldArray]);

  const controlledFields =
    fields?.map((field, index) => {
      return {
        ...field,
        ...watchFieldArray?.[index],
      };
    }) || [];

  useEffect(() => {
    if (
      allEnabled &&
      watchAutoSyncColumns === undefined &&
      !controlledFields.length
    ) {
      setValue(autoSyncKey, autoSyncColumnsDefault);
    } else if (!allEnabled) {
      setValue(autoSyncKey, undefined);
    }
  }, [autoSyncColumnsDefault]);

  useEffect(() => {
    const subscription = watch((state, { name: key }) => {
      const currentMappings = state[name];

      // handles when externalIdMapping overlaps with current component
      if (currentMappings && key && excludeMappings.includes(key)) {
        const value = state[key];
        let otherMappings: any[] = [];
        if (Array.isArray(value)) {
          otherMappings = value;
        } else if (typeof value === "object") {
          otherMappings = [value];
        }

        const fieldsWithoutExcluded = currentMappings.filter(
          (currentMapping) =>
            !otherMappings.some(
              (otherMapping) =>
                currentMapping.to === otherMapping.to &&
                isPresent(currentMapping.to) &&
                isPresent(otherMapping.to),
            ),
        );
        setValue(name, fieldsWithoutExcluded);
      }
    });

    return () => subscription.unsubscribe();
  }, [excludeMappings, setValue, watch]);

  const excludedFields: any[] = [];

  for (const key of excludeMappings) {
    const watchMapping = watch(key);
    if (Array.isArray(watchMapping)) {
      excludedFields.push(...watchMapping);
    } else if (typeof watchMapping === "object") {
      excludedFields.push(watchMapping);
    }
  }

  // handles existing mappings
  const isOptionExcluded = (option) => {
    if (option.disabled) {
      return true;
    }
    const valueAlreadyMapped = controlledFields.some(
      ({ to }) =>
        to === (isColumnReference(option.value) ? option.label : option.value),
    );
    const usedInOtherMappings = excludedFields.some(
      ({ to }) =>
        to === (isColumnReference(option.value) ? option.label : option.value),
    );

    return valueAlreadyMapped || usedInOtherMappings;
  };

  //Add in required options
  const requiredOptionsNotPresent = options?.filter(
    (option) =>
      (option.required ?? option.extendedType?.required) &&
      !isOptionExcluded(option),
  );
  useEffect(() => {
    //Remove options that may not be available now or possible duplicates (due to modifiers)
    const existingMappings: string[] = [];
    const indexesToRemove: number[] = [];
    controlledFields?.forEach(({ to }, index) => {
      //Remove any duplicate mappings (from options having required again)
      if (to && existingMappings.includes(to) && options) {
        indexesToRemove.push(index);
      }
      existingMappings.push(to);
    });
    if (indexesToRemove.length > 0) {
      remove(indexesToRemove);
    }

    if (requiredOptionsNotPresent?.length) {
      const requiredMappings = requiredOptionsNotPresent.map((r) => {
        // Map references differently
        if (r?.extendedType?.type === "REFERENCE") {
          return {
            to: r.value,
            type: "reference",
            lookup: {
              object: r.objectType || r.value,
            },
            object: r["object"]?.value,
          };
        }

        return {
          to: r.value,
          type: "standard",
          object: r["object"]?.value,
        };
      });

      append(requiredMappings);
    }
  }, [controlledFields, options]);

  useEffect(() => {
    if (!requiredOptionsNotPresent?.length && !controlledFields.length) {
      append({ type: "standard" });
    }
  }, []);

  const suggestColumns = () => {
    let automapOptions = options;
    if (options?.length && allowCreatableAutomapperWithFields) {
      // If all available destination fields are included in the `allowCreatableAutomapperWithFields`, add model columns
      // to be auto suggested
      const fromExtendedOptions: Option[] = options.map(
        fromExtendedOptiontoOption,
      );
      const shouldAddModelColumns = fromExtendedOptions.every((option) =>
        allowCreatableAutomapperWithFields.includes(option.value as string),
      );
      if (shouldAddModelColumns) {
        const flatColumns = flattenOptions(columns);
        const destinationFields = fromExtendedOptions.map(
          (option) => option.value as string,
        );

        // Only add the model columns if destination fields don't have the value already
        const filtered = flatColumns.filter(
          (column) =>
            !destinationFields.find((field) => field === column.value),
        );
        automapOptions = [...options, ...filtered];
      }
    }
    const unmappedOptions = getUnmappedColumns(columns, automapOptions);
    const automappedColumns = automap(columns, unmappedOptions);
    setIsSuggested(true);
    return automappedColumns;
  };

  function getUnmappedColumns(
    columns: ColumnOption[],
    options: ExtendedOption[] | undefined,
  ) {
    const flatColumns = flattenOptions(columns);
    return (
      !options?.length ? flatColumns : options?.map(fromExtendedOptiontoOption)
    )?.filter((option) => !isOptionExcluded(option));
  }

  const mbAvailableForSyncConfig = isMatchBoostingAvailableForSyncConfig({
    options,
    componentSupportsMatchBoosting,
    destinationSupportsMatchBoosting: supportsMatchboosting,
  });

  const matchBoostingEnabled = isMatchboostingEnabled({ fields });

  const onToggleMatchboosterEnabled = () => {
    if (matchBoostingEnabled) {
      const indexesToRemove: number[] = [];
      controlledFields.forEach((field, index) => {
        if (field?.from?.type === "boosted") {
          indexesToRemove.push(index);
        }
      });
      if (indexesToRemove.length > 0) {
        remove(indexesToRemove);
      }
      return;
    }

    // Some destinations don't have boosted fields (i.e. BigQuery ADH), since
    // the fields are user-provided and we don't know their semantic meaning.
    // In these cases, we provide the mapping via the matchboosterSemanticColumnsToMap
    // on the column semantic types.
    if (matchboosterSemanticColumnsToMap?.length) {
      const boostedColumns = columns.find(
        (columns) => columns.label === "boosted",
      );
      if (!boostedColumns) {
        // This should never happen, it means that matchboosting isn't
        // enabled for this destination.
        Sentry.captureException(
          new Error("Could not find boosted columns section"),
        );
        return;
      }

      const boostedOptions = boostedColumns.options?.filter((option) =>
        matchboosterSemanticColumnsToMap.includes(
          (option.value as any).semanticType,
        ),
      );

      if (!boostedOptions?.length) {
        // This should never happen - this would mean the destination
        // hasn't implemented the matchboosterSemanticColumnsToMap.
        Sentry.captureException(new Error("Could not find columns to boost"));
        return;
      }

      const fieldsToBoost = boostedOptions.map((option) => {
        return {
          from: option.value,
          to: null,
        };
      });

      append(fieldsToBoost);

      return;
    }

    const boostedFields = options?.filter(
      (option) => !!option?.extendedType?.semanticColumnType,
    );

    // Remove all the currently mapped fields.
    remove(controlledFields.map((_, index) => index));

    // Add all the fields back with the boosted fields first.
    const fieldsToAppend = [
      ...(boostedFields?.map((field) => {
        return {
          from: {
            type: "boosted",
            semanticType: field?.extendedType?.semanticColumnType,
          },
          to: field.value,
        };
      }) || []),
      // Remove any empty mappings that were added before toggling.
      ...(controlledFields
        .filter((field) => field.from || field.to)
        .map((field) => {
          return {
            from: field.from,
            to: field.to,
          };
        }) || []),
    ];

    if (fieldsToAppend.length > 0) {
      append(fieldsToAppend);
    }
  };

  const mbSetUpToUse = isMatchBoosterSetUpToUse({
    mbEnabledForWorkspace: mbEnabledForWorkspace,
    model,
    lastEnrichedAt: modelLastEnrichedStats?.last_enriched_at,
  });
  useEffect(() => {
    // Default to enable MB, if available and user has MB set up when creating a sync
    if (
      mbAvailableForSyncConfig &&
      mbSetUpToUse &&
      !matchBoostingEnabled &&
      !sync?.id &&
      !sync_template_id
    ) {
      onToggleMatchboosterEnabled();
    }
  }, [sync, mbAvailableForSyncConfig, mbSetUpToUse]);

  const handleOptionChange = ({
    destinationFieldOptions,
    data,
    value,
    isCreatedOption,
    typeOptions,
  }: OptionChangeArgs): void => {
    const option = destinationFieldOptions?.find(
      (option) => option.value === value,
    );
    const newFieldData = { ...data.field.value, object: option?.object?.value };
    if (isCreatedOption) {
      newFieldData.fieldType = value;
      newFieldData.typeOptions = typeOptions;
    } else {
      newFieldData.to = option?.value;
    }

    if (option?.extendedType?.type === "REFERENCE") {
      newFieldData.type = "reference";
      newFieldData.lookup = {
        object:
          option?.referenceObjects?.[0]?.value ||
          option?.objectType ||
          option?.value,
        from: data.field.value?.from,
      };
    } else if (
      option?.extendedType?.type === "OBJECT" ||
      option?.extendedType?.type === "ARRAY"
    ) {
      if (enableInLineMapper && appEnableInLineMapper) {
        newFieldData.from = undefined;
        newFieldData.type =
          option?.extendedType?.type === "OBJECT"
            ? MappingType.OBJECT
            : MappingType.ARRAY;
      }
    } else if (data.field.value?.type === "reference") {
      newFieldData.type = "standard";
      newFieldData.from = data.field.value?.lookup?.from;
    }

    data.field.onChange(newFieldData);
  };

  const permission = usePermissionContext();

  const columnOptionGroups = useMemo(() => {
    return columns.map((optionGroup) => ({
      ...optionGroup,
      options: optionGroup.options ?? [],
    }));
  }, [columns]);

  const MAPPING_TYPE_OPTIONS = [
    {
      label: allEnabledLabel || "Sync all columns",
      value: "true",
    },
    {
      label: "Sync specific columns",
      value: "false",
    },
  ];

  if (!watchFieldArray) {
    return null;
  }

  if (allEnabled && watchAutoSyncColumns) {
    return (
      <Controller
        name={autoSyncKey}
        render={({ field }) => {
          return (
            <RadioGroup
              isDisabled={permission.unauthorized}
              orientation="vertical"
              value={String(Boolean(field.value))}
              onChange={(value) => {
                field.onChange(value);
                if (value === "true") {
                  field.onChange(true);
                } else {
                  field.onChange(undefined);
                }
              }}
            >
              {MAPPING_TYPE_OPTIONS.map((option, index) => (
                <Radio key={index} label={option.label} value={option.value} />
              ))}
            </RadioGroup>
          );
        }}
      />
    );
  }

  const loadEligibleInlineMapperColumns = async (
    currentSelectedColumn?: string,
  ): Promise<void> => {
    const allColumnsProps = extractEligibleInlineMapperColumns(columns);
    if (isValidJsonColumnMetadata(allColumnsProps)) {
      setJsonColumnProperties((currentValue) => ({
        selectedColumnProps: currentSelectedColumn
          ? allColumnsProps[currentSelectedColumn]
          : currentValue.selectedColumnProps,
        allColumnsProps,
      }));
    }
  };

  const getIsValid = ({
    errors,
    index,
    mappingDirection,
    mappingName,
  }: GetIsValidProps): boolean => {
    const error = errors?.[`${mappingName}[${index}].${mappingDirection}`];
    return Boolean(error);
  };

  return (
    <Column aria-label={`${name} mappings`} gap={2}>
      {allEnabled && (
        <Controller
          name={autoSyncKey}
          render={({ field }) => {
            return (
              <RadioGroup
                isDisabled={permission.unauthorized}
                mb={4}
                orientation="vertical"
                value={String(Boolean(field.value))}
                onChange={(value) => {
                  field.onChange(value);
                  if (value === "true") {
                    field.onChange(true);
                  } else {
                    field.onChange(undefined);
                  }
                }}
              >
                {MAPPING_TYPE_OPTIONS.map((option, index) => (
                  <Radio
                    key={index}
                    label={option.label}
                    value={option.value}
                  />
                ))}
              </RadioGroup>
            );
          }}
        />
      )}
      <MatchBoosterMappingToggle
        mbAvailableForSyncConfig={mbAvailableForSyncConfig}
        mbEnabledForWorkspace={mbEnabledForWorkspace}
        matchBoostingEnabled={matchBoostingEnabled}
        onToggle={onToggleMatchboosterEnabled}
        model={model}
        workspaceId={workspace?.id}
        syncId={sync?.id}
        config={config}
        destinationDefinition={destinationDefinition}
      />
      {controlledFields?.length > 0 && (
        <Box
          alignItems="center"
          display="grid"
          gap={4}
          gridTemplateColumns="minmax(0, 1fr) 32px minmax(0, 1fr) 24px 24px"
        >
          <MappingsHeader
            loading={loading}
            reload={reload}
            usingCustomFromOptions={Boolean(customColumns)}
          />
        </Box>
      )}
      {controlledFields.map(({ id }, index) => (
        <Fragment key={id}>
          <Controller
            name={`${name}.${index}`}
            render={(data) => {
              const isMatchboosterField =
                data.field.value?.from?.type === "boosted";
              const destinationFieldOptions = options?.map((option) => {
                if (isOptionExcluded(option)) {
                  return { ...option, disabled: true };
                } else {
                  return option;
                }
              });
              const selectedOption = options?.find(
                (option) => option.value == data.field.value?.to,
              );

              const allDestinationOptions = [
                ...(destinationFieldOptions ?? []),
              ];

              // If mapping destination is configured, but it's not in the list of
              // available options, it's either a custom value typed by the user or
              // it comes from a suggested mapping
              if (typeof data.field.value?.to === "string" && !selectedOption) {
                allDestinationOptions.push({
                  label: data.field.value.to,
                  value: data.field.value.to,
                  disabled: false,
                });
              }

              const isMappingRequired = Boolean(
                selectedOption?.required ??
                  selectedOption?.extendedType?.required,
              );
              const referenceObjectOptions = selectedOption?.referenceObjects;

              const optionContext = extendedTypeToFormkitOptions(
                data.field?.value?.fieldType && creatableTypes
                  ? creatableTypes.find(
                      (option) => option.value === data.field.value.fieldType,
                    )
                  : selectedOption,
              );

              return (
                <Box
                  alignItems="center"
                  display="grid"
                  gap={4}
                  gridTemplateColumns="minmax(0, 1fr) 32px minmax(0, 1fr) 24px 24px"
                  mb={
                    data.field.value?.to &&
                    data.field.value?.typeOptions &&
                    "1rem"
                  }
                >
                  {data.field.value?.type === "reference" ? (
                    <AssociationMapper
                      associationOptions={associationOptions}
                      index={index}
                      name={name}
                      value={data.field.value}
                      onChange={data.field.onChange}
                    />
                  ) : advanced ? (
                    <Mapper
                      enableInLineMapper={
                        enableInLineMapper && appEnableInLineMapper
                      }
                      isDisabled={permission.unauthorized}
                      isError={getIsValid({
                        errors,
                        index,
                        mappingDirection: "from",
                        mappingName: name,
                      })}
                      jsonColumnProperties={jsonColumnProperties}
                      placeholder={`Select a field from ${sourceDefinition?.name}`}
                      selectedOption={selectedOption}
                      templates={templates ?? []}
                      value={data.field.value}
                      onReloadEligibleInlineMapperColumns={
                        loadEligibleInlineMapperColumns
                      }
                      onChange={(value) => {
                        const availableOptions = getUnmappedColumns(
                          columns,
                          destinationFieldOptions,
                        );
                        if (
                          !data.field.value?.to &&
                          availableOptions?.length &&
                          value.type === MappingType.STANDARD
                        ) {
                          // Ensure that the fieldName is used for the label property since FuzzySet methods (used in automap.ts)
                          // require string arguments.
                          const fieldName = resolveFromValue(value.from);
                          data.field.onChange(
                            suggest(
                              { label: fieldName, value: value.from },
                              availableOptions,
                            ),
                          );
                        } else {
                          data.field.onChange({
                            to: data.field.value?.to,
                            object: data.field.value?.object,
                            ...value,
                          });
                        }
                      }}
                      onChangeJsonColumnProperties={setJsonColumnProperties}
                    />
                  ) : (
                    <GroupedCombobox
                      isDisabled={permission.unauthorized}
                      isInvalid={getIsValid({
                        errors,
                        index,
                        mappingName: name,
                        mappingDirection: "from",
                      })}
                      optionAccessory={(option) => {
                        return option?.value?.["type"] ===
                          "additionalColumnReference"
                          ? {
                              type: "icon",
                              icon: TraitIcon,
                            }
                          : undefined;
                      }}
                      optionGroups={columnOptionGroups}
                      placeholder={
                        data.field.value?.to
                          ? ""
                          : `Select a field from ${sourceDefinition?.name}`
                      }
                      value={data.field.value.from}
                      onChange={(value) => {
                        let option: ColumnOption | undefined;

                        // Find an original option object for a selected value
                        for (const optionGroup of columnOptionGroups) {
                          for (const optionGroupItem of optionGroup.options) {
                            if (optionGroupItem.value === value) {
                              option = optionGroupItem;
                              break;
                            }
                          }
                        }

                        const availableOptions = getUnmappedColumns(
                          columns,
                          destinationFieldOptions,
                        );
                        if (!data.field.value?.to && option) {
                          data.field.onChange(
                            suggest(option as any, availableOptions),
                          );
                        } else {
                          data.field.onChange({
                            to: data.field.value?.to,
                            object: data.field.value?.object,
                            from: option?.value,
                            fieldType: data.field.value?.fieldType,
                          });
                        }
                      }}
                    />
                  )}
                  <Column gap={2} fontSize="xl" color="gray.600">
                    <ArrowRightIcon />
                  </Column>
                  <Column>
                    {options || creatable || asyncOptions ? (
                      creatable ? (
                        <Column flex={1} gap={2} position="relative">
                          <Combobox
                            supportsCreatableOptions
                            createOptionMessage={(inputValue) =>
                              `Create field "${inputValue}"`
                            }
                            emptyOptionsMessage="Type in a field name and Hightouch will create that field in the destination"
                            isDisabled={
                              permission.unauthorized || isMappingRequired
                            }
                            isInvalid={getIsValid({
                              errors,
                              index,
                              mappingDirection: "to",
                              mappingName: name,
                            })}
                            isOptionDisabled={(option) => {
                              // For some reason when option is selected, it becomes disabled
                              // and has `disabled = true` property, so we need to take this into account
                              // and make sure selected option can't be disabled
                              const isSelectedOption =
                                option.value === data.field.value?.to;
                              if (isSelectedOption) {
                                return false;
                              }

                              return Boolean(option.disabled);
                            }}
                            optionAccessory={(option) => {
                              return option["type"]
                                ? {
                                    type: "icon",
                                    icon: NEW_ICON_MAP[option["type"]],
                                  }
                                : undefined;
                            }}
                            optionLabel={(option) => {
                              return option.object?.label
                                ? `${option.label} (${option.object.label})`
                                : option.label;
                            }}
                            options={allDestinationOptions}
                            placeholder={
                              data.field.value?.to
                                ? ""
                                : `Select or enter a field from ${destinationDefinition?.name}`
                            }
                            value={data.field.value?.to}
                            width="sm"
                            onChange={(value) =>
                              handleOptionChange({
                                destinationFieldOptions,
                                data,
                                value,
                              })
                            }
                            onCreateOption={(inputValue) => {
                              data.field.onChange({
                                ...data.field.value,
                                to: inputValue || undefined,
                              });
                            }}
                          />
                          {creatableTypes && data.field.value?.to && (
                            <TypeSelect
                              isDisabled={permission.unauthorized}
                              options={creatableTypes}
                              created={Boolean(
                                options?.find(
                                  (option: Option) =>
                                    option.value === data.field.value?.to,
                                ),
                              )}
                              placeholder="Select a field type..."
                              value={data.field.value}
                              width="100%"
                              onTypeCreate={(value, typeOptions) =>
                                handleOptionChange({
                                  destinationFieldOptions: creatableTypes,
                                  data,
                                  value: value as any,
                                  isCreatedOption: true,
                                  typeOptions,
                                })
                              }
                            />
                          )}
                        </Column>
                      ) : (
                        <Combobox
                          isDisabled={
                            permission.unauthorized || isMappingRequired
                          }
                          isInvalid={getIsValid({
                            errors,
                            index,
                            mappingDirection: "to",
                            mappingName: name,
                          })}
                          isOptionDisabled={(option) => {
                            // For some reason when option is selected, it becomes disabled
                            // and has `disabled = true` property, so we need to take this into account
                            // and make sure selected option can't be disabled
                            const isSelectedOption =
                              option.value === data.field.value?.to;
                            if (isSelectedOption) {
                              return false;
                            }

                            return Boolean(option.disabled);
                          }}
                          optionAccessory={(option) => {
                            return option?.extendedType?.type
                              ? {
                                  type: "icon",
                                  icon: NEW_ICON_MAP[option.extendedType.type],
                                }
                              : undefined;
                          }}
                          optionLabel={(option) => {
                            return option.object?.label
                              ? `${option.label} (${option.object.label})`
                              : option.label;
                          }}
                          options={allDestinationOptions}
                          placeholder={
                            data.field.value?.to
                              ? ""
                              : `Select a field from ${destinationDefinition?.name}`
                          }
                          value={data.field.value?.to}
                          width="100%"
                          onChange={(value) =>
                            handleOptionChange({
                              destinationFieldOptions,
                              data,
                              value,
                            })
                          }
                        />
                      )
                    ) : (
                      <Box width="100%">
                        <TextInput
                          isDisabled={permission.unauthorized}
                          isInvalid={getIsValid({
                            errors,
                            index,
                            mappingDirection: "to",
                            mappingName: name,
                          })}
                          placeholder={`Enter a field from ${destinationDefinition?.name}`}
                          value={data.field.value.to ?? ""}
                          width="100%"
                          onChange={(event) => {
                            data.field.onChange({
                              ...data.field.value,
                              to: event.target.value,
                            });
                          }}
                        />
                      </Box>
                    )}
                    {selectedOption?.extendedType?.type === "REFERENCE" &&
                      (referenceObjectOptions?.length ?? 0) > 1 && (
                        <Row alignItems="center" mt={2}>
                          <Text
                            color="base.4"
                            fontWeight="semibold"
                            mr={2}
                            size="sm"
                            whiteSpace="nowrap"
                          >
                            Linked to:
                          </Text>
                          <Select
                            isInvalid={getIsValid({
                              errors,
                              index,
                              mappingDirection: "to",
                              mappingName: name,
                            })}
                            options={referenceObjectOptions ?? []}
                            value={data.field.value?.lookup?.object}
                            onChange={(selected) => {
                              data.field.onChange({
                                ...data.field.value,
                                lookup: {
                                  by: null,
                                  byType: null,
                                  from: undefined,
                                  object: selected,
                                },
                              });
                            }}
                          />
                        </Row>
                      )}
                  </Column>
                  <SyncType
                    isDisabled={
                      permission.unauthorized ||
                      !advanced ||
                      (allowIgnoreNullForAssociations
                        ? ["array", "object"]
                        : ["array", "object", "reference"]
                      ).includes(data.field.value?.type)
                    }
                    setValue={data.field.onChange}
                    value={data.field.value}
                  />
                  {required && controlledFields.length <= 1 ? (
                    // needs to exist to satisfy grid
                    <Row width="32px" />
                  ) : isMappingRequired ? (
                    // needs to exist at same width of 'X' icon to satisfy grid;
                    <Row
                      alignItems="center"
                      height="20px"
                      justifyContent="center"
                      width="32px"
                    >
                      <Tooltip message="Required">
                        <Box color="danger.500" fontSize={18}>
                          *
                        </Box>
                      </Tooltip>
                    </Row>
                  ) : (
                    (!isMatchboosterField ||
                      (isMatchboosterField &&
                        selectedOption?.extendedType?.matchBoosterOptional ===
                          true)) && (
                      <IconButton
                        aria-label="Remove mapping"
                        icon={CloseIcon}
                        isDisabled={permission.unauthorized}
                        onClick={() => {
                          remove(index);
                          clearMappingError(errors, index, setErrors);
                        }}
                      />
                    )
                  )}
                  {retrieveErrorMessage(errors, index, name) && (
                    <Tooltip
                      message={retrieveErrorMessage(errors, index, name)}
                    >
                      <Row fontSize="24px">
                        <Box as={ErrorIcon} color="danger.600" />
                      </Row>
                    </Tooltip>
                  )}
                  <Box
                    gridColumnEnd="4"
                    gridColumnStart="1"
                    gridRowEnd="3"
                    gridRowStart="2"
                  >
                    {data.field.value?.type === "object" &&
                      enableInLineMapper &&
                      appEnableInLineMapper && (
                        <InlineMapper
                          currentDepth={1}
                          enabledNestedInlineMapper={
                            enableInLineMapper && appEnableInLineMapper
                          }
                          errorPrefix={`${name}[${index}].from`}
                          jsonColumnProperties={jsonColumnProperties}
                          retrieveErrorMessage={retrieveErrorMessage}
                          selectedOptionContext={optionContext}
                          templates={templates ?? []}
                          type="object"
                          value={data.field.value}
                          onChange={(value) => {
                            data.field.onChange({
                              ...data.field.value,
                              from: value,
                              type: MappingType.OBJECT,
                            });
                          }}
                          onChangeJsonColumnProperties={setJsonColumnProperties}
                          onReloadEligibleInlineMapperColumns={
                            loadEligibleInlineMapperColumns
                          }
                        />
                      )}
                    {data.field.value?.type === "array" &&
                      enableInLineMapper &&
                      appEnableInLineMapper &&
                      data.field.value?.from && (
                        <ArrayInlineMapper
                          currentDepth={1}
                          enabledNestedInlineMapper={
                            enableInLineMapper && appEnableInLineMapper
                          }
                          errorPrefix={`${name}[${index}].children`}
                          jsonColumnProperties={jsonColumnProperties}
                          retrieveErrorMessage={retrieveErrorMessage}
                          selectedOptionContext={optionContext}
                          templates={templates ?? []}
                          value={data.field.value}
                          onChange={(value) => {
                            data.field.onChange({
                              ...data.field.value,
                              children: value,
                              type: MappingType.ARRAY,
                            });
                          }}
                          onChangeJsonColumnProperties={setJsonColumnProperties}
                          onReloadEligibleInlineMapperColumns={
                            loadEligibleInlineMapperColumns
                          }
                        />
                      )}
                  </Box>
                </Box>
              );
            }}
          />
        </Fragment>
      ))}
      <ButtonGroup>
        <Button
          isDisabled={permission.unauthorized}
          onClick={() => {
            append({ type: "standard" });
          }}
        >
          Add mapping
        </Button>

        {(model || columns.length > 0) && (
          <Tooltip
            isDisabled={
              permission.unauthorized || watchAutoSyncColumns || loading
            }
            message={
              isSuggested
                ? "All possible suggestions applied"
                : "Suggest mappings"
            }
          >
            <IconButton
              aria-label="Suggest mappings"
              icon={WandIcon}
              isDisabled={
                permission.unauthorized || watchAutoSyncColumns || loading
              }
              variant="secondary"
              onClick={() => {
                append(suggestColumns());
              }}
            />
          </Tooltip>
        )}
      </ButtonGroup>
    </Column>
  );
};

const isTraitEnrichment = (value: unknown): boolean => {
  return get(value, "type") === "additionalColumnReference";
};

const isTraitLike = (value: unknown): boolean => {
  return isTraitEnrichment(value) || get(value, "column.type") === "trait";
};

export const formatFromColumnOption = (option) => {
  if (isTraitLike(option.value)) {
    return (
      <Row sx={{ alignItems: "center", gap: 1 }}>
        <Box as={TraitIcon} color="text.secondary" boxSize={4} />
        <Text>{option.label}</Text>
        {isTraitEnrichment(option.value) && (
          <Tooltip message="Audience-specific trait enrichment">
            <Box as={AudienceIcon} color="text.secondary" boxSize={4} />
          </Tooltip>
        )}
      </Row>
    );
  }

  return option?.label;
};

export const extendedTypeToFormkitOptions = (
  extendedOption: ExtendedOption | undefined,
): SelectedOptionContext[] | undefined => {
  let childProperties: { [key: string]: ExtendedTypesSync } | undefined;

  if (!extendedOption) return undefined;

  if (
    extendedOption.extendedType?.type === "OBJECT" &&
    extendedOption.extendedType?.properties
  ) {
    childProperties = extendedOption.extendedType.properties;
  }

  if (
    extendedOption.extendedType?.type === "ARRAY" &&
    extendedOption.extendedType?.items &&
    extendedOption.extendedType?.items?.type === "OBJECT"
  ) {
    childProperties = extendedOption.extendedType.items?.properties;
  }

  if (!childProperties) return undefined;

  const options: SelectedOptionContext[] = [];

  for (const [key, value] of Object.entries(childProperties)) {
    if (value.type === "OBJECT" && value.properties) {
      const nestedOption: SelectedOptionContext[] = [];

      for (const [nestedKey, nestedValue] of Object.entries(value.properties)) {
        nestedOption.push({
          value: nestedKey,
          label: nestedValue.label ?? nestedKey,
          required: nestedValue.required,
          type: nestedValue.type,
        });
      }

      options.push({
        value: key,
        label: value.label ?? key,
        required: value.required,
        type: value.type,
        properties: nestedOption,
      });
    } else {
      options.push({
        value: key,
        label: value.label ?? key,
        required: value.required,
        type: value.type,
      });
    }
  }

  return options;
};
