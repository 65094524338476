import { AttributionMethodType } from "src/types/visual";

export const WeightingOptions = [
  { label: "Participation", value: AttributionMethodType.Participation },
  { label: "Last touch", value: AttributionMethodType.LastTouch },
  { label: "First touch", value: AttributionMethodType.FirstTouch },
];

export const WeightingOptionMap = WeightingOptions.reduce(
  (all, option) => {
    all[option.value] = option.label;

    return all;
  },
  {} as Record<AttributionMethodType, string>,
);
