import { useToast } from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import isEqual from "lodash/isEqual";
import { useMemo } from "react";
import { UseHightouchFormReturn } from "src/components/form";
import {
  OrderByCampaignColumn,
  useCreateSavedCampaignViewMutation,
  useUpdateSavedCampaignViewMutation,
} from "src/graphql";
import {
  ConversionMetric,
  FilterState,
  PredefinedMetric,
  RequiredAssetModelFields,
  SavedCampaignView,
} from "src/pages/campaigns/types";
import { useNavigate, useOutletContext, useParams } from "src/router";
import {
  AssetType,
  AttributionTimeWindow,
  ConditionType,
  FilterableColumn,
  PropertyCondition,
} from "src/types/visual";
import { queryClient } from "src/lib/react-query";
import { CampaignsOutletContext } from "../types";
import { shouldShowPredefinedMetric } from "../utils";
import { sortPredefinedMetricsByColumnName } from "./utils";

export function useSavedCampaignView({
  assetModel,
  columns,
  predefinedMetrics,
  conversionMetrics,
}: {
  assetModel: RequiredAssetModelFields;
  columns: FilterableColumn[];
  predefinedMetrics: PredefinedMetric[];
  conversionMetrics: ConversionMetric[];
}) {
  const { parentModelId, savedView } =
    useOutletContext<CampaignsOutletContext>();
  const { toast } = useToast();
  const navigate = useNavigate();
  const { assetType } = useParams<{
    assetType: string;
    savedViewId?: string;
  }>();

  function invalidateSavedViewsCache() {
    return queryClient.invalidateQueries({
      predicate: (query) =>
        (query.queryKey[0] as string).includes("SavedCampaignView"),
    });
  }

  const createSavedCampaignViewMutation = useCreateSavedCampaignViewMutation({
    onSuccess: invalidateSavedViewsCache,
  });
  const updateSavedCampaignViewMutation = useUpdateSavedCampaignViewMutation({
    onSuccess: invalidateSavedViewsCache,
  });

  const initialValues: FilterState = useMemo(() => {
    const initialSelections = transformSavedCampaignViewToFilters({
      assetType: assetModel.type as AssetType,
      view: savedView,
      columns,
      predefinedMetrics,
      conversionMetrics,
    });

    // Back compat: old saved views have {} in their .sort field
    const orderBy = initialSelections?.sortBy.type
      ? initialSelections?.sortBy
      : undefined;

    return {
      name: savedView?.name ?? "",
      description: savedView?.description || null,
      assetModelFilters: initialSelections?.filters ?? [],
      metricFilters: [],
      selectedColumns: [],
      selectedConversionMetrics:
        initialSelections?.selectedConversionMetrics ?? conversionMetrics,
      selectedPredefinedMetrics:
        initialSelections?.selectedPredefinedMetrics ??
        predefinedMetrics.filter(shouldShowPredefinedMetric),
      timeWindow:
        initialSelections?.timeWindow ?? AttributionTimeWindow.SevenDays,
      orderBy,
    };
  }, [savedView, columns]);

  const createCampaignView = async ({
    name,
    description,
    form,
  }: {
    name: string;
    description: string | null;
    form: UseHightouchFormReturn<FilterState, void>;
  }) => {
    const data = form.getValues();
    const conversionMetricInput = data.selectedConversionMetrics.map(
      (metric) => ({
        goal_id: metric.id,
        attribution_method_id: metric.attributionMethod.id,
      }),
    );
    const predefinedMetricInput = data.selectedPredefinedMetrics.map(
      (metric) => ({
        goal_id: metric.id,
        attribution_method_id: null,
      }),
    );

    try {
      const result = await createSavedCampaignViewMutation.mutateAsync({
        input: {
          name,
          description,
          asset_model_id: assetModel.model_id,
          parent_model_id: parentModelId,
          column_references: data.selectedColumns.map(
            (column) => column.column_reference,
          ),
          filters: {
            type: ConditionType.And,
            conditions: data.assetModelFilters,
          },
          goals: {
            data: [...conversionMetricInput, ...predefinedMetricInput],
          },
          sort: data.orderBy || {},
          time_window: data.timeWindow,
        },
      });

      navigate(
        `/campaigns/${assetType}/${result.insert_saved_campaign_views_one?.id}?id=${parentModelId}`,
      );
    } catch (error) {
      Sentry.captureException(error);

      toast({
        id: "save-campaign-view-error",
        title: "Error saving view",
        message: "An error occurred while saving the view",
        variant: "error",
      });

      throw error;
    }
  };

  const updateCampaignView = async (data: FilterState) => {
    if (!savedView?.id) {
      Sentry.captureException(
        new Error("[Saved campaign views] No view ID provided for update"),
      );
      return;
    }

    const campaignViewIdToUpdate = savedView.id;

    try {
      const goalsToRemove = savedView?.goals.map(({ id }) => id) ?? [];

      const conversionMetricInput = data.selectedConversionMetrics.map(
        (metric) => ({
          goal_id: metric.id,
          attribution_method_id: metric.attributionMethod.id,
          saved_campaign_view_id: campaignViewIdToUpdate,
        }),
      );
      const predefinedMetricInput = data.selectedPredefinedMetrics.map(
        (metric) => ({
          goal_id: metric.id,
          attribution_method_id: null,
          saved_campaign_view_id: campaignViewIdToUpdate,
        }),
      );

      await updateSavedCampaignViewMutation.mutateAsync({
        id: campaignViewIdToUpdate,
        input: {
          name: data.name,
          description: data.description,
          asset_model_id: assetModel.model_id,
          parent_model_id: parentModelId,
          column_references: data.selectedColumns.map(
            (column) => column.column_reference,
          ),
          filters: {
            type: ConditionType.And,
            conditions: data.assetModelFilters,
          },
          sort: data.orderBy || {},
          time_window: data.timeWindow,
        },
        bridge_goal_row_ids_to_remove: goalsToRemove,
        goals_to_add: [...conversionMetricInput, ...predefinedMetricInput],
      });
    } catch (error) {
      Sentry.captureException(error);

      throw error;
    }
  };

  return {
    initialValues,
    createCampaignView,
    updateCampaignView,
  };
}

type transformSavedViewToFiltersReturn = {
  filters: PropertyCondition[];
  selectedColumns: FilterableColumn[];
  selectedConversionMetrics: ConversionMetric[];
  selectedPredefinedMetrics: PredefinedMetric[];
  timeWindow: AttributionTimeWindow;
  sortBy: OrderByCampaignColumn;
};

export const transformSavedCampaignViewToFilters = ({
  assetType,
  view,
  columns,
  conversionMetrics,
  predefinedMetrics,
}: {
  assetType: AssetType;
  view: SavedCampaignView;
  columns: FilterableColumn[];
  conversionMetrics: ConversionMetric[];
  predefinedMetrics: PredefinedMetric[];
}): transformSavedViewToFiltersReturn | undefined => {
  if (!view) {
    return undefined;
  }

  const selectedColumns = columns.filter((column) =>
    view.column_references.some((column_reference) =>
      isEqual(column_reference, column.column_reference),
    ),
  );

  const selectedConversionMetrics: ConversionMetric[] = [];
  const selectedPredefinedMetrics: PredefinedMetric[] = [];

  view.goals.forEach(({ goal, attribution_method }) => {
    if (attribution_method) {
      const conversionMetricInformation = conversionMetrics.find(
        (predefinedMetric) => predefinedMetric.id === goal.id,
      );

      // If no conversion metric is found, ignore
      if (!conversionMetricInformation) {
        return;
      }

      selectedConversionMetrics.push({
        id: goal.id,
        name: goal.name,
        description: goal.description,
        attributionMethod: {
          id: attribution_method.id,
          name: attribution_method.name,
        },
        aggregation: conversionMetricInformation.aggregation,
        audience_aggregation: conversionMetricInformation.audience_aggregation,
      });
    } else {
      const predefinedMetricInformation = predefinedMetrics.find(
        (predefinedMetric) => predefinedMetric.id === goal.id,
      );

      // If no predefined metric is found, ignore
      if (!predefinedMetricInformation) {
        return;
      }

      selectedPredefinedMetrics.push({
        id: goal.id,
        name: goal.name,
        description: goal.description,
        config: predefinedMetricInformation.config,
        aggregation: predefinedMetricInformation.aggregation,
        audience_aggregation: predefinedMetricInformation.audience_aggregation,
      });
    }
  });

  return {
    filters: view.filters.conditions,
    selectedColumns,
    selectedConversionMetrics,
    selectedPredefinedMetrics: sortPredefinedMetricsByColumnName(
      assetType,
      selectedPredefinedMetrics,
    ),
    timeWindow: view.time_window,
    sortBy: view.sort,
  };
};
