import { FC, useEffect, useState } from "react";
import { FormkitTable } from "./table";
import { TableProps, TableColumnProps } from "@hightouch/formkit";
import { useTunnelsQuery } from "src/graphql";
import { TunnelSelect } from "../tunnel-select";
import { Column } from "@hightouchio/ui";

type Props = TableProps & {
  tunnelColumnIndex?: number;
  onChange: any;
};
/**
 * TunnelTable will append the TunnelSelect component at the provided tunnelColumnIndex, or as the last column
 * with the columnKey tunnelId
 */
export const TunnelTable: FC<Readonly<Props>> = ({
  addRow,
  addRowLabel,
  createTunnel,
  columns,
  value,
  onChange,
  tunnelColumnIndex,
}) => {
  const tunnelColumn: TableColumnProps = {
    header: "Tunnel",
    columnKey: "tunnelId",
    ...(createTunnel
      ? {
          componentType: "react",
          component: (value: string, onChange) => (
            <Column>
              <TunnelSelect
                value={{ id: value }}
                onChange={(tunnel) => onChange(tunnel?.id)}
                field={false}
              />
            </Column>
          ),
        }
      : {
          componentType: "select",
          options: [],
        }),
  };

  const { data, isFetching } = useTunnelsQuery();
  const [tableColumns, setTableColumns] = useState(
    tunnelColumnIndex
      ? columns.toSpliced(tunnelColumnIndex, 0, tunnelColumn)
      : columns.concat(tunnelColumn),
  );

  useEffect(() => {
    let tunnelOptions;
    if (data && !createTunnel) {
      tunnelOptions =
        data.getTunnels?.map(({ tunnel: { name, id } }) => ({
          label: name ?? "",
          value: id ?? "",
        })) ?? [];

      setTableColumns(
        tableColumns.map((tcol) => {
          if (tcol.columnKey === tunnelColumn.columnKey) {
            return {
              ...tcol,
              options: tunnelOptions,
            };
          }
          return tcol;
        }),
      );
    }
  }, [isFetching]);

  return (
    <FormkitTable
      columns={tableColumns}
      addRow={addRow}
      addRowLabel={addRowLabel}
      value={value}
      onChange={onChange}
    />
  );
};
