import { useEffect, useMemo, useState } from "react";
import { processFormNode } from "../../../../../formkit/formkit";
import {
  useHightouchForm,
  Form,
  FormActions,
  SubmitButton,
} from "../../../../../components/form";
import {
  DestinationDefinitionFragment,
  useFormkitSharedConfigValidationQuery,
} from "../../../../../graphql";
import { cleanConfig } from "../../../../../components/destinations/utils";
import { useQueryClient } from "react-query";
import {
  FormkitDestination,
  FormkitProvider,
} from "../../../../../formkit/components/formkit-context";
import {
  Button,
  ButtonGroup,
  Column,
  Dialog,
  Row,
  useToast,
} from "@hightouchio/ui";
import { DestinationFormProvider } from "../../../../../contexts/destination-form-context";
import { eventSourceDefinitions } from "../../../../types";
import json5 from "json5";
import { Editor } from "../../../../../components/editor";
import { ActionBar } from "../../../../../components/action-bar";
import { useFlags } from "launchdarkly-react-client-sdk";
import { DeleteConfirmationModal } from "../../../../../components/modals/delete-confirmation-modal";
import { isObject } from "lodash";
import { Subscription } from "../../types";
import Filter from "./filter";
import { DeepPartial } from "react-hook-form";
import { isEqual } from "lodash";

export default function EventConfigurationForm<Config extends Subscription>({
  formkitDefinition,
  destination,
  config,
  submit,
  submitTrigger,
  source,
  validation,
  onDelete,
  saved,
  hideActions,
  otherFilters,
}: {
  formkitDefinition: any;
  destination: {
    type: string;
    name: string | null;
    id: any;
    definition: { name: string; icon: string };
  };
  source: {
    type: string;
    name: string;
  };
  config: DeepPartial<Config>;
  submit: (config: Config) => Promise<any>;
  // Allow parent to handle form submission
  submitTrigger?: React.MutableRefObject<
    (() => Promise<Config | undefined>) | undefined
  >;
  validation?: typeof useFormkitSharedConfigValidationQuery;
  onDelete?: () => Promise<any>;
  saved?: boolean;
  hideActions?: boolean;
  otherFilters?: boolean;
}) {
  const flags = useFlags();
  const [trackedEvent, setTrackedEvent] = useState<string>();
  const [isInitialized, setIsInitialized] = useState(false);
  const [errors, setErrors] = useState<string[]>();
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditingAsJSON, setIsEditingAsJSON] = useState(false);

  const validate = useValidate(
    destination?.type,
    validation ?? useFormkitSharedConfigValidationQuery,
  );
  const onSubmit = async (data, filter?: any) => {
    if (!isObject(data)) throw "Couldn't save the sync configuration";
    const cleanedConfig = cleanConfig(data);
    const errors = await validate(cleanedConfig);
    if (typeof errors === "object" && Object.keys(errors).length) {
      Object.entries(errors).forEach(([key, message]) => {
        methods.setError(key, { message: String(message) });
      });

      setErrors(errors);
      throw "Couldn't save the sync configuration";
    } else {
      setErrors(undefined);

      return await submit({
        syncConfig: data,
        trackedEvent: config.trackedEvent,
        // Make this configurable
        filter: filter ?? filterForm.getValues().filter,
      } as Config);
    }
  };
  const methods = useHightouchForm({
    onSubmit,
    values: {
      ...config.syncConfig,
      __filter: config.filter,
    },
  });
  const filterForm = useHightouchForm({
    onSubmit: (filter) => onSubmit(methods.getValues(), filter),
    values: {
      filter: config.filter,
    },
  });

  // We need to make sure the dirty state and reset works across the different forms
  const shadowFilter = methods.watch("__filter");
  const filter = filterForm.watch("filter");
  useEffect(() => {
    if (
      isEqual(shadowFilter, config.filter) &&
      !isEqual(filterForm.getValues("filter"), config.filter)
    ) {
      // Main form was reset
      filterForm.reset();
    }
  }, [shadowFilter, config.filter]);
  useEffect(() => {
    if (!isEqual(methods.getValues("__filter"), filter)) {
      // Filter form was updated
      methods.setValue("__filter", filter, {
        shouldDirty: true,
      });
    }
  }, [filter]);

  if (submitTrigger) {
    submitTrigger.current = () => onSubmit(methods.getValues());
  }
  const formkit = useMemo(() => {
    if (!formkitDefinition) {
      return null;
    }
    return processFormNode(formkitDefinition, 0, {});
  }, [formkitDefinition]);

  // XXX: Existing state should be set via reset without setting the default values
  // This is because default values should be set by the components only
  useEffect(() => {
    if (config.syncConfig && Object.keys(config.syncConfig).length) {
      methods.reset(
        { ...config.syncConfig, __filter: config.filter },
        { keepDefaultValues: true },
      );
    }
    if (config.filter && Object.keys(config.filter).length) {
      filterForm.reset({ filter: config.filter }, { keepDefaultValues: true });
    }
    methods.setValue("trackedEvent", config.trackedEvent, {
      shouldDirty: !saved,
    });
    setTrackedEvent(config.trackedEvent);
    setIsInitialized(true);
  }, [config.trackedEvent, config.syncConfig]);

  if (!isInitialized || trackedEvent !== config.trackedEvent) {
    return null;
  }

  return (
    <>
      <FormkitProvider
        validate={validate}
        destination={destination as FormkitDestination}
        destinationDefinition={
          destination.definition as DestinationDefinitionFragment
        }
        sourceDefinition={{
          ...eventSourceDefinitions[source.type],
          ...source,
          name: `the "${source.name}" event`,
        }}
      >
        <DestinationFormProvider
          errors={errors}
          setErrors={setErrors}
          config={methods.getValues()}
          /* eslint-disable-next-line @typescript-eslint/no-empty-function */
          setConfig={() => {}}
          setCustomValidation={() => {}}
        >
          <Form form={filterForm}>
            <Row p={6} pb={0}>
              <Filter othersExist={otherFilters} />
            </Row>
          </Form>
          <Form form={methods}>
            <Row p={6} flex={1}>
              <Column gap={8} flex={1}>
                {formkit}
              </Column>
            </Row>
            {!hideActions && (
              <ActionBar fit>
                {saved ? (
                  <FormActions />
                ) : (
                  <SubmitButton variant="primary">Add event</SubmitButton>
                )}
                <ButtonGroup>
                  {flags.eventForwardingSyncJsonEditor && (
                    <Button
                      onClick={() => {
                        setIsEditingAsJSON(true);
                      }}
                    >
                      Edit JSON
                    </Button>
                  )}
                  {onDelete && (
                    <Button
                      variant="warning"
                      onClick={() => {
                        if (saved) {
                          setIsDeleting(true);
                        } else {
                          onDelete();
                        }
                      }}
                    >
                      Delete
                    </Button>
                  )}
                </ButtonGroup>
              </ActionBar>
            )}
          </Form>
        </DestinationFormProvider>
      </FormkitProvider>
      {onDelete && (
        <DeleteConfirmationModal
          label="event"
          isOpen={isDeleting}
          onDelete={onDelete}
          onClose={() => {
            setIsDeleting(false);
          }}
        />
      )}
      <JSONEditor
        isOpen={isEditingAsJSON}
        onClose={() => {
          setIsEditingAsJSON(false);
        }}
        config={methods.getValues}
        onChange={(config) => {
          methods.reset(config, { keepDirty: true });
        }}
      />
    </>
  );
}

function useValidate(
  type: string | undefined,
  validationQuery: typeof useFormkitSharedConfigValidationQuery,
) {
  const client = useQueryClient();
  const formkitValidate = async (config) => {
    const response = await client.fetchQuery({
      queryFn: validationQuery.fetcher({
        type: type ?? "",
        config,
      }),
      queryKey: validationQuery.getKey(config),
    });

    return response.validation;
  };

  return async (config) => {
    const cleanedConfig = cleanConfig(config);
    const errors = await formkitValidate(cleanedConfig);
    if (typeof errors === "object" && Object.keys(errors).length) {
      return errors;
    }
  };
}

export const JSONEditor = ({ isOpen, onClose, config, onChange }) => {
  const { toast } = useToast();
  const [value, setValue] = useState("{}");
  const [realValue, setRealValue] = useState("{}");
  useEffect(() => {
    const real = json5.stringify(
      {
        ...config(),
        __filter: undefined,
        _eventName: undefined,
      },
      null,
      2,
    );
    setRealValue(real);
    setValue(real);
  }, [isOpen]);
  return (
    <Dialog
      isOpen={isOpen}
      variant="form"
      title="Edit config"
      width="xl"
      actions={
        <ButtonGroup>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            variant="primary"
            isDisabled={realValue === value}
            onClick={() => {
              try {
                onChange(json5.parse(value));
                onClose();
              } catch (error) {
                toast({
                  id: "apply-json-config",
                  title: "This configuration is not valid JSON",
                  message: String(error),
                  variant: "error",
                });
              }
            }}
          >
            Apply changes
          </Button>
        </ButtonGroup>
      }
      onClose={onClose}
    >
      <Editor value={value} language="json" onChange={setValue} />
    </Dialog>
  );
};
