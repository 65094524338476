import { FC, useState } from "react";

import {
  useToast,
  Column,
  Row,
  MenuActionsButton,
  MenuList,
  Menu,
  DeleteIcon,
  Box,
  EditableDescription,
} from "@hightouchio/ui";
import {
  Routes,
  Route,
  useParams,
  Outlet,
  useNavigate,
  useOutletContext,
  Navigate,
} from "src/router";

import { Page } from "src/components/layout";
import { DeleteConfirmationModal } from "src/components/modals/delete-confirmation-modal";
import { Warning } from "src/components/warning";
import {
  eventForwardingDestinationDefinitions,
  eventWarehouseDestinationDefinitions,
} from "src/events/types";
import {
  EventWarehouseDestinationsSetInput,
  useDeleteEventWarehouseDestinationMutation,
  useEventWarehouseDestinationQuery,
  useUpdateEventWarehouseDestinationMutation,
  useDestinationQuery,
  useDeleteDestinationMutation,
  useUpdateDestinationV2Mutation,
} from "src/graphql";
import { PageSpinner } from "src/components/loading";

import { SchemaSettings } from "./schema-settings";
import { validate as validateUUID } from "uuid";
import { Configuration } from "./configuration";
import { OutletContext } from "./types";
import {
  PermissionedEditableHeading,
  PermissionedMenuItem,
} from "src/components/permission";
import { RouteTabs } from "src/components/route-tabs";

export const EventDestinationRouter: FC = () => {
  return (
    <Routes>
      <Route element={<Loader />}>
        <Route element={<Layout />}>
          <Route path="configuration" element={<Configuration />} />
          <Route path="sync-settings" element={<SchemaSettings />} />
          <Route
            index
            element={
              <Navigate
                to={{ pathname: "configuration", search: location.search }}
                replace
              />
            }
          />
        </Route>
      </Route>
    </Routes>
  );
};

const Loader: FC = () => {
  const { id } = useParams<{ id: string }>();

  if (!id) {
    return null;
  }

  // Event warehouse destinations are a separate data model from other destinations, and use UUIDs,
  // while event forwarding destinations use bigint ids, and are stored in the same table as rETL destinations.
  const type = validateUUID(id) ? "warehouse" : "forwarding";

  const { data: destination, isLoading } =
    type === "forwarding"
      ? useDestinationQuery(
          { id: String(id) },
          {
            enabled: Boolean(id),
            select: (data) => data.destinations_by_pk,
          },
        )
      : useEventWarehouseDestinationQuery(
          { id: String(id) },
          {
            enabled: Boolean(id),
            select: (data) => data.event_warehouse_destinations_by_pk,
          },
        );

  if (isLoading) {
    return <PageSpinner />;
  }

  if (!destination) {
    return (
      <Warning
        subtitle="It may have been deleted"
        title="Event destination not found"
      />
    );
  }

  return <Outlet context={{ type, destination }} />;
};

const Layout: FC = () => {
  const { type, destination } = useOutletContext<OutletContext>();

  const { toast } = useToast();
  const navigate = useNavigate();
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteMutationForwarding = useDeleteDestinationMutation();
  const updateMutationForwarding = useUpdateDestinationV2Mutation();

  const deleteMutationWarehouse = useDeleteEventWarehouseDestinationMutation();
  const updateMutationWarehouse = useUpdateEventWarehouseDestinationMutation();

  const update = async (input: EventWarehouseDestinationsSetInput) => {
    try {
      if (type === "forwarding") {
        await updateMutationForwarding.mutateAsync({
          id: destination.id.toString(),
          destination: input,
        });
      } else {
        await updateMutationWarehouse.mutateAsync({
          id: destination.id,
          input,
        });
      }

      toast({
        id: "update-event-destination",
        title: `Event destination was updated`,
        variant: "success",
      });
    } catch (error) {
      toast({
        id: "update-event-destination-error",
        title: `There was a problem updating your event destination.`,
        variant: "error",
      });
    }
  };

  const iconSrc =
    type === "warehouse"
      ? eventWarehouseDestinationDefinitions[destination.type].icon
      : eventForwardingDestinationDefinitions[destination.type].icon;

  // TODO: should refactor this to use `DetailPage` component to be more consistent with rETL destinations page
  return (
    <Page
      title={destination.name || "Destination"}
      crumbs={[{ label: "All destinations", link: "/events/destinations" }]}
    >
      <Column minWidth={0} mb={4} gap={2}>
        <Row flex={1} justify="space-between" minWidth={0} pt={1}>
          <Row gap={4} align="center">
            {/* TODO: rETL destination pages have a nicer looking header+subheader, may want to re-use */}
            <Box as="img" src={iconSrc} width="32px" />
            <PermissionedEditableHeading
              permission={
                type === "warehouse"
                  ? undefined
                  : {
                      v2: {
                        resource: "destination",
                        grant: "can_update",
                        id: destination.id,
                      },
                    }
              }
              size="lg"
              value={destination.name ?? ""}
              onChange={(name) => {
                update({ name });
              }}
            />
          </Row>

          <Row align="center" gap={4}>
            <Menu>
              <MenuActionsButton variant="secondary" />
              <MenuList>
                <PermissionedMenuItem
                  permission={
                    type === "warehouse"
                      ? undefined
                      : {
                          v2: {
                            resource: "destination",
                            grant: "can_delete",
                            id: destination.id,
                          },
                        }
                  }
                  icon={DeleteIcon}
                  variant="danger"
                  onClick={() => {
                    setIsDeleting(true);
                  }}
                >
                  Delete
                </PermissionedMenuItem>
              </MenuList>
            </Menu>
          </Row>
        </Row>
        {type === "warehouse" && (
          <EditableDescription
            value={destination.description || ""}
            onChange={(description) => {
              update({ description });
            }}
          />
        )}
      </Column>
      <RouteTabs
        mb={6}
        depth={5}
        tabs={[
          { path: "configuration", title: "Configuration" },
          {
            path: "sync-settings",
            title: "Schema settings",
            // Schema settings makes no sense for S3 destination, or for forwarding destinations
            isHidden: destination.type === "s3" || type === "forwarding",
          },
        ]}
      />

      <Outlet context={{ type, destination }} />

      <DeleteConfirmationModal
        isOpen={isDeleting}
        label="destination"
        onClose={() => {
          setIsDeleting(false);
        }}
        onDelete={async () => {
          if (type === "warehouse") {
            await deleteMutationWarehouse.mutateAsync({ id: destination.id });
          } else {
            await deleteMutationForwarding.mutateAsync({
              id: destination.id,
              stringId: destination.id.toString(),
            });
          }
          navigate("/events/destinations");
        }}
      />
    </Page>
  );
};
