import { Row, Select, Text } from "@hightouchio/ui";
import { orderBy } from "lodash";
import { FC } from "react";
import { Controller } from "react-hook-form";
import { useOrganizationWorkspacesWithUserGroupQuery } from "src/graphql";
import { Link, useOutletContext } from "src/router";

import { TextWithTooltip } from "src/components/text-with-tooltip";
import { Table } from "src/ui/table";
import { TableColumn, TableProps } from "src/ui/table/table";
import { DestinationsCell } from "./destinations-cell";
import { OutletContext } from "src/pages/organizations/roles/role";
import { BuiltInPermissionSet } from "src/pages/organizations/roles/types";
import { isBuiltInPermissionSet } from "src/pages/organizations/roles/utils";
import { useUser } from "src/contexts/user-context";

export type Workspace = {
  id: string;
  name: string;
  role: ReturnType<typeof getWorkspaceRole>;
  isApprovalsRequired: boolean;
};

export const useUserGroupWorkspaces = ({
  organizationId,
  userGroupId,
  search,
}): Array<Workspace> => {
  const { data } = useOrganizationWorkspacesWithUserGroupQuery(
    { organizationId, userGroupId },
    {
      select: (data) =>
        orderBy(data.workspaces, (w) => w.user_groups.length, "desc").map(
          (workspace) => ({
            id: workspace?.id,
            name: workspace?.name,
            role: getWorkspaceRole({
              is_in_workspace: Boolean(
                workspace?.user_groups?.[0]?.user_group_id,
              ),
              is_workspace_admin: Boolean(
                workspace?.user_groups?.[0]?.is_workspace_admin,
              ),
              built_in_permission_set:
                workspace?.user_groups?.[0]?.built_in_permission_set ?? null,
            }),
            isApprovalsRequired: Boolean(workspace?.approvals_required),
          }),
        ) ?? [],
      suspense: true,
    },
  );

  return data
    ? data.filter((workspace) =>
        search
          ? workspace?.name?.toLowerCase().includes(search.toLowerCase())
          : true,
      )
    : [];
};

const PERMISSION_SET_OPTIONS: {
  label: string;
  value: BuiltInPermissionSet | "custom" | "none";
}[] = [
  {
    label: "No access",
    value: "none",
  },
  {
    label: "Workspace admin",
    value: "workspace_admin",
  },
  {
    label: "Workspace editor",
    value: "workspace_editor",
  },
  {
    label: "Workspace draft editor",
    value: "workspace_draft_editor",
  },
  {
    label: "Workspace viewer",
    value: "workspace_viewer",
  },
  {
    label: "Custom...",
    value: "custom",
  },
];

export const getWorkspaceRole = ({
  is_in_workspace,
  is_workspace_admin,
  built_in_permission_set,
}: {
  is_in_workspace: boolean;
  is_workspace_admin: boolean;
  built_in_permission_set: string | null;
}): BuiltInPermissionSet | "custom" | "none" => {
  if (!is_in_workspace) {
    return "none";
  }
  if (is_workspace_admin) {
    return "workspace_admin";
  }
  if (isBuiltInPermissionSet(built_in_permission_set)) {
    return built_in_permission_set;
  }
  return "custom";
};

type Props = {
  workspaces: Array<Workspace>;
  placeholder: TableProps<Workspace>["placeholder"];
  isEditable: boolean;
};

export const Workspaces: FC<Readonly<Props>> = ({
  workspaces,
  placeholder,
  isEditable,
}) => {
  const context = useOutletContext<OutletContext>();
  const { workspace } = useUser();
  const isBusinessTier = workspace?.organization?.plan?.sku === "business_tier";

  return (
    <Controller
      name="workspaces"
      render={({ field }) => {
        const columns: TableColumn<Workspace>[] = [
          {
            name: "Workspace",
            cell: ({ name, id }) => {
              const value: Workspace = field.value?.[id];
              return (
                <TextWithTooltip
                  fontWeight="medium"
                  color={
                    value?.role === "none" ? "text.secondary" : "text.primary"
                  }
                >
                  {name}
                </TextWithTooltip>
              );
            },
          },
          {
            name: "Role",
            max: "max-content",
            cell: ({ id }) => {
              const value: Workspace = field.value?.[id];
              return (
                <Row alignItems="center">
                  <Select
                    options={PERMISSION_SET_OPTIONS}
                    isDisabled={!isEditable || !value}
                    isOptionDisabled={(option) =>
                      option.value === "custom" && !isBusinessTier
                    }
                    optionDescription={(option) =>
                      option.value === "custom" && !isBusinessTier
                        ? "Upgrade to Business Tier for custom roles"
                        : ""
                    }
                    value={value?.role}
                    onChange={(role) => {
                      field.onChange({
                        ...field.value,
                        [id]: { ...value, role },
                      });
                    }}
                  />
                </Row>
              );
            },
          },
          {
            name: "Access",
            max: "max-content",
            cell: (workspace) => {
              const value: Workspace = field.value?.[workspace.id];
              return (
                <DestinationsCell
                  workspaceRole={value?.role}
                  workspace={workspace}
                  userGroup={context.userGroup}
                />
              );
            },
          },
          {
            name: "",
            cell: ({ id, role }) => {
              const value: Workspace = field.value?.[id];
              return (
                <>
                  {value?.role === "custom" &&
                    role === "custom" &&
                    isEditable && (
                      <Link href={String(id)}>Edit custom role...</Link>
                    )}
                  {value?.role === "custom" && role !== "custom" && (
                    <Text color="text.secondary">
                      Save your changes before editing this custom role
                    </Text>
                  )}
                </>
              );
            },
          },
        ];

        return (
          <Table
            data={workspaces}
            columns={columns}
            placeholder={placeholder}
          />
        );
      }}
    />
  );
};
