import { FC, useEffect, useState } from "react";

import {
  Avatar,
  Box,
  ChakraListItem,
  ChakraUnorderedList,
  Column,
  ConfirmationDialog,
  CopyIcon,
  DeleteIcon,
  EditIcon,
  EditableDescription,
  FolderIcon,
  LinkIcon,
  Menu,
  MenuActionsButton,
  MenuDivider,
  MenuList,
  Paragraph,
  Row,
  Text,
  useToast,
} from "@hightouchio/ui";
import { Link } from "src/router";
import * as Sentry from "@sentry/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import capitalize from "lodash/capitalize";

import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useNavigate,
  useOutletContext,
  useParams,
} from "src/router";

import { AddSyncButton } from "src/components/audiences/add-sync/add-sync-button";
import { DetailBar } from "src/components/detail-bar";
import { MoveFolder } from "src/components/folders/move-to-folder";
import { useFolder } from "src/components/folders/use-folder";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { EditLabelModal } from "src/components/labels/edit-label-modal";
import { Labels } from "src/components/labels/labels";
import { ResourceType } from "src/components/labels/use-labels";
import { DetailPage } from "src/components/layout";
import { Crumb } from "src/components/layout/header/breadcrumbs";
import { PageSpinner } from "src/components/loading";
import { PermissionProvider } from "src/components/permission/permission-context";
import { Warning } from "src/components/warning";
import { useUser } from "src/contexts/user-context";
import {
  useAudienceParentQuery,
  useAudienceQuery,
  useAudienceRelatedResourcesQuery,
  useDeleteAudienceMutation,
  useSyncTemplatesForParentModelQuery,
  useUpdateAudienceMutation,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { QueryType } from "src/types/models";
import { isPresent } from "src/types/utils";
import { formatDate } from "src/utils/time";

import { CreateTraitEnrichment } from "src/components/audiences/trait-enrichments/create-trait-enrichment";
import {
  DependenciesModal,
  DependencyTableData,
} from "src/components/modals/dependencies-modal";
import {
  PermissionedEditableHeading,
  PermissionedMenuItem,
} from "src/components/permission";
import { useResourcePermission } from "src/components/permission/use-resource-permission";
import { AudiencePageOutletState } from "src/pages/audiences/types";
import { AudienceActivity } from "./activity";
import { AudienceDefinition } from "./definition";
import { AudienceSplits } from "./splits";
import { AudienceSyncs } from "./syncs";
import { AudienceTraits } from "./traits";
import { RouteTab, useTabState } from "src/components/route-tabs";
import { isTraitColumn, VisualQueryFilter } from "src/types/visual";
import { getUniqueObjectsByKey } from "src/utils/array";

export const AudienceLoader = () => {
  const { id } = useParams<{ id?: string }>();

  const {
    data: audience,
    refetch,
    isFetching: audienceFetching,
  } = useAudienceQuery(
    {
      id: id ?? "",
    },
    {
      enabled: Boolean(id),
      select: (data) => data.segments_by_pk,
      suspense: true,
    },
  );

  const audienceParentQuery = useAudienceParentQuery(
    {
      audience_id: id ?? "",
    },
    {
      enabled: Boolean(id),
      select: (data) => data.segments_by_pk?.parent,
      suspense: true,
    },
  );

  const parentModel = audienceParentQuery.data;

  if (!id) {
    return <PageSpinner />;
  }

  if (!audience || !parentModel) {
    return (
      <Warning subtitle="It may have been deleted" title="Audience not found" />
    );
  }

  return (
    <Outlet
      context={{
        audience,
        parentModel,
        audienceFetching: audienceFetching || audienceParentQuery.isFetching,
        refetch,
        id,
      }}
    />
  );
};

export const Audience: FC = () => {
  return (
    <Routes>
      <Route element={<AudienceLoader />}>
        <Route path="*" element={<AudienceDetails />}>
          <Route index element={<Navigate to="definition" replace />} />
          <Route path="definition" element={<AudienceDefinition />} />
          <Route
            path="query"
            element={<Navigate to="../definition" replace />}
          />
          <Route path="splits" element={<AudienceSplits />} />
          <Route path="syncs" element={<AudienceSyncs />} />
          <Route path="traits" element={<AudienceTraits />}>
            <Route path="new" element={<CreateTraitEnrichment />} />
          </Route>
          <Route path="activity" element={<AudienceActivity />} />
        </Route>
      </Route>
    </Routes>
  );
};

export const AudienceDetails: FC = () => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const { workspace } = useUser();
  const [deleteModal, setDeleteModal] = useState(false);
  const [editLabelsModal, setEditLabelsModal] = useState(false);
  const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);

  const { schemaV2 } = useFlags();

  const { audience, parentModel, refetch, audienceFetching, id } =
    useOutletContext<AudiencePageOutletState>();

  const visualQueryFilter = audience?.visual_query_filter as VisualQueryFilter;
  const traits =
    visualQueryFilter?.additionalColumns?.filter(
      (col) => col.column?.column && isTraitColumn(col.column.column),
    ) || [];

  const { mutateAsync: updateAudience } = useUpdateAudienceMutation();
  const { mutateAsync: deleteAudience } = useDeleteAudienceMutation();
  const { data: syncTemplatesData } = useSyncTemplatesForParentModelQuery(
    { parentModelId: parentModel.id },
    { enabled: Boolean(parentModel) },
  );

  const audienceRelatedResourcesQuery = useAudienceRelatedResourcesQuery({
    id,
  });

  const { isPermitted: hasViewSchemaPermissions } = useResourcePermission({
    v2: { resource: "model", grant: "can_read", id: parentModel.id },
    v1: { resource: "audience_schema", grant: "read" },
  });

  const { isPermitted: userCanUpdate } = useResourcePermission({
    v2: { resource: "model", grant: "can_update", id: String(id) },
    v1: { resource: "audience", grant: "update", id },
  });

  const folder = useFolder({
    folderId: audience?.folder?.id ?? null,
    folderType: "audiences",
    viewType: "models",
  });

  const updatedByUsername =
    audience.updated_by_user?.name || audience.created_by_user?.name;
  const membership = audience?.priority_list_memberships?.[0];
  const syncs = audience?.syncs;

  const idsOfJourneysBeingDeleted = new Set(
    audienceRelatedResourcesQuery.data?.journeysBeingDeleted.map(
      ({ id }) => id,
    ),
  );

  // Many journey version may be associated with this audience
  const uniqueJourneys = getUniqueObjectsByKey(
    audienceRelatedResourcesQuery.data?.journeys ?? [],
    "id",
  );

  const dependencies: DependencyTableData[] = [
    {
      name: "Journeys",
      resources: uniqueJourneys.map((journey) => ({
        id: journey.id,
        name: journey.name,
        description: idsOfJourneysBeingDeleted.has(journey.id)
          ? "Deletion in progress"
          : undefined,
        url: `/journeys/${journey.id}`,
        created_at: journey.created_at,
        updated_at: journey.updated_at,
        created_by_user: journey.created_by_user,
        updated_by_user: journey.updated_by_user,
      })),
    },
    {
      name: "Priority lists",
      resources: (
        audienceRelatedResourcesQuery.data?.priority_lists ?? []
      )?.map((priorityList) => ({
        id: priorityList.id,
        name: priorityList.name,
        url: `/priority-lists/${priorityList.id}`,
        created_at: priorityList.created_at,
        updated_at: priorityList.updated_at,
        created_by_user: priorityList.created_by_user,
        updated_by_user: priorityList.updated_by_user,
      })),
    },
    {
      name: "Saved charts",
      resources: (
        audienceRelatedResourcesQuery.data?.saved_analytics_charts ?? []
      )?.map((chart) => ({
        id: chart.id,
        name: chart.name,
        description: capitalize(chart.chart_type),
        chart_type: chart.chart_type,
        url: `/analytics/${chart.id}`,
        created_at: chart.created_at,
        updated_at: chart.updated_at,
        created_by_user: chart.created_by_user,
        updated_by_user: chart.updated_by_user,
      })),
    },
  ].filter(({ resources }) => resources.length > 0);

  const source = audience?.connection;

  const splits = audience?.splits;
  const labelKeys = Object.keys(audience?.labels || {});

  const syncTemplates = syncTemplatesData?.sync_templates;

  const hasSyncTemplates =
    Array.isArray(syncTemplates) && syncTemplates.length > 0;
  const cannotCreateNewSyncs =
    workspace?.sync_templates_only && !hasSyncTemplates;

  const onUpdate = (showToast = true) => {
    analytics.track("Model Updated", {
      model_id: id,
      model_type: QueryType.Visual,
      model_name: audience?.name,
      source_id: source?.id,
      source_type: source?.type,
    });

    if (showToast) {
      toast({
        id: "update-audience",
        title: "Audience was updated",
        variant: "success",
      });
    }
  };

  const saveName = async (name: string) => {
    await updateAudience({
      id,
      input: {
        name,
      },
    });

    onUpdate();
  };

  const saveDescription = async (description: string) => {
    if (!id) {
      return;
    }

    await updateAudience({
      id,
      input: {
        description,
      },
    });

    onUpdate();
  };

  const onDelete = async () => {
    try {
      await deleteAudience({ id });

      toast({
        id: "delete-audience",
        title: "Audience was deleted",
        variant: "success",
      });

      analytics.track("Model Deleted", {
        model_id: id,
        model_type: QueryType.Visual,
        model_name: audience?.name,
        source_id: source?.id,
        source_type: source?.type,
      });

      navigate("/audiences");
    } catch (error) {
      if (
        error.message.startsWith("Foreign key violation") &&
        error.message.includes("journey_nodes")
      ) {
        toast({
          id: "delete-audience",
          title: "Failed to delete this audience",
          message:
            "This audience cannot be deleted because it is used in a journey. First remove the audience from the journey and try again.",
          variant: "error",
        });
      } else if (
        error.message.startsWith("Foreign key violation") &&
        error.message.includes("segments")
      ) {
        toast({
          id: "delete-audience",
          title: "Failed to delete this audience",
          message:
            "This audience cannot be deleted because it is used in a priority list. First remove the audience from the priority list and try again.",
          variant: "error",
        });
      } else if (error.message.startsWith("Foreign key violation")) {
        toast({
          id: "delete-audience",
          title: `Failed to delete this audience`,
          message:
            "This audience cannot be deleted because other resources rely on it.",
          variant: "error",
        });

        Sentry.captureException(error);
      } else {
        toast({
          id: "delete-audience",
          title: "Failed to delete this audience",
          variant: "error",
        });

        Sentry.captureException(error);
      }

      setDeleteModal(false);

      throw error;
    }
  };

  const crumbs: Crumb[] = [{ label: "All audiences", link: "/audiences" }];

  if (folder?.path) {
    folder.path.split("/").forEach((path) => {
      crumbs.push({
        label: path,
        link: "/audiences?folder=" + folder.id,
      });
    });
  }

  const tabs: RouteTab[] = [
    { title: "Definition", path: "definition" },
    {
      title: "Splits",
      path: "splits",
      count: splits.length || undefined,
    },
    {
      title: "Syncs",
      path: "syncs",
      count: syncs.length || undefined,
    },
    {
      title: "Traits",
      path: "traits",
      count: traits.length || undefined,
    },
    { title: "Activity", path: "activity" },
  ].filter(isPresent);

  const { activeTab } = useTabState(tabs, 4);

  useEffect(() => {
    refetch();
  }, [activeTab?.path]);

  return (
    <>
      <PermissionProvider
        permission={{
          v2: { resource: "model", grant: "can_update", id },
          v1: { resource: "audience", grant: "update", id },
        }}
      >
        <DetailPage
          title={`${audience.name} - Audiences`}
          bg={
            activeTab?.path === "definition" ? "base.lightBackground" : "white"
          }
          contentFullWidth={activeTab?.path === "definition"}
          crumbs={crumbs}
          header={
            <Column width="100%">
              <Row
                sx={{
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <PermissionedEditableHeading
                  permission={{
                    v1: {
                      resource: "audience",
                      grant: "update",
                      id: id,
                    },
                    v2: { resource: "model", grant: "can_update", id },
                  }}
                  size="lg"
                  value={audience?.name ?? ""}
                  onChange={saveName}
                />
                <Row gap={3} sx={{ alignItems: "center", ml: 8 }}>
                  <Menu>
                    <MenuActionsButton variant="secondary" />
                    <MenuList>
                      <PermissionedMenuItem
                        permission={{
                          v1: {
                            resource: "audience",
                            grant: "update",
                            id: id,
                          },
                          v2: { resource: "model", grant: "can_update", id },
                        }}
                        icon={FolderIcon}
                        onClick={() => {
                          setIsFolderModalOpen(true);
                        }}
                      >
                        Move to folder
                      </PermissionedMenuItem>
                      <PermissionedMenuItem
                        permission={{
                          v1: {
                            resource: "audience",
                            grant: "update",
                            id: id,
                          },
                          v2: { resource: "model", grant: "can_update", id },
                        }}
                        icon={EditIcon}
                        onClick={() => {
                          setEditLabelsModal(true);
                        }}
                      >
                        Edit labels
                      </PermissionedMenuItem>
                      <PermissionedMenuItem
                        permission={{
                          v1: {
                            resource: "audience",
                            grant: "create",
                          },
                          v2: {
                            resource: "model",
                            grant: "can_create",
                            creationOptions: {
                              type: "audience",
                              parentModelId: parentModel.id?.toString() ?? "",
                            },
                          },
                        }}
                        icon={CopyIcon}
                        onClick={() => {
                          navigate(`/audiences/${id}/clone`);
                        }}
                      >
                        Clone
                      </PermissionedMenuItem>
                      <MenuDivider />
                      <PermissionedMenuItem
                        permission={{
                          v1: {
                            resource: "audience",
                            grant: "delete",
                            id: id,
                          },
                          v2: {
                            resource: "model",
                            grant: "can_delete",
                            id,
                          },
                        }}
                        isDisabled={audienceRelatedResourcesQuery.isLoading}
                        icon={DeleteIcon}
                        variant="danger"
                        onClick={() => {
                          setDeleteModal(true);
                        }}
                      >
                        Delete
                      </PermissionedMenuItem>
                    </MenuList>
                  </Menu>

                  {cannotCreateNewSyncs ? null : (
                    <AddSyncButton
                      audience={audience}
                      parentModel={parentModel}
                    />
                  )}
                </Row>
              </Row>
              <Row>
                <EditableDescription
                  isDisabled={!userCanUpdate}
                  value={audience.description ?? ""}
                  onChange={saveDescription}
                />
              </Row>
              <Row sx={{ mt: 1 }}>
                <DetailBar>
                  <Row align="center" gap={2} flexShrink={0}>
                    <IntegrationIcon
                      src={source?.definition?.icon}
                      name={source?.definition?.name ?? ""}
                    />
                    {hasViewSchemaPermissions ? (
                      <Link
                        href={
                          schemaV2
                            ? `/schema-v2/view?source=${source?.id}&id=${parentModel.id}`
                            : `/schema/parent-models/${parentModel.id}`
                        }
                      >
                        <Text isTruncated fontWeight="medium" color="inherit">
                          {parentModel.name}
                        </Text>
                      </Link>
                    ) : (
                      <Text isTruncated fontWeight="medium">
                        {parentModel.name}
                      </Text>
                    )}
                  </Row>
                  <Row align="center" gap={2} flexShrink={0}>
                    <Text>Last updated:</Text>
                    <Row gap={1} align="center">
                      {formatDate(
                        (audience.updated_at || audience.created_at)!,
                      )}
                      {updatedByUsername && (
                        <>
                          <Text>by</Text>
                          <Avatar size="xs" name={updatedByUsername} />
                        </>
                      )}
                    </Row>
                  </Row>
                  {membership && (
                    <Row align="center" gap={2}>
                      <Text>#{membership.rank + 1} in</Text>
                      <Link
                        href={`/priority-lists/${membership.priority_list.id}`}
                      >
                        <Row
                          color="primary"
                          gap={2}
                          align="center"
                          _hover={{ color: "secondary" }}
                        >
                          {membership.priority_list.name}
                          <Box as={LinkIcon} fontSize="16px" />
                        </Row>
                      </Link>
                    </Row>
                  )}
                  {labelKeys?.length > 0 && (
                    <Row>
                      <Labels labels={audience?.labels} />
                    </Row>
                  )}
                </DetailBar>
              </Row>
            </Column>
          }
          tabs={tabs}
        >
          <Outlet context={{ audience, parentModel, id, audienceFetching }} />
        </DetailPage>
      </PermissionProvider>

      <DependenciesModal
        isOpen={deleteModal && dependencies.length > 0}
        resourceType="audience"
        onClose={() => setDeleteModal(false)}
        dependencies={dependencies}
      />

      <ConfirmationDialog
        confirmButtonText="Delete"
        isOpen={deleteModal && dependencies.length === 0}
        title="Delete audience"
        variant="danger"
        onClose={() => {
          setDeleteModal(false);
        }}
        onConfirm={onDelete}
      >
        <Column gap={6}>
          <Paragraph>
            Are you sure you want to delete this audience? You won't be able to
            undo this.
          </Paragraph>
          <Paragraph>
            This audience may not be deleted until the resources that reference
            it are deleted as well.
          </Paragraph>

          {syncs.length > 0 && (
            <>
              <Paragraph>
                The following syncs will be deleted as well:
              </Paragraph>

              <Column alignItems="start">
                <ChakraUnorderedList>
                  {syncs.map(({ destination, id }) => (
                    <ChakraListItem key={id}>
                      <Link href={`/syncs/${id}`}>
                        {destination?.name ||
                          destination?.definition.name ||
                          "Unknown sync"}
                      </Link>
                    </ChakraListItem>
                  ))}
                </ChakraUnorderedList>
              </Column>
            </>
          )}
        </Column>
      </ConfirmationDialog>

      {audience && isFolderModalOpen && (
        <MoveFolder
          folder={audience.folder}
          folderType="audiences"
          modelIds={[audience.id]}
          viewType="models"
          onClose={() => setIsFolderModalOpen(false)}
        />
      )}

      <EditLabelModal
        isOpen={editLabelsModal}
        labels={audience?.labels}
        resourceType={ResourceType.Model}
        onClose={() => setEditLabelsModal(false)}
        onSubmit={(labels) =>
          updateAudience({
            id,
            input: {
              tags: labels,
            },
          })
        }
      />
    </>
  );
};
