import { FC, useState } from "react";
import * as Sentry from "@sentry/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import {
  Alert,
  ButtonGroup,
  ConfirmationDialog,
  Paragraph,
  UnlinkIcon,
  useToast,
} from "@hightouchio/ui";

import { LinkButton, useOutletContext } from "src/router";
import { OverageContentAlert } from "src/components/overage/overage-content-alert";
import { DestinationForm } from "src/components/destinations/sync-form";
import { PermissionedButton } from "src/components/permission";
import { useEntitlements } from "src/hooks/use-entitlement";
import * as analytics from "src/lib/analytics";
import { useDraft } from "src/contexts/draft-context";
import {
  DestinationInstancesSetInput,
  useExternalSegmentsQuery,
  useSyncTemplateQuery,
  useUpdateSyncMutation,
} from "src/graphql";
import { useUser } from "src/contexts/user-context";
import { useHeaderHeight } from "src/contexts/header-height-context";
import { Context } from ".";

export const Configuration: FC = () => {
  const { sync, onRefetch } = useOutletContext<Context>();
  const id = String(sync.id);
  const { workspace } = useUser();
  const { toast } = useToast();
  const { headerHeight } = useHeaderHeight();

  const [confirmSyncTemplateDetach, setConfirmSyncTemplateDetach] =
    useState(false);
  const flags = useFlags();
  const topDisplacement = headerHeight + 16;

  const updateSyncMutation = useUpdateSyncMutation();
  const updateSyncMutationAndInvalidateCatche = useUpdateSyncMutation({
    onSuccess: () => {
      onRefetch();
    },
  });

  const syncTemplateQuery = useSyncTemplateQuery(
    {
      id: sync?.sync_template_id ?? -1,
    },
    { enabled: Boolean(sync?.sync_template_id) },
  );

  const syncTemplateName = syncTemplateQuery.data?.sync_templates_by_pk?.name;

  const model = sync?.segment;
  const destination = sync?.destination;
  const source = model?.connection;
  const usesSyncTemplate = sync?.sync_template_id;

  const { updateResourceOrDraft } = useDraft();

  const { data: externalSegmentsData } = useExternalSegmentsQuery(
    { syncId: sync?.id },
    { enabled: !!sync },
  );
  // We used to support multiple external segments per sync, where only one
  // would be valid at a time. When there is more than one, we only care about
  // the most recent, active one.
  const externalSegment = externalSegmentsData?.external_segments?.sort(
    (a, b) => {
      return a.created_at > b.created_at ? -1 : 1;
    },
  )?.[0];

  const { data: entitlementsData } = useEntitlements(true);
  const overageLockout: boolean = entitlementsData.overage?.overageLockout;

  const onUpdate = () => {
    analytics.track("Sync Edited", {
      sync_id: id,
      destination_type: destination?.definition?.name,
      schedule_type: sync.schedule?.type,
      source_type: model?.connection?.type,
    });

    if (!workspace?.approvals_required) {
      toast({
        id: "update-sync",
        title: "Sync was updated",
        variant: "success",
      });
    }
  };

  const updateConfig = async (config) => {
    if (!id || !sync) {
      return;
    }

    const updatePayload = {
      config: { ...config, configVersion: sync?.config?.configVersion },
      // we null the draft id, it gets added on the backend and we want to be consistent
      // if a workspace turns off approvals again =
      approved_draft_id: null,
    };

    if (updateResourceOrDraft) {
      await updateResourceOrDraft(
        { _set: updatePayload },
        onUpdate,
        () =>
          updateSyncMutation.mutateAsync({
            id,
            object: updatePayload,
          }),
        sync.draft || false,
      );
    }
  };

  const detachSyncTemplate = async () => {
    if (!id || !sync) {
      return;
    }

    try {
      if (updateResourceOrDraft) {
        const updatePayload: DestinationInstancesSetInput = {
          destination_id: sync.destination_id,
          sync_template_id: null,
          config: sync.config,
          is_streaming: sync.is_streaming,
          schedule: sync.schedule,
          schedule_paused: sync.schedule_paused,
          row_threshold_total: sync.row_threshold_total,
          row_threshold_attempted: sync.row_threshold_attempted,
          // we null the draft id, it gets added on the backend and we want to be consistent
          // if a workspace turns off approvals again =
          approved_draft_id: null,
        };

        await updateResourceOrDraft(
          { _set: updatePayload },
          onUpdate,
          () =>
            updateSyncMutationAndInvalidateCatche.mutateAsync({
              id,
              object: updatePayload,
            }),
          sync.draft || false,
        );
      }
    } catch (error) {
      toast({
        id: "detach-sync-template",
        title: "Could not detach template",
        message: error.message,
        variant: "error",
      });
      Sentry.captureException(error);
    }
  };

  return (
    <>
      {overageLockout && <OverageContentAlert />}
      {!overageLockout &&
        !usesSyncTemplate &&
        model &&
        source?.definition &&
        destination &&
        destination?.definition && (
          <DestinationForm
            permission={{
              v2: {
                resource: "sync",
                grant: "can_update",
                id: id ?? "",
              },
            }}
            testPermission={{
              v2: {
                resource: "sync",
                grant: "can_test",
                id: id ?? "",
              },
            }}
            destination={destination}
            destinationDefinition={destination.definition}
            externalSegment={externalSegment}
            model={model}
            slug={destination?.definition?.type}
            sidebarTop={`${topDisplacement}px`}
            sourceDefinition={source.definition}
            sync={sync}
            onSubmit={updateConfig}
          />
        )}

      {!overageLockout && usesSyncTemplate && (
        <Alert
          type="info"
          variant="inline"
          title="Sync template"
          message="This sync uses a shared configuration template"
          actions={
            <ButtonGroup size="lg">
              {!workspace?.sync_templates_only && (
                <PermissionedButton
                  permission={{
                    v2: {
                      resource: "sync",
                      grant: "can_update",
                      id: sync?.id,
                    },
                  }}
                  icon={UnlinkIcon}
                  onClick={() => setConfirmSyncTemplateDetach(true)}
                >
                  Detach from template
                </PermissionedButton>
              )}
              <LinkButton
                href={
                  flags.schemaV2
                    ? `/schema-v2/settings/sync-templates/${sync?.sync_template_id}`
                    : `/schema/sync-templates/${sync?.sync_template_id}`
                }
              >
                Edit the template
              </LinkButton>
            </ButtonGroup>
          }
        />
      )}

      <ConfirmationDialog
        confirmButtonText="Detach"
        isOpen={confirmSyncTemplateDetach}
        title="Detach from template"
        variant="warning"
        onClose={() => setConfirmSyncTemplateDetach(false)}
        onConfirm={detachSyncTemplate}
      >
        <Paragraph>
          Detaching this sync from template &quot;{syncTemplateName}&quot; means
          future updates to the template will not be propagated to this sync.
          Are you sure you want to detach?
        </Paragraph>
      </ConfirmationDialog>
    </>
  );
};
