import { PreworkspacePage } from "src/components/layout/preworkspace-page";
import { AuthenticatedRoute } from "src/components/router/authenticated-route";
import { Outlet } from "src/router";
import * as Workspaces from "./workspaces";
import * as WorkspacesNew from "./new";

export const WorkspacesLayout = () => {
  return (
    <PreworkspacePage title="Workspaces">
      <Outlet />
    </PreworkspacePage>
  );
};

export const routes = {
  path: "workspaces",
  element: <WorkspacesLayout />,
  children: [
    {
      index: true,
      loader: Workspaces.loader,
      element: (
        <AuthenticatedRoute>
          <Workspaces.Page />
        </AuthenticatedRoute>
      ),
    },
    {
      path: "new",
      element: (
        <AuthenticatedRoute>
          <WorkspacesNew.Page />
        </AuthenticatedRoute>
      ),
    },
  ],
};
