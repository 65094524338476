import { useMemo } from "react";
import { useUser } from "src/contexts/user-context";
import { useOrganizationPermissionsQuery } from "src/graphql";
import { useResourcePermission } from "./use-resource-permission";

export function useOrganizationPermissions(): {
  isWorkspaceAdmin: (workspaceId: string) => boolean;
  isOrganizationAdmin: boolean;
  isAdminOfAnyWorkspace: boolean;
} {
  const { user, isLoading: isUserLoading } = useUser();

  const {
    data: { workspace_members, user_group_members, organization_members } = {},
    isLoading,
  } = useOrganizationPermissionsQuery(
    { userId: user?.id?.toString() ?? "" },
    {
      enabled: Boolean(user),
    },
  );

  // This is only used to tell whether the user is an upgraded impersonator
  const { isPermitted: isWorkspaceAdmin } = useResourcePermission({
    v2: { resource: "workspace", grant: "can_update" },
  });

  const defaultPermissions = {
    isWorkspaceAdmin: (_workspaceId: string) => false,
    isOrganizationAdmin: false,
    isAdminOfAnyWorkspace: false,
  };

  return useMemo(() => {
    if (!user || isUserLoading || isLoading) {
      return defaultPermissions;
    }

    const workspaces = workspace_members
      ?.filter((m) => m.is_workspace_admin)
      ?.map((m) => String(m.workspace_id));

    if (user.is_impersonating && !isWorkspaceAdmin) {
      return defaultPermissions;
    }

    if (user.is_impersonating) {
      return {
        isAdminOfAnyWorkspace: true,
        isWorkspaceAdmin: (_workspaceId: string) => true,
        isOrganizationAdmin: true,
      };
    }

    const isOrganizationAdmin = Boolean(
      organization_members?.some(
        (m) =>
          String(m.organization_id) === String(user?.organization?.id) &&
          m.is_organization_admin,
      ),
    );
    return {
      isAdminOfAnyWorkspace: Boolean(workspaces?.length),
      isWorkspaceAdmin: (workspaceId: string) =>
        Boolean(workspaces?.includes(String(workspaceId))),
      isOrganizationAdmin,
    };
  }, [
    workspace_members,
    user_group_members,
    organization_members,
    user,
    isUserLoading,
    isLoading,
    isWorkspaceAdmin,
  ]);
}

export type OrganizationPermissionInput =
  | {
      resource: "organization";
    }
  | {
      resource: "workspace";
      id: string;
    }
  | {
      resource: "group";
      id: string;
    };

export function useOrganizationPermission(
  input: OrganizationPermissionInput | undefined,
): boolean {
  const { isOrganizationAdmin, isWorkspaceAdmin } =
    useOrganizationPermissions();

  if (!input) return false;

  switch (input.resource) {
    case "organization":
    case "group":
      return isOrganizationAdmin;
    case "workspace":
      return Boolean(input.id) && isWorkspaceAdmin(input.id); // all organization admins are already also workspace admins
    default:
      return false;
  }
}
