import { FC, useEffect, useState } from "react";

import {
  Column,
  DeleteIcon,
  Heading,
  IconButton,
  Menu,
  MenuActionsButton,
  MenuItem,
  MenuList,
  PlusIcon,
  Row,
  SectionHeading,
  Select,
  useToast,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import { SomeJSONSchema } from "ajv/dist/types/json-schema";
import JSON5 from "json5";
import omit from "lodash/omit";
import { useNavigate, useOutletContext, useParams } from "src/router";

import { useUser } from "src/contexts/user-context";
import {
  EventSchemaEventType,
  useCreateEventSchemaV2Mutation,
  useDeleteAllEventSchemaVersionsMutation,
  useDeleteEventSchemaVersionMutation,
  useEventSchemaVersionQuery,
  useEventSchemaVersionsQuery,
  useUpdateEventSchemaV2Mutation,
} from "src/graphql";
import { TrackView } from "src/lib/analytics";

import {
  EventSchemaFormState,
  EventSchemaOutletContext,
  assertEventSchema,
} from "src/events/contracts/types";

import {
  createEventPath,
  deconstructEventPath,
  getDefaultProperty,
  validationResolver,
} from "src/events/contracts/utils";
import { DeleteVersionModal } from "./delete-version-modal";
import { NewVersionModal } from "src/events/contracts/contract/event-schema/new-version-modal";
import {
  canJsonSchemaBeConvertedToContractProperties,
  convertContractPropertiesToJsonSchema,
  convertJsonSchemaToContractProperties,
} from "src/events/contracts/contract/event-schema/transformation";
import { DetailPage } from "src/components/layout";
import { SidebarForm } from "src/components/page";
import { ActionBar } from "src/components/action-bar";
import { PageSpinner } from "src/components/loading";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import { NameField } from "./fields/name-field";
import { TypeField } from "./fields/type-field";
import { SchemaEditor } from "./fields/schema-editor";
import { formatDistanceToNowStrict, parseISO } from "date-fns";
import { DescriptionField } from "./fields/description-field";
import { EnforcementSettings } from "./fields/enforcement-settings";
import { Divider } from "./divider";

export const EventSchema: FC = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const { workspace } = useUser();
  const { event_type_and_name, event_version } = useParams();
  const { contract } = useOutletContext<EventSchemaOutletContext>();

  const [showNewVersionModal, setShowNewVersionModal] = useState(false);
  const [showDeleteVersionModal, setShowDeleteVersionModal] = useState(false);

  const { eventType, eventName } = deconstructEventPath(event_type_and_name);

  const createEventSchemaMutation = useCreateEventSchemaV2Mutation();
  const updateEventSchemaMutation = useUpdateEventSchemaV2Mutation();
  const deleteEventSchemaVersionMutation =
    useDeleteEventSchemaVersionMutation();
  const deleteAllEventSchemaVersionsMutation =
    useDeleteAllEventSchemaVersionsMutation();

  const eventSchemaVersionsQuery = useEventSchemaVersionsQuery(
    {
      event_plan_id: contract.id,
      name: eventName,
      event_type: eventType ?? "",
    },
    {
      enabled: Boolean(eventType),
      keepPreviousData: true,
      select: (data) => data.event_schemas,
    },
  );

  const eventSchemaVersionQuery = useEventSchemaVersionQuery(
    {
      event_plan_id: contract.id,
      name: eventName,
      event_type: eventType ?? "",
      event_version: event_version ?? "",
    },
    {
      enabled: Boolean(eventType && event_version),
      select: (data) => data.event_schemas?.[0] ?? null,
    },
  );

  const isLoading =
    eventSchemaVersionsQuery.isLoading || eventSchemaVersionQuery.isLoading;

  const eventUnvalidated = eventSchemaVersionQuery.data;
  const event = eventUnvalidated
    ? assertEventSchema(eventUnvalidated)
    : eventUnvalidated;

  const isDefaultVersion =
    event?.event_version === contract.default_schema_version;
  const versionOptions =
    eventSchemaVersionsQuery.data?.map((version) => ({
      ...version,
      isDefault: version.event_version === contract.default_schema_version,
    })) ?? [];

  const form = useHightouchForm<EventSchemaFormState>({
    defaultValues: {
      name: event?.event_name,
      description: event?.schema.description,
      eventType: event?.event_type,
      editorState: {
        isJson: false,
        json: event?.schema
          ? JSON.stringify(
              omit(event?.schema as SomeJSONSchema, "description"),
              null,
              4,
            )
          : "",
        properties: event?.schema
          ? (convertJsonSchemaToContractProperties(
              omit(event.schema, "description") as SomeJSONSchema,
              event.event_type,
            ) ?? [getDefaultProperty()])
          : [getDefaultProperty()],
      },
      onSchemaViolation: event?.on_schema_violation,
      onUndeclaredFields: event?.on_undeclared_fields,
    },
    resolver: validationResolver,
    onError: (error) => {
      // If no error message just let our generic form error handling take care of it
      // There isn't a specific field error message to show in this case
      if (!error.message) return;

      const isContrainstViolation = error.message.includes(
        "Uniqueness violation",
      );

      if (
        formEventType === EventSchemaEventType.Track &&
        isContrainstViolation
      ) {
        form.setError("name", {
          message: "Event name must be unique",
        });
      } else if (isContrainstViolation) {
        form.setError("eventType", {
          message: `Contract already contains a schema for event type "${formEventType}"`,
        });
      } else {
        // For other cases, assume it's a json validation error,
        // that's the only other (expected) error the backend will send
        form.setError("editorState.json", {
          message: error.message,
        });
      }
    },
    error: "Failed to update event",
    success: "Event updated",
    onSubmit: async (formValues: EventSchemaFormState) => {
      if (!event?.id) {
        return;
      }

      const isJson = formValues.editorState.isJson;

      let schema: SomeJSONSchema;
      if (isJson) {
        const json = formValues.editorState.json;
        schema = JSON5.parse(json);
      } else {
        const properties = formValues.editorState.properties;
        schema = convertContractPropertiesToJsonSchema(
          properties,
          formValues.eventType,
        );
      }

      const newEventName =
        formValues.eventType === EventSchemaEventType.Track
          ? formValues.name
          : null;

      // If name, event type or enforcement settings have changed, propagate the changes to all versions.
      // Otherwise, just apply updates the current version.
      //
      // Note: when name or event type do change, there is a slight race condition,
      // that can cause the "latest version" in the UI to unexpectedly change versions.
      // (see: UpdateEventSchema.update_event_schemas_many)
      //
      // This doesn't cause data loss, and doesn't impact the backend validation,
      // only which version we show in the UI first.
      // It's a bit annoying, but users won't rename or change types often,
      // and the proper fix would require a migration to the underlying data model to fix, which isn't worth it at this time.
      const hasUpdatesToPropagate =
        newEventName !== event.event_name ||
        formValues.eventType !== event.event_type ||
        formValues.onSchemaViolation !== event.on_schema_violation ||
        formValues.onUndeclaredFields !== event.on_undeclared_fields;

      const eventSchemaVersionIdsToUpdate = hasUpdatesToPropagate
        ? versionOptions.map((version) => version.id)
        : [];

      const { updateEventSchemaV2: result } =
        await updateEventSchemaMutation.mutateAsync({
          input: {
            id: event.id,
            workspace_id: workspace?.id.toString(),
            schema: {
              schema: { ...schema, description: formValues.description },
            },
            updatesToPropagateAcrossVersions: {
              event_name: newEventName,
              event_type: formValues.eventType,
              on_schema_violation: formValues.onSchemaViolation,
              on_undeclared_fields: formValues.onUndeclaredFields,
            },
            eventSchemaVersionIdsToUpdate,
          },
        });

      if (result.errors.length > 0 || !result.schema) {
        throw new Error(
          result.errors[0]?.message ||
            "Unexpected error creating event schema.",
        );
      }

      navigate(
        `../${createEventPath({
          eventName: result.schema.event_name,
          eventType: result.schema.event_type,
          eventVersion: result.schema.event_version,
        })}`,
      );
    },
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore Circular reference for ContractProperty type
  const formEventType = form.watch("eventType");

  useEffect(() => {
    if (event) {
      form.reset({
        name: event.event_name,
        description: event.schema.description,
        eventType: event.event_type,
        editorState: {
          isJson:
            event.schema && event.schema.properties
              ? !canJsonSchemaBeConvertedToContractProperties(
                  omit(event.schema, "description") as SomeJSONSchema,
                  event.event_type,
                ).canConvert
              : false,
          json: event?.schema
            ? JSON.stringify(
                omit(event.schema as SomeJSONSchema, "description"),
                null,
                4,
              )
            : "",
          properties: event?.schema
            ? (convertJsonSchemaToContractProperties(
                omit(event.schema, "description") as SomeJSONSchema,
                event.event_type,
              ) ?? [getDefaultProperty()])
            : [getDefaultProperty()],
        },
        onSchemaViolation: event.on_schema_violation,
        onUndeclaredFields: event.on_undeclared_fields,
      });
    }
  }, [event]);

  if (isLoading) {
    return <PageSpinner />;
  }

  const createNewEventSchemaVersion = async (name: string) => {
    if (!event) {
      Sentry.captureException(
        new Error("No event to create a new version of."),
      );

      toast({
        id: "create-event-schema-version",
        title: "Internal error",
        message: "Please try again later.",
        variant: "error",
      });

      return;
    }

    try {
      const { createEventSchemaV2: result } =
        await createEventSchemaMutation.mutateAsync({
          input: {
            event_name: event.event_name,
            event_plan_id: event.event_plan_id,
            event_type: event.event_type,
            event_version: name,
            schema: event.schema,
            on_schema_violation: event.on_schema_violation,
            on_undeclared_fields: event.on_undeclared_fields,
          },
        });

      toast({
        id: "create-event-schema-version",
        title: "Event was updated with a new version",
        variant: "success",
      });

      setShowNewVersionModal(false);

      if (result.schema?.event_version) {
        navigate(
          `../${createEventPath({
            eventName: event?.event_name,
            eventType: event.event_type,
            eventVersion: result.schema?.event_version,
          })}`,
        );
      } else {
        navigate("../");
      }
    } catch (error) {
      Sentry.captureException(error);
      toast({
        id: "create-event-schema-version",
        title: "Failed to create a new event version",
        message: error.message,
        variant: "error",
      });
    }
  };

  const deleteSchemaVersion = async (deleteAllVersions = false) => {
    if (
      !deleteAllVersions &&
      versionOptions.length > 1 &&
      event?.event_version === contract.default_schema_version
    ) {
      toast({
        id: "delete-event-schema-version",
        title: "Cannot delete default version",
        message: "The default version cannot be deleted ",
        variant: "error",
      });

      return;
    }

    try {
      if (!deleteAllVersions) {
        // delete one version
        await deleteEventSchemaVersionMutation.mutateAsync({
          name: event?.event_name,
          event_plan_id: event?.event_plan_id ?? "",
          event_type: event?.event_type ?? "",
          event_version: event?.event_version ?? "",
        });
      } else {
        // delete all versions
        await deleteAllEventSchemaVersionsMutation.mutateAsync({
          name: event?.event_name,
          event_plan_id: event?.event_plan_id ?? "",
          event_type: event?.event_type ?? "",
        });
      }

      toast({
        id: "delete-event-schema-version",
        title: deleteAllVersions ? "Event deleted" : "Event version deleted",
        variant: "success",
      });

      navigate("..");
    } catch (error) {
      Sentry.captureException(error);
      toast({
        id: "delete-event-schema-version",
        title: "Failed to delete event version",
        message: error.message,
        variant: "error",
      });
    }
  };

  return (
    <DetailPage
      title={`Edit event | ${contract.name}`}
      crumbs={[
        { label: "All contracts", link: "/events/contracts" },
        { label: contract.name, link: `/events/contracts/${contract.id}` },
      ]}
      header={
        <Row align="center" justify="space-between">
          <Heading size="lg" mt={1}>
            Edit event contract
          </Heading>
          <Menu>
            <MenuActionsButton variant="secondary" />
            <MenuList>
              <MenuItem
                variant="danger"
                icon={DeleteIcon}
                isDisabled={createEventSchemaMutation.isLoading}
                onClick={() => setShowDeleteVersionModal(true)}
              >
                Delete
              </MenuItem>
            </MenuList>
          </Menu>
        </Row>
      }
    >
      <Row>
        <Column minWidth={0} width="100%">
          <Form form={form}>
            <TrackView name="Edit Event Page" />
            <Column gap={4} pb={24} flexGrow={1}>
              <TypeField />
              {formEventType === EventSchemaEventType.Track && <NameField />}
              <Divider />
              <Column gap={2}>
                <Row alignItems="center" gap={2}>
                  <SectionHeading>Schema version:</SectionHeading>
                  <Select
                    isDisabled={createEventSchemaMutation.isLoading}
                    optionLabel={(option) => option.event_version}
                    optionValue={(option) => option.event_version}
                    optionDescription={(option) =>
                      `Updated ${formatDistanceToNowStrict(
                        parseISO(option.updated_at),
                        { addSuffix: true },
                      )}`
                    }
                    options={versionOptions}
                    placeholder="Event version"
                    value={event_version ?? ""}
                    width="auto"
                    onChange={(version) => {
                      if (version && eventType) {
                        navigate(
                          `../${createEventPath({
                            eventName,
                            eventType,
                            eventVersion: version,
                          })}`,
                        );
                      }
                    }}
                  />
                  <IconButton
                    aria-label="New version"
                    icon={PlusIcon}
                    variant="secondary"
                    isLoading={createEventSchemaMutation.isLoading}
                    onClick={() => setShowNewVersionModal(true)}
                  />
                </Row>

                <DescriptionField />
              </Column>

              <SchemaEditor />

              <Divider />
              <EnforcementSettings />

              <ActionBar>
                <FormActions />
              </ActionBar>
            </Column>

            <NewVersionModal
              error={createEventSchemaMutation.error?.message}
              invalidNames={versionOptions.map(
                (version) => version.event_version,
              )}
              isOpen={showNewVersionModal}
              onClose={() => setShowNewVersionModal(false)}
              onSubmit={createNewEventSchemaVersion}
            />

            <DeleteVersionModal
              isDefaultVersion={isDefaultVersion}
              isOpen={showDeleteVersionModal}
              versionName={event?.event_version ?? ""}
              onClose={() => setShowDeleteVersionModal(false)}
              onSubmit={deleteSchemaVersion}
            />
          </Form>
        </Column>
        <SidebarForm
          docsUrl="events/contracts/management"
          name="Event contracts"
        />
      </Row>
    </DetailPage>
  );
};
