import { FC, useEffect, useMemo, useRef, useState } from "react";

import {
  Alert,
  Button,
  Column,
  Paragraph,
  Row,
  SectionHeading,
  Text,
} from "@hightouchio/ui";
import { isEmpty } from "lodash";
import { useFormContext } from "react-hook-form";
import { isPresent } from "ts-extras";

import { AnalyticsGraph } from "src/components/analytics/cross-audience-graph/analytics-graph";
import { transformMetricDataForGraph } from "src/components/analytics/cross-audience-graph/utils";
import { AnalyticsContent } from "src/pages/analytics/analytics-content";
import { BreakdownTable } from "src/pages/analytics/breakdown-table";

import { LoadingCircles } from "src/components/loading";
import { useScrollPageToBottom } from "src/hooks/use-scroll-page-to-bottom";
import { useMetricSeries } from "src/pages/analytics/hooks/use-metric-series";
import {
  AnalyticsCopilotProvider,
  useAnalyticsContext,
} from "src/pages/analytics/state";
import { GraphType } from "src/pages/analytics/types";

import { CopilotModal } from "./copilot-modal";
import { ChartDefinition } from "./types";
import { AnalyticsFrequency } from "src/types/visual";

type CopilotChartSectionProps = {
  id: string;
  chartDefinitions: ChartDefinition[];
};

export const CopilotChartSection: FC<CopilotChartSectionProps> = ({
  id,
  chartDefinitions,
}) => {
  return (
    <Column gap={4}>
      {chartDefinitions.map((chartDefinition, index) => (
        <AnalyticsCopilotProvider
          key={`${id}-${index}`}
          chartDefinition={chartDefinition}
        >
          <CopilotChartCard chartDefinition={chartDefinition} />
        </AnalyticsCopilotProvider>
      ))}
    </Column>
  );
};

type CopilotChartCard = {
  isLoading?: boolean;
  chartDefinition: ChartDefinition;
};

const CopilotChartCard: FC<CopilotChartCard> = ({ chartDefinition }) => {
  const { audiences, events, metrics, parent } = useAnalyticsContext();
  const form = useFormContext();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hoveredLine, setHoveredLine] = useState<string | undefined>();

  const { chartVariables, parentModelId } = chartDefinition;

  const selectedDates = form
    .watch("selectedDates")
    .map((date) => new Date(date));
  const groupByColumns = form.watch("groupByColumns").filter(isPresent);
  const graphType = form.watch("graphType");
  const selectedAudiences = form.watch("selectedAudiences");
  const rollupFrequency = form.watch("rollupFrequency");
  const metricSelection = form.watch("metricSelection");
  const timeValue = form.watch("timeValue");
  const cumulative = form.watch("cumulative");

  const {
    data,
    isPolling,
    pollingError,
    errors: metricSeriesErrors,
  } = useMetricSeries({
    enabled: graphType !== GraphType.Funnel,
    audiences: selectedAudiences ?? [],
    customDateRange: selectedDates,
    frequency:
      graphType !== GraphType.Performance
        ? AnalyticsFrequency.All
        : rollupFrequency,
    groupByColumns,
    metricSelection: metricSelection,
    metrics: metrics,
    parentModelId: parentModelId,
    timeValue: timeValue,
  });

  const graph = useMemo(() => {
    return transformMetricDataForGraph({
      audiences: selectedAudiences ?? [],
      cumulative: cumulative,
      events: events,
      groupByColumns,
      numberOfSelectedMetrics:
        chartVariables?.metricIds?.length +
        chartVariables?.metricDefinitions?.length,
      metricResults: data,
      metrics,
      parent,
      transformForPerformance: graphType === GraphType.Performance,
    });
  }, [audiences, cumulative, events, data, graphType, metrics, parent]);

  const hasLoadedOnce = useRef(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const showCopilotAnimationLoadingState = isPolling && isFirstLoad;

  useScrollPageToBottom([showCopilotAnimationLoadingState]);

  // After first load, hide copilot loading animation
  useEffect(() => {
    if (!isPolling && hasLoadedOnce.current) {
      setIsFirstLoad(false);
    } else if (isPolling) {
      hasLoadedOnce.current = true;
    }
  }, [isPolling]);

  return (
    <Column
      borderRadius="md"
      border="1px solid"
      borderColor="base.divider"
      boxShadow="sm"
    >
      {showCopilotAnimationLoadingState ? (
        <Column align="center" justify="center" height="100%" width="100%">
          <LoadingCircles />

          <Row align="center" justify="center" mb={10}>
            <Text color="text.secondary" fontWeight="medium">
              Generating chart...
            </Text>
          </Row>
        </Column>
      ) : (
        <Column minHeight="400px">
          <Row justify="space-between" px={6} pt={6}>
            <SectionHeading>{chartDefinition.chartName}</SectionHeading>
            <Button size="sm" onClick={() => setIsModalOpen(true)}>
              View chart
            </Button>
          </Row>

          {!isEmpty(metricSeriesErrors) && (
            <Column px={6} pt={6} pb={2}>
              <Alert
                variant="inline"
                type="error"
                title="There were some errors"
                message="Click the 'view chart' button to learn more"
              />
            </Column>
          )}

          {chartVariables.frequency !== "all" ? (
            <AnalyticsGraph
              data={graph.series}
              hoveredLine={hoveredLine}
              onHoverLine={setHoveredLine}
            />
          ) : (
            <Column p={6} pt={4} pb={0}>
              <BreakdownTable
                data={graph.series}
                groupByColumns={groupByColumns}
              />
            </Column>
          )}

          <CopilotModal
            chartName={chartDefinition.chartName}
            metrics={metrics}
            isOpen={isModalOpen}
            shouldConfirmClose={form.formState.isDirty}
            onClose={() => setIsModalOpen(false)}
          >
            <AnalyticsContent
              ignoreHeaderHeight
              isSavedView={false}
              isLoading={isPolling}
              // TODO(samuel): add funnels data once it is enabled in copilot
              funnelsData={[]}
              funnelsErrors={{}}
              metricSeriesErrors={metricSeriesErrors}
              graph={graph}
              errorMessage={
                pollingError ? (
                  <Paragraph
                    color={pollingError ? "text.danger" : "text.primary"}
                  >
                    {pollingError
                      ? pollingError.message
                      : "Please select at least one series to view the graph"}
                  </Paragraph>
                ) : null
              }
            />
          </CopilotModal>
        </Column>
      )}
    </Column>
  );
};
