import {
  MonitorConditionTypes,
  MonitorStatus,
} from "@hightouch/lib/resource-monitoring/types";
import { subDays, subHours, subMinutes } from "date-fns";
import { SplashPage } from "src/components/splash-page";
import { SyncHealthAlerts } from "src/pages/syncs/sync/components/sync-health-alerts";
import { AlertingIcon } from "src/ui/icons";
import { SyncHealthStatus } from "src/utils/syncs";
import bottomRightGradient from "src/assets/backgrounds/bottom-right-green-gradient.svg";
import topLeftGradient from "src/assets/backgrounds/top-left-green-gradient.svg";
import { Box, Row, ArrowRightIcon } from "@hightouchio/ui";
import { Link } from "src/router";
import { useSearchParam } from "src/components/routeable-tabs/utils";
import { useUser } from "src/contexts/user-context";
import { useUpdateWorkspaceMutation } from "src/graphql";
import { PermissionedButton } from "src/components/permission";
import { MigrateV1AlertsToV2 } from "./migrate-to-v2";
import { PageSpinner } from "src/components/loading";

export const AdvancedAlertingSplashPage = () => {
  const [isMigrating, setMigrating] = useSearchParam("migrating");

  const { mutateAsync: enableAlertingMutation } = useUpdateWorkspaceMutation();
  const { workspace, isLoading } = useUser();

  // This is kind of weird, but since we don't load anything explicitly on this page,
  // but we do potentially mutate the workspace, we need to wait for the user context to load,
  // otherwise the page seems pretty unresponsive.
  if (isLoading) {
    return <PageSpinner />;
  }

  return (
    <>
      <SplashPage
        hideLogos
        icon={<AlertingIcon />}
        eyebrow="Advanced Alerting"
        heading="New alerting features are available for early access"
        description="Detect, triage, and resolve issues with your syncs before they impact customers and internal teams. With advanced alerting, you can receive actionable alerts based on custom monitors for sync throughput, row-level errors, and other metrics. Reduce alert fatigue and set custom routing rules to loop in the relevant teams when things break. Now in beta."
        visual={
          <SyncHealthAlerts
            health={SyncHealthStatus.Unhealthy}
            healthChangedAt=""
            statuses={[
              {
                conditionType: MonitorConditionTypes.SyncRejectedRows,
                id: "throughput",
                eventTime: subMinutes(new Date(), 15).toISOString(),
                text: "More than 10% of rows were rejected during a sync run",
                status: MonitorStatus.Unhealthy,
              },
              {
                conditionType: MonitorConditionTypes.SyncDuration,
                id: "slow",
                eventTime: subHours(new Date(), 8).toISOString(),
                text: "Sync has been running for more than 2 hours",
                status: MonitorStatus.Warning,
              },
              {
                conditionType: MonitorConditionTypes.SyncSequentialFailures,
                id: "rejected",
                eventTime: subDays(new Date(), 2).toISOString(),
                text: "3 or more consecutive sync runs experienced fatal errors",
                status: MonitorStatus.Unhealthy,
              },
            ]}
          />
        }
        backgroundGradient={
          <>
            <Box
              as="img"
              position="absolute"
              top={0}
              left={0}
              src={topLeftGradient}
            />
            <Box
              as="img"
              position="absolute"
              bottom={0}
              right={0}
              src={bottomRightGradient}
            />
          </>
        }
        actions={
          <Row alignItems="baseline" gap={4}>
            <PermissionedButton
              unauthorizedTooltip="You must be an admin to enable beta features"
              permission={{
                v2: { resource: "workspace", grant: "can_update" },
              }}
              variant="primary"
              onClick={async () => {
                await enableAlertingMutation({
                  id: workspace?.id,
                  input: {
                    alerting_v2_enabled: true,
                  },
                });
                setMigrating("true");
              }}
            >
              Enable beta features
            </PermissionedButton>

            <Link href="/extensions/alerting/configuration">
              Continue to alerting settings <ArrowRightIcon />
            </Link>
          </Row>
        }
      />
      {isMigrating && <MigrateV1AlertsToV2 />}
    </>
  );
};
