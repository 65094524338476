import { Column } from "@hightouchio/ui";
import { captureException } from "@sentry/react";

import { DestinationForm } from "src/components/destinations/sync-form";
import { Warning } from "src/components/warning";
import { useOutletContext } from "src/router";

import { SyncTemplateOutletContext } from "./types";

export const SyncTemplateConfiguration = () => {
  const { syncTemplate, onUpdateConfig } =
    useOutletContext<SyncTemplateOutletContext>();

  const destination = syncTemplate.destination;
  const source = syncTemplate.segment.connection;

  if (!source) {
    captureException(`Source not found for sync template ${syncTemplate.id}`);
    return (
      <Warning title="Source not found" subtitle="It may have been deleted." />
    );
  }

  return (
    <Column pb={10}>
      <DestinationForm
        allowOverrides
        testPermission={{
          v1: { resource: "sync_template", grant: "update" },
          v2: {
            resource: "model",
            grant: "can_update",
            id: syncTemplate.segment.id,
          },
        }}
        permission={{
          v1: { resource: "sync_template", grant: "update" },
          v2: {
            resource: "model",
            grant: "can_update",
            id: syncTemplate.segment.id,
          },
        }}
        destination={destination}
        destinationDefinition={destination?.definition}
        model={syncTemplate.segment}
        slug={destination?.definition?.type}
        sourceDefinition={source.definition}
        syncConfig={syncTemplate.config}
        overrideConfig={syncTemplate.override_config}
        onSubmit={async (config) => {
          onUpdateConfig(config);
        }}
      />
    </Column>
  );
};
