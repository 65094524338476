import { FC } from "react";

import {
  ColumnType,
  InlineAggregatedTrait,
  PropertyCondition,
  isAggregationTraitConfig,
  isCountDedupedTraitConfig,
  isOrderDedupedTraitConfig,
} from "@hightouch/lib/query/visual/types";
import { Column, Combobox, Row, Text } from "@hightouchio/ui";
import immutableUpdate from "immutability-helper";

import { RequiredParentModelFieldsForQueryBuilder } from "src/components/audiences/types";
import {
  EventColumn,
  RelationColumn,
} from "src/components/explore/filter-popover/constants";
import { IconBox } from "src/components/icon-box";
import { INLINE_TRAIT_TYPE_OPTIONS } from "src/components/traits/utils";
import {
  Audience,
  DefaultOperators,
  FilterableColumn,
  InlineAggregatedTraitCondition,
} from "src/types/visual";

import { ConditionTextWrapper } from "./condition-text-wrapper";
import { ErrorMessage } from "./error-message";
import { PropertyInputProps } from "./property-input";
import { PropertyOperatorValueFilter } from "./property-operator-value-filter";
import { TraitFilter } from "./trait-filter";
import { getCustomTraitPropertyType } from "./utils";

const ColumnWidth = "150px";

type Props = {
  audience: Audience | undefined;
  condition: InlineAggregatedTraitCondition;
  operatorError: string | null | undefined;
  parent: RequiredParentModelFieldsForQueryBuilder;
  propertyError: string | null | undefined;
  loadingSuggestions: boolean;
  suggestions: PropertyInputProps["suggestions"];
  valueError: string | null | undefined;
  onChange: (updates: Partial<PropertyCondition>) => void;
};

export const InlineTrait: FC<Readonly<Props>> = ({
  audience,
  condition,
  operatorError,
  parent,
  propertyError,
  loadingSuggestions,
  suggestions,
  valueError,
  onChange,
}) => {
  const relationships = parent.relationships;

  const relationshipOptions = parent.relationships.map((relationship) => {
    const modelType = relationship.to_model.event
      ? EventColumn
      : RelationColumn;
    const Icon = () => (
      <IconBox
        bg={modelType.color}
        boxSize={4}
        icon={modelType.icon}
        iconSize={3}
      />
    );

    return {
      label: relationship.to_model.name || relationship.name,
      value: relationship.id,
      icon: Icon,
    };
  });

  const relationshipId = condition.property?.column?.relationshipId;
  const relationship = relationships.find(({ id }) => id === relationshipId);
  const relatedModel = relationship?.to_model;

  const filterableColumnReferences =
    relationship?.to_model.filterable_audience_columns ?? [];

  const filterableColumns = filterableColumnReferences.map(
    (column: FilterableColumn) => ({
      label: column.alias ?? column.name,
      value: column.column_reference,
    }),
  );

  const column = condition.property.column;

  const updateRelationshipId = (relationshipId: string) =>
    onChange(
      immutableUpdate(condition, {
        property: {
          column: {
            relationshipId: {
              $set: relationshipId,
            },
            traitConfig: {
              $set: {},
            },
          },
          path: {
            $set: [relationshipId],
          },
        },
      }),
    );

  const updateTraitType = (traitType: InlineAggregatedTrait["traitType"]) => {
    const propertyType = getCustomTraitPropertyType({
      type: traitType,
      selectedColumn: undefined,
      filterableColumns: filterableColumnReferences,
    });

    onChange(
      immutableUpdate(condition, {
        property: {
          column: {
            traitType: {
              $set: traitType,
            },
            traitConfig: {
              $set: {},
            },
          },
        },
        propertyType: {
          $set: propertyType,
        },
        operator: {
          $set: DefaultOperators[propertyType],
        },
      }),
    );
  };

  const updateTraitConfig = (
    config: Partial<InlineAggregatedTrait["traitConfig"]>,
  ) => {
    const traitType = condition.property.column.traitType;
    const selectedColumn = isAggregationTraitConfig(traitType, config)
      ? config.column
      : isCountDedupedTraitConfig(traitType, config) ||
          isOrderDedupedTraitConfig(traitType, config)
        ? config.toSelect
        : undefined;

    // Changing the orderBy column should not influence the propertyType of the condition.
    // Just re-use the exiting propertyType in that case.
    const propertyType =
      isOrderDedupedTraitConfig(traitType, config) && config.orderBy
        ? (condition.propertyType ?? ColumnType.Unknown)
        : getCustomTraitPropertyType({
            type: traitType,
            selectedColumn,
            filterableColumns: filterableColumnReferences,
          });

    onChange(
      immutableUpdate(condition, {
        property: {
          column: {
            traitConfig: {
              $merge: config,
            },
          },
        },
        propertyType: {
          $set: propertyType,
        },
        operator: {
          $set: DefaultOperators[propertyType],
        },
      }),
    );
  };

  if (!relationshipId) {
    return (
      <Column mt={2}>
        <Combobox
          isInvalid={Boolean(
            propertyError && !condition?.property?.column?.relationshipId,
          )}
          options={relationshipOptions}
          optionAccessory={(option: any) => ({
            type: "icon",
            icon: option.icon,
          })}
          placeholder="Select a model..."
          width="2xs"
          value={condition?.property?.column?.relationshipId}
          onChange={(value) => {
            if (value) {
              updateRelationshipId(value);
            }
          }}
        />

        {propertyError && <ErrorMessage>{propertyError}</ErrorMessage>}
      </Column>
    );
  }

  return (
    <Column mt={2} gap={2}>
      <Column>
        <Row gap={2} alignItems="flex-start">
          <Column width={ColumnWidth}>
            <Combobox
              isInvalid={Boolean(
                propertyError && !condition?.property?.column.traitType,
              )}
              options={INLINE_TRAIT_TYPE_OPTIONS}
              placeholder="Select a trait type"
              value={condition?.property?.column.traitType}
              onChange={(value) => {
                if (value) {
                  updateTraitType(value);
                }
              }}
            />
            {propertyError && !condition?.property?.column.traitType && (
              <ErrorMessage>{propertyError}</ErrorMessage>
            )}
          </Column>

          {/* Average, Count, Sum */}
          {isAggregationTraitConfig(column.traitType, column.traitConfig) && (
            <Column width={ColumnWidth}>
              <Combobox
                isInvalid={Boolean(propertyError && !column.traitConfig.column)}
                options={filterableColumns}
                placeholder="Select a column"
                value={column.traitConfig.column}
                popoverWidth="xs"
                onChange={(value) => {
                  if (value) {
                    updateTraitConfig({ column: value });
                  }
                }}
              />
              {propertyError && !column.traitConfig.column && (
                <ErrorMessage>{propertyError}</ErrorMessage>
              )}
            </Column>
          )}

          {/* Least Frequent, Most Frequent */}
          {isCountDedupedTraitConfig(column.traitType, column.traitConfig) && (
            <Column width={ColumnWidth}>
              <Combobox
                isInvalid={Boolean(
                  propertyError && !column.traitConfig.toSelect,
                )}
                options={filterableColumns}
                placeholder="Select a column"
                popoverWidth="xs"
                value={column.traitConfig.toSelect}
                onChange={(value) => {
                  if (value) {
                    updateTraitConfig({ toSelect: value });
                  }
                }}
              />
              {propertyError && !column.traitConfig.toSelect && (
                <ErrorMessage>{propertyError}</ErrorMessage>
              )}
            </Column>
          )}

          {/* First, Last */}
          {isOrderDedupedTraitConfig(column.traitType, column.traitConfig) && (
            <Row gap={2}>
              <Column width={ColumnWidth}>
                <Combobox
                  isInvalid={Boolean(
                    propertyError && !column.traitConfig.toSelect,
                  )}
                  options={filterableColumns}
                  placeholder="Select a column"
                  popoverWidth="xs"
                  value={column.traitConfig.toSelect}
                  onChange={(value) => {
                    if (value) {
                      updateTraitConfig({ toSelect: value });
                    }
                  }}
                />
                {propertyError && !column.traitConfig.toSelect && (
                  <ErrorMessage>{propertyError}</ErrorMessage>
                )}
              </Column>

              <ConditionTextWrapper>
                <Text color="text.secondary">ordered by</Text>
              </ConditionTextWrapper>

              <Column width={ColumnWidth}>
                <Combobox
                  isInvalid={Boolean(
                    propertyError && !column.traitConfig.orderBy,
                  )}
                  options={filterableColumns}
                  placeholder="Select a column"
                  popoverWidth="xs"
                  value={column.traitConfig.orderBy}
                  onChange={(value) => {
                    if (value) {
                      updateTraitConfig({ orderBy: value });
                    }
                  }}
                />
                {propertyError && !column.traitConfig.orderBy && (
                  <ErrorMessage>{propertyError}</ErrorMessage>
                )}
              </Column>
            </Row>
          )}

          <ConditionTextWrapper>
            <Text color="text.secondary">
              of {relatedModel?.event ? "events" : "rows"} in
            </Text>
          </ConditionTextWrapper>

          <Column>
            <Combobox
              isInvalid={Boolean(
                propertyError && !condition?.property?.column?.relationshipId,
              )}
              options={relationshipOptions}
              optionAccessory={(option: any) => ({
                type: "icon",
                icon: option.icon,
              })}
              placeholder="Select a model"
              width="3xs"
              value={condition?.property?.column?.relationshipId}
              onChange={(value) => {
                if (value) {
                  updateRelationshipId(value);
                }
              }}
            />
            {propertyError && !condition?.property?.column?.relationshipId && (
              <ErrorMessage>{propertyError}</ErrorMessage>
            )}
          </Column>

          <PropertyOperatorValueFilter
            condition={condition}
            loadingSuggestions={loadingSuggestions}
            operatorError={operatorError}
            parent={parent}
            suggestions={suggestions}
            valueError={valueError}
            onChange={onChange}
          />
        </Row>
      </Column>

      <Row mt={2} pl={10}>
        <TraitFilter
          audience={audience}
          condition={condition}
          parent={parent}
          onChange={onChange}
        />
      </Row>
    </Column>
  );
};
