import {
  ColumnReference,
  exhaustiveCheck,
  isMergedColumn,
  isRelatedColumn,
} from "src/types/visual";

/**
 * Get the text representation of a column reference in the sql editor.
 */
export const getAppliedText = ({
  columnReference,
  alias,
  relationshipName,
}: {
  alias?: string | null;
  columnReference: ColumnReference;
  relationshipName?: string | null;
}): string => {
  if (!isRelatedColumn(columnReference) && alias) {
    return alias;
  }

  // Merged columns get special treatment
  if (isMergedColumn(columnReference)) {
    return `merged.${relationshipName}.${getAppliedText({
      columnReference: columnReference.column,
      alias,
    })}`;
  }

  switch (columnReference.type) {
    case "raw":
      return columnReference.name;
    case "related":
      return getAppliedText({
        columnReference: columnReference.column,
      });
    // Trait templating looks like `"trait.<trait name>"`.
    // The quotes are necessary.
    case "transformed":
      return `trait.${getAppliedText({
        columnReference: columnReference.column,
      })}`;
    case "trait":
      return `trait.${columnReference.name}`;
    default:
      // @ts-expect-error - Inline and event traits are not supported
      return exhaustiveCheck(columnReference.type);
  }
};
