import { FC, useEffect, useState } from "react";

import {
  ArrowRightIcon,
  Box,
  Button,
  ButtonGroup,
  CloseIcon,
  Column,
  Combobox,
  ErrorIcon,
  IconButton,
  Row,
  TextInput,
  Tooltip,
  WandIcon,
} from "@hightouchio/ui";
import get from "lodash/get";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { usePermissionContext } from "src/components/permission/permission-context";
import { isColumnReference } from "src/types/visual";
import { flattenOptions } from "src/ui/select";
import { suggest, automap } from "src/utils/automap";
import { NEW_ICON_MAP } from "src/utils/destinations";
import { ArrayInlineMapper } from "./array-inline-mapper";
import {
  ColumnOption,
  useFormkitContext,
} from "src/formkit/components/formkit-context";
import { SyncType } from "src/formkit/components/sync-type";
import {
  ArrayMapping,
  InlineMapperProps,
  isArrayMapping,
  Mapping,
  MappingType,
  ObjectMapping,
  Option,
  SelectedOptionContext,
} from "src/formkit/components/types";
import { resolveFromValue } from "src/formkit/components/utils";
import { Mapper } from "./mapper";

/**
 * Populates the nestedMappings state on load with fields that were mapped previously and required fields that are not mapped.
 */
const generateMappings = (
  value: ArrayMapping | ObjectMapping,
  properties: SelectedOptionContext[] | undefined,
): Mapping[] => {
  const requiredOptions: Mapping[] = [];
  const children = isArrayMapping(value) ? value?.children : value?.from;
  let mappings: Mapping[] = [];
  if (Array.isArray(properties)) {
    for (const option of properties) {
      if (option.required) {
        requiredOptions.push({
          to: option.value,
          type: MappingType.STANDARD,
        });
      }
    }
  }
  if (Array.isArray(children)) {
    const nestedMappings = new Set(children.map(({ to }) => to));
    mappings = [
      ...children,
      ...requiredOptions.filter(({ to }) => !nestedMappings.has(to)),
    ];
  } else {
    mappings = requiredOptions;
  }

  // If there is nothing mapped previously and no required fields, initiate the mappings with one standard field for clarity.
  if (mappings.length === 0) {
    mappings.push({
      type: MappingType.STANDARD,
    });
  }
  return mappings;
};

export const InlineMapper: FC<Readonly<InlineMapperProps>> = ({
  currentDepth,
  enabledNestedInlineMapper,
  errorPrefix,
  jsonColumnProperties,
  onChange,
  onChangeJsonColumnProperties,
  onReloadEligibleInlineMapperColumns,
  overwriteColumnsWithArrayProps,
  parentMapping,
  required,
  retrieveErrorMessage,
  selectedOptionContext,
  templates,
  type,
  value,
}) => {
  const [nestedMappings, setNestedMappings] = useState<Mapping[]>([]);
  const [isSuggested, setIsSuggested] = useState(false);

  const { errors, sourceDefinition } = useDestinationForm();

  const jsonColumnPropsToOptions = (): ColumnOption[] | undefined => {
    if (!jsonColumnProperties.selectedColumnProps) {
      return undefined;
    }

    return Array.from(jsonColumnProperties.selectedColumnProps).map((prop) => {
      return {
        label: prop,
        value: prop,
        type: "array_property",
      };
    });
  };

  const {
    columns: contextColumns,
    destinationDefinition,
    model,
  } = useFormkitContext();
  const columns = overwriteColumnsWithArrayProps
    ? jsonColumnPropsToOptions()
    : contextColumns;
  const permission = usePermissionContext();

  useEffect(() => {
    setNestedMappings(generateMappings(value, selectedOptionContext));
  }, [value.to]);

  useEffect(() => {
    onChange(nestedMappings);
  }, [nestedMappings]);

  const isOptionExcluded = (option: Option) => {
    const valueAlreadyMapped = nestedMappings.some(
      (mapping) =>
        mapping?.to ===
        (isColumnReference(option.value) ? option.label : option.value),
    );
    return valueAlreadyMapped;
  };

  const suggestColumns = () => {
    const flatColumns = flattenOptions(columns || []);
    const unmappedOptions = (
      !selectedOptionContext?.length
        ? (flatColumns as any[])
        : selectedOptionContext
    ).filter((option) => !isOptionExcluded(option));
    setIsSuggested(true);
    return automap(columns || [], unmappedOptions as any[]);
  };

  const append = (newMapping: Mapping[]) => {
    setNestedMappings((currentMappings) => [...currentMappings, ...newMapping]);
  };

  const change = (index: number, newMapping: Mapping) => {
    setNestedMappings((currentMappings) => {
      const mappingsCopy = [...currentMappings];
      mappingsCopy[index] = newMapping;
      return mappingsCopy;
    });
  };

  const remove = (index: number) => {
    setNestedMappings((currentMappings) => {
      const mappingsCopy = [...currentMappings];
      mappingsCopy.splice(index, 1);
      return mappingsCopy;
    });
  };
  return (
    <Column
      gap={2}
      bg="#F8FAFC"
      p={4}
      width="100%"
      borderColor="#8EA8A9"
      borderLeftStyle="solid"
      borderLeftWidth="2px"
    >
      {nestedMappings.map((mapping, index) => {
        const { to, type } = mapping;
        const selectedOption = selectedOptionContext?.find(
          (option) => option.value == to,
        );
        const isMappingRequired = Boolean(selectedOption?.required);
        const destinationFieldOptions = selectedOptionContext?.map((option) => {
          if (isOptionExcluded(option)) {
            return { ...option, disabled: true };
          } else {
            return option;
          }
        });
        // If mapping destination is configured, but it's not in the list of
        // available options, it's either a custom value typed by the user or
        // it comes from a suggested mapping
        if (typeof to === "string" && !selectedOption) {
          destinationFieldOptions?.push({
            label: to,
            value: to,
            disabled: false,
          });
        }

        const errorMessage = retrieveErrorMessage(errors, index, errorPrefix);

        return (
          <Box
            alignItems="center"
            display="grid"
            gap={2}
            gridTemplateColumns="minmax(0, 1fr) 32px minmax(0, 1fr) 24px 24px"
            key={to}
          >
            <Mapper
              columnOptions={columns}
              enableInLineMapper={enabledNestedInlineMapper}
              isDisabled={permission.unauthorized}
              isError={!!errors?.[`${errorPrefix}[${index}].from`]}
              jsonColumnProperties={jsonColumnProperties}
              overwriteColumnsWithArrayProps={overwriteColumnsWithArrayProps}
              parentMapping={parentMapping ? parentMapping : value}
              placeholder={`Select a field from ${sourceDefinition?.name}`}
              selectedOption={selectedOption}
              templates={templates ?? []}
              value={mapping}
              onReloadEligibleInlineMapperColumns={
                onReloadEligibleInlineMapperColumns
              }
              onChange={(option) => {
                const availableOptions = destinationFieldOptions?.filter(
                  (o) => !o.disabled,
                );
                if (
                  !to &&
                  availableOptions?.length &&
                  option?.type === MappingType.STANDARD
                ) {
                  const fieldName = resolveFromValue(value.from);
                  change(
                    index,
                    suggest(
                      { label: fieldName, value: option.from },
                      availableOptions,
                    ) as Mapping,
                  );
                } else {
                  change(index, {
                    ...option,
                    to,
                  });
                }
              }}
              onChangeJsonColumnProperties={onChangeJsonColumnProperties}
            />
            <Column gap={2} fontSize="xl" color="text.secondary">
              <ArrowRightIcon />
            </Column>
            {selectedOptionContext ? (
              <Column flex={1} gap={2}>
                <Combobox
                  supportsCreatableOptions
                  createOptionMessage={(inputValue) =>
                    `Create field "${inputValue}"`
                  }
                  emptyOptionsMessage="Type in a field name and Hightouch will create that field in the destination"
                  isDisabled={permission.unauthorized || isMappingRequired}
                  isInvalid={!!errors?.[`${errorPrefix}[${index}].to`]}
                  isOptionDisabled={(option) => {
                    // For some reason when option is selected, it becomes disabled
                    // and has `disabled = true` property, so we need to take this into account
                    // and make sure selected option can't be disabled
                    const isSelectedOption = option.value === to;
                    if (isSelectedOption) {
                      return false;
                    }

                    return Boolean(option.disabled);
                  }}
                  optionAccessory={(option) => {
                    return option.type
                      ? {
                          type: "icon",
                          icon: NEW_ICON_MAP[option.type],
                        }
                      : undefined;
                  }}
                  optionLabel={(option) => {
                    return option.object?.label
                      ? `${option.label} (${option.object.label})`
                      : option.label;
                  }}
                  options={destinationFieldOptions || []}
                  placeholder={
                    to
                      ? ""
                      : `Select or enter a field from ${destinationDefinition?.name}`
                  }
                  value={to}
                  width="sm"
                  onChange={(value) => {
                    const option = destinationFieldOptions?.find(
                      (option) => option.value === value,
                    );
                    if (
                      option?.type === "OBJECT" &&
                      enabledNestedInlineMapper
                    ) {
                      change(index, {
                        from: undefined,
                        to: option?.value ?? "",
                        type: MappingType.OBJECT,
                      });
                    } else if (
                      option?.type === "array" &&
                      enabledNestedInlineMapper
                    ) {
                      change(index, {
                        from: undefined,
                        to: option?.value ?? "",
                        type: MappingType.ARRAY,
                        children: [],
                      });
                    } else {
                      change(index, {
                        ...mapping,
                        to: option?.value ?? "",
                      });
                    }
                  }}
                  onCreateOption={(inputValue) => {
                    change(index, {
                      ...mapping,
                      to: inputValue ?? "",
                    });
                  }}
                />
              </Column>
            ) : (
              <InputField
                destinationName={destinationDefinition?.name || ""}
                disabled={permission.unauthorized}
                error={!!errors?.[`${errorPrefix}[${index}].to`]}
                value={to ?? ""}
                onBlur={(value) => {
                  change(index, {
                    ...mapping,
                    to: value,
                  });
                }}
              />
            )}
            <SyncType
              isDisabled={
                permission.unauthorized ||
                ["array", "object", "reference"].includes(type)
              }
              setValue={(val) => change(index, val)}
              value={mapping}
            />
            {required && nestedMappings.length <= 1 ? (
              // needs to exist to satisfy grid
              <Row minHeight="32px" width="32px" />
            ) : isMappingRequired ? (
              <Row
                alignItems="center"
                height="20px"
                justifyContent="center"
                width="32px"
              >
                <Tooltip message="Required">
                  <Box color="danger.500" fontSize={18}>
                    *
                  </Box>
                </Tooltip>
              </Row>
            ) : (
              <IconButton
                aria-label="Remove mapping"
                icon={CloseIcon}
                isDisabled={permission.unauthorized}
                onClick={() => {
                  remove(index);
                }}
              />
            )}
            {errorMessage && (
              <Tooltip message={errorMessage}>
                <Row fontSize="24px">
                  <Box as={ErrorIcon} color="danger.base" />
                </Row>
              </Tooltip>
            )}
            {type === "object" && enabledNestedInlineMapper && (
              <Box display="grid" gridColumn="1 / 5" gridRow="2 / 3">
                <InlineMapper
                  currentDepth={currentDepth + 1}
                  enabledNestedInlineMapper={currentDepth < 2}
                  errorPrefix={`${errorPrefix}[${index}].from`}
                  jsonColumnProperties={jsonColumnProperties}
                  outerContainerSx={{ marginTop: "0px" }}
                  overwriteColumnsWithArrayProps={
                    overwriteColumnsWithArrayProps
                  }
                  retrieveErrorMessage={retrieveErrorMessage}
                  parentMapping={parentMapping ? parentMapping : value}
                  selectedOptionContext={selectedOption?.["properties"]}
                  templates={templates ?? []}
                  type="object"
                  value={mapping}
                  onReloadEligibleInlineMapperColumns={
                    onReloadEligibleInlineMapperColumns
                  }
                  onChange={(value) => {
                    change(index, {
                      ...mapping,
                      from: value,
                      type: MappingType.OBJECT,
                    });
                  }}
                  onChangeJsonColumnProperties={onChangeJsonColumnProperties}
                />
              </Box>
            )}
            {type === "array" && enabledNestedInlineMapper && (
              <Box display="grid" gridColumn="1 / 5" gridRow="2 / 3">
                <ArrayInlineMapper
                  currentDepth={currentDepth + 1}
                  enabledNestedInlineMapper={currentDepth < 2}
                  errorPrefix={`${errorPrefix}[${index}].from`}
                  jsonColumnProperties={jsonColumnProperties}
                  outerContainerSx={{ marginTop: "0px" }}
                  retrieveErrorMessage={retrieveErrorMessage}
                  parentMapping={parentMapping ? parentMapping : value}
                  selectedOptionContext={selectedOption?.["properties"]}
                  templates={templates ?? []}
                  value={mapping}
                  onChange={(value) => {
                    change(index, {
                      ...mapping,
                      children: value,
                      type: MappingType.ARRAY,
                    });
                  }}
                  onChangeJsonColumnProperties={onChangeJsonColumnProperties}
                  onReloadEligibleInlineMapperColumns={
                    onReloadEligibleInlineMapperColumns
                  }
                />
              </Box>
            )}
          </Box>
        );
      })}
      <ButtonGroup>
        <Button
          isDisabled={permission.unauthorized}
          onClick={() => {
            append([{ type: MappingType.STANDARD }]);
          }}
        >
          {type === "array" ? "Add array mapping" : "Add object mapping"}
        </Button>
        {(model || (columns?.length ?? 0) > 0) && (
          <Tooltip
            isDisabled={permission.unauthorized}
            message={
              isSuggested
                ? "All possible suggestions applied"
                : "Suggest mappings"
            }
          >
            <IconButton
              aria-label="Suggest mappings"
              icon={WandIcon}
              isDisabled={permission.unauthorized}
              variant="secondary"
              onClick={() => {
                append(suggestColumns() as Mapping[]);
              }}
            />
          </Tooltip>
        )}
      </ButtonGroup>
    </Column>
  );
};

const isTraitEnrichment = (value: unknown): boolean => {
  return get(value, "type") === "additionalColumnReference";
};

export const isTraitlike = (value: unknown): boolean => {
  return isTraitEnrichment(value) || get(value, "column.type") === "trait";
};

const InputField: FC<
  Readonly<{
    destinationName: string;
    disabled: boolean;
    error: boolean;
    onBlur: (value: string) => void;
    value: string;
  }>
> = ({ destinationName, disabled, error, onBlur, value }) => {
  const [inputValue, setInputValue] = useState(value || "");

  /**
   * We use onBlur to update the nested mapping state. This is because if we use onChange in the `Input` component,
   * it will cause re-rendering and loss of focus on the input field whenever there is a change.
   * Since we are changing the nested mapping state onBlur, we cannot use the nested mapping's `to` as a value since it is not updated
   * whenever a user types.
   * This requires every `Input` component to have its own state that it references and we pass that state back once onBlur is called.
   */
  return (
    <TextInput
      isDisabled={disabled}
      isInvalid={error}
      placeholder={`Enter a field from ${destinationName}`}
      value={inputValue}
      width="100%"
      onBlur={() => onBlur(inputValue)}
      onChange={(event) => setInputValue(event.target.value)}
    />
  );
};
