import { FC } from "react";
import { ExtendedOption } from "../../../../../formkit";
import { FormProps, JsonColumnProps, MappingType } from "../types";
import { ArrayInput } from "./array-input";
import { ObjectInput } from "./object-input";
import { StandardInput } from "./standard-input";
import { StaticInput } from "./static-input";
import { TemplateInput } from "./template-input";
import { VariableInput } from "./variable-input";
import { FieldInput } from "./field-input";

export const INPUTS: Record<
  MappingType,
  FC<
    FormProps & {
      enableInLineMapper?: boolean;
      jsonColumnProperties: JsonColumnProps;
      onChangeJsonColumnProperties: React.Dispatch<
        React.SetStateAction<JsonColumnProps>
      >;
      onClose: () => void;
      onReloadEligibleInlineMapperColumns: (
        currentSelectedColumn?: string,
      ) => void;
      ref: React.Ref<HTMLInputElement>;
      selectedOption: ExtendedOption | undefined;
    }
  >
> = {
  [MappingType.BOOSTED]: StandardInput,
  [MappingType.STANDARD]: StandardInput,
  [MappingType.FIELD]: FieldInput,
  [MappingType.ARRAY_FIELD]: FieldInput,
  [MappingType.STATIC]: StaticInput,
  [MappingType.VARIABLE]: VariableInput,
  [MappingType.TEMPLATE]: TemplateInput,
  [MappingType.OBJECT]: ObjectInput,
  [MappingType.ARRAY]: ArrayInput,
};
