import { useState } from "react";

import { Button, ButtonGroup, Column, Row, useToast } from "@hightouchio/ui";
import { captureException } from "@sentry/react";

import { ActionBar } from "src/components/action-bar";
import { eventWarehouseDestinationDefinitions } from "src/events/types";
import { useUpdateEventWarehouseDestinationMutation } from "src/graphql";

import { EventDestinationForm } from "src/events/destinations/setup";
import { WarehouseDestination } from "src/events/destinations/destination/types";
import { SidebarForm } from "src/components/page";

export const WarehouseConfiguration = ({
  destination,
}: {
  destination: WarehouseDestination;
}) => {
  const { toast } = useToast();

  const definition = eventWarehouseDestinationDefinitions[destination.type];
  const [config, setConfig] = useState<Record<string, unknown> | undefined>(
    destination.config,
  );
  const [tunnelId, setTunnelId] = useState<string | null | undefined>(
    destination.tunnel_id ? String(destination.tunnel_id) : undefined,
  );
  const [credentialId, setCredentialId] = useState<string>(
    destination.credential_id,
  );

  const updateMutation = useUpdateEventWarehouseDestinationMutation();

  const submit = async () => {
    const updatedConfig = { ...config };

    if (tunnelId) {
      updatedConfig.host = null;
      updatedConfig.server = null;
      updatedConfig.port = null;
    }

    try {
      await updateMutation.mutateAsync({
        id: destination.id,
        input: {
          tunnel_id: tunnelId ? tunnelId : null,
          credential_id:
            credentialId != null ? String(credentialId) : undefined,
          config: updatedConfig,
        },
      });
      toast({
        id: "save-destination",
        title: "Your destination has been saved.",
        variant: "success",
      });
    } catch (error) {
      captureException(error);
      toast({
        id: "save-destination",
        title: "There was a problem saving your destination.",
        variant: "error",
      });
    }
  };

  return (
    <Row>
      <Column flex={1} gap={8} pb={16}>
        <EventDestinationForm
          config={config}
          credentialId={credentialId}
          definition={definition}
          disableAuthMethod={true}
          isSetup={false}
          setConfig={setConfig}
          setCredentialId={setCredentialId}
          setTunnelId={setTunnelId}
          tunnelId={tunnelId}
          onConnectClick={() => {}}
        />
      </Column>
      <ActionBar>
        <ButtonGroup>
          <Button
            size="lg"
            variant="primary"
            onClick={submit}
            isLoading={updateMutation.isLoading}
          >
            Save changes
          </Button>
        </ButtonGroup>
      </ActionBar>
      <SidebarForm
        docsUrl={definition.docs ?? ""}
        name={definition.name ?? ""}
      />
    </Row>
  );
};
