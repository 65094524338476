import { FC, Fragment, useEffect, useMemo } from "react";

import {
  ArrowRightIcon,
  Badge,
  Box,
  Button,
  CloseIcon,
  Column,
  Combobox,
  ErrorIcon,
  GroupedCombobox,
  IconButton,
  RefreshIcon,
  Row,
  Text,
  Tooltip,
  TraitIcon,
} from "@hightouchio/ui";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { isPresent } from "ts-extras";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { usePermissionContext } from "src/components/permission/permission-context";
import { FieldError } from "src/components/field-error";
import { NEW_ICON_MAP } from "src/utils/destinations";

import {
  ExtendedOption,
  ExtendedTypes,
  isExtendedTypes,
  toExtendedOption,
} from "../../../../../formkit";
import { useQuery } from "../../formkit";
import { useFormkitContext } from "../formkit-context";
import { GetIsValidProps } from "./mappings";
import { MappingsHeader } from "./mappings-header";

export type AssociationOption = {
  label: string;
  value: string;
  extendedType?: ExtendedTypes;
  // Note: association field and object type could be different
  // Ex: label: contact_to_company - objectType: company
  objectType?: string; // Associated object type
  objectLabel?: string; // Associated object type label/name
};

type Props = {
  name: string;
  options?: AssociationOption[];
  loading?: boolean;
  error?: string;
  excludeMappings?: string[];
  reload?: () => void;
  ascOptions?: any;
};

/**
 * Example of an associationMapping
  {
    to: "company",
    type: "reference",
    lookup: {
      by: "name",
      from: "company_obj",
      byType: "STRING",
      object: "company",
    },
  }

  to: the association field you're linking (most of the time, it's the associated
    object, but could be named differently)
  lookup: find the company object by name with the model's `company_name` field
*/

const clearMappingError = (
  errors: any,
  index: number,
  setErrors: (error: Error) => void,
): void => {
  const updatedErrors = { ...errors };
  const errorKeys = [
    `associationMappings[${index}].lookup.by`,
    `associationMappings[${index}].lookup.from`,
    `associationMappings[${index}].lookup.object`,
    `associationMappings[${index}].to`,
  ];

  errorKeys.forEach((key) => {
    if (updatedErrors[key]) {
      delete updatedErrors[key];
    }
  });

  setErrors(updatedErrors);
};

const retrieveErrorMessage = (
  errors: any,
  index: number,
  name: string,
): string => {
  const byAssociationError = errors?.[`${name}[${index}].lookup.by`];
  const fromAssociationError = errors?.[`${name}[${index}].lookup.from`];
  if (byAssociationError || fromAssociationError) {
    for (const direction of ["by", "from"]) {
      const mappingPath = `${name}[${index}].lookup.${direction}`;
      const errorMessage = errors?.[mappingPath];
      if (typeof errorMessage === "string") {
        return errorMessage.replace(mappingPath, "This");
      }
    }
  }
  return "";
};

export const AssociationMappings: FC<Readonly<Props>> = ({
  name,
  options,
  loading,
  reload,
  error,
  excludeMappings = [],
  ascOptions,
}) => {
  const { watch, setValue } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    name,
  });
  const { errors, setErrors } = useDestinationForm();
  const {
    columns,
    destination,
    destinationDefinition,
    model,
    sourceDefinition,
  } = useFormkitContext();
  const watchFieldArray = watch(name);

  const controlledFields =
    fields?.map((field, index) => {
      return {
        ...field,
        ...watchFieldArray[index],
      };
    }) || [];

  useEffect(() => {
    const subscription = watch((state, { name: key }) => {
      const currentMappings = state[name];

      // handles when externalIdMapping overlaps with current component
      if (currentMappings && key && excludeMappings.includes(key)) {
        const value = state[key];
        let otherMappings: any[] = [];
        if (Array.isArray(value)) {
          otherMappings = value;
        } else if (typeof value === "object") {
          otherMappings = [value];
        }

        const fieldsWithoutExcluded = currentMappings.filter(
          (currentMapping) =>
            !otherMappings.some(
              (otherMapping) =>
                currentMapping.to === otherMapping.to &&
                isPresent(currentMapping.to) &&
                isPresent(otherMapping.to),
            ),
        );
        setValue(name, fieldsWithoutExcluded);
      }
    });

    return () => subscription.unsubscribe();
  }, [excludeMappings, setValue, watch]);

  useEffect(() => {
    if (!controlledFields.length) {
      append({ type: "reference" });
    }
  }, []);

  const excludedFields: any[] = [];

  for (const key of excludeMappings) {
    const watchMapping = watch(key);
    if (Array.isArray(watchMapping)) {
      excludedFields.push(...watchMapping);
    } else if (typeof watchMapping === "object") {
      excludedFields.push(watchMapping);
    }
  }

  // handles existing mappings
  const isOptionExcluded = (option) => {
    const valueAlreadyMapped = controlledFields.some(
      ({ to }) => to === option.value,
    );
    const usedInOtherMappings = excludedFields.some(
      ({ to }) => to === option.value,
    );

    return valueAlreadyMapped || usedInOtherMappings;
  };

  const getIsValid = ({
    errors,
    index,
    mappingDirection,
    mappingName,
  }: GetIsValidProps): boolean => {
    const error = errors?.[`${mappingName}[${index}].${mappingDirection}`];
    return Boolean(error);
  };

  const permission = usePermissionContext();

  // Items in `columns` have `options` field as optional,
  // but `GroupedCombobox` expects it to be required
  const columnOptionGroups = useMemo(() => {
    return (columns ?? []).map((group) => ({
      ...group,
      options: group.options ?? [],
    }));
  }, [columns]);

  if (!watchFieldArray) {
    return null;
  }

  return (
    <Column aria-label={`${name} association mappings`} gap={3}>
      {controlledFields?.length > 0 && (
        <Box
          alignItems="center"
          display="grid"
          gap={4}
          gridTemplateColumns="minmax(0, 1fr) 32px minmax(0, 1fr) 24px 24px"
        >
          <MappingsHeader loading={loading} reload={reload} />
        </Box>
      )}
      <Box
        alignItems="center"
        display="grid"
        gap={4}
        gridTemplateColumns="minmax(0, 1fr) 32px minmax(0, 1fr) 24px 24px"
      >
        {controlledFields.map(({ id }, index) => (
          <Fragment key={id}>
            <Controller
              name={`${name}.${index}`}
              render={(data) => {
                const destinationFieldOptions = options?.map((option) => {
                  if (isOptionExcluded(option)) {
                    return { ...option, disabled: true };
                  } else {
                    return option;
                  }
                });

                const association = data?.field?.value;
                const asyncAscOptions =
                  !Array.isArray(ascOptions) &&
                  ascOptions !== null &&
                  ascOptions !== undefined;
                const queryVariables = ascOptions?.variables?.input?.variables;
                const {
                  data: associatedObjectFields,
                  error: queryError,
                  isFetching,
                  refetch,
                } = useQuery<any, Error>(
                  JSON.stringify({
                    name,
                    variables: {
                      ...ascOptions?.variables,
                      input: {
                        ...ascOptions?.variables?.input,
                        variables: {
                          object: association?.lookup?.object,
                          ...queryVariables,
                        },
                      },
                    },
                  }),
                  {
                    enabled: Boolean(asyncAscOptions && association?.to),
                    fetchProps: {
                      destinationId: destination?.id,
                      modelId: model?.id,
                      query: ascOptions?.query,
                      variables: {
                        input: {
                          ...ascOptions?.variables?.input,
                          variables: {
                            object: association?.lookup?.object,
                            ...queryVariables,
                          },
                        },
                      },
                    },
                  },
                );

                const ascFields: ExtendedOption[] =
                  toExtendedOption(associatedObjectFields) ?? [];

                return (
                  <>
                    {association?.to ? (
                      <Column gap={3}>
                        <Box
                          alignItems="center"
                          display="grid"
                          gap={2}
                          gridTemplateColumns="56px max-content 1fr"
                        >
                          <Text>Find</Text>

                          <Badge>
                            {association?.lookup?.objectLabel ||
                              association?.lookup?.object}
                          </Badge>

                          <Text>ID</Text>
                        </Box>

                        <Box
                          alignItems="center"
                          display="grid"
                          gap={2}
                          gridTemplateColumns="56px auto minmax(0, 1fr) 24px"
                        >
                          <Text>Where</Text>

                          {destinationDefinition?.icon && (
                            <Box
                              as="img"
                              src={destinationDefinition.icon}
                              width="4"
                            />
                          )}

                          <Combobox
                            isDisabled={permission.unauthorized}
                            isInvalid={getIsValid({
                              errors,
                              index,
                              mappingDirection: "lookup.by",
                              mappingName: name,
                            })}
                            optionAccessory={(option) => {
                              const type = option?.extendedType?.type;

                              return type && type in NEW_ICON_MAP
                                ? {
                                    type: "icon",
                                    icon: NEW_ICON_MAP[type],
                                  }
                                : undefined;
                            }}
                            optionLabel={(option) => {
                              return option.objectLabel
                                ? `${option.label} (${option.objectLabel})`
                                : option.label;
                            }}
                            options={ascFields}
                            placeholder={
                              association?.lookup?.by ? "" : "Select a field"
                            }
                            value={association?.lookup?.by}
                            onChange={(value) => {
                              const option = ascFields?.find((option) => {
                                return option.value === value;
                              });

                              data.field.onChange({
                                ...data.field.value,
                                lookup: {
                                  ...association?.lookup,
                                  by: option?.value || undefined,
                                  byType: option?.extendedType?.type,
                                },
                              });
                            }}
                          />
                          <IconButton
                            aria-label="Refresh"
                            icon={RefreshIcon}
                            isLoading={isFetching}
                            size="sm"
                            variant="secondary"
                            onClick={() => {
                              void refetch();
                            }}
                          />
                        </Box>

                        <Box
                          alignItems="center"
                          display="grid"
                          gap={2}
                          gridTemplateColumns="56px max-content"
                        >
                          <Text>Of</Text>

                          <Badge>
                            {association?.lookup?.objectLabel ||
                              association?.lookup?.object}
                          </Badge>
                        </Box>

                        <Box
                          alignItems="center"
                          display="grid"
                          gap={2}
                          gridTemplateColumns="56px auto minmax(0, 1fr)"
                        >
                          <Text>Equals</Text>

                          {sourceDefinition?.icon && (
                            <Box
                              as="img"
                              src={sourceDefinition.icon}
                              width="4"
                            />
                          )}

                          <GroupedCombobox
                            isDisabled={permission.unauthorized}
                            isInvalid={getIsValid({
                              errors,
                              index,
                              mappingDirection: "lookup.from",
                              mappingName: name,
                            })}
                            optionAccessory={(option) => {
                              return typeof option.value === "object" &&
                                option.value?.type ===
                                  "additionalColumnReference"
                                ? {
                                    type: "icon",
                                    icon: TraitIcon,
                                  }
                                : undefined;
                            }}
                            optionGroups={columnOptionGroups}
                            placeholder={
                              association?.lookup?.from ? "" : "Select a column"
                            }
                            value={association?.lookup?.from}
                            onChange={(value) => {
                              data.field.onChange({
                                ...data.field.value,
                                lookup: { ...association?.lookup, from: value },
                              });
                            }}
                          />
                        </Box>

                        {queryError && <FieldError error={queryError} />}
                      </Column>
                    ) : (
                      <GroupedCombobox
                        isDisabled={permission.unauthorized}
                        isInvalid={getIsValid({
                          errors,
                          index,
                          mappingDirection: "lookup.from",
                          mappingName: name,
                        })}
                        optionGroups={columnOptionGroups}
                        placeholder={`Select a field from ${sourceDefinition?.name}`}
                        value={association?.lookup?.from}
                        width="100%"
                        onChange={(value) => {
                          data.field.onChange({
                            ...data.field.value,
                            lookup: { ...association?.lookup, from: value },
                          });
                        }}
                      />
                    )}

                    <Column gap={2} fontSize="xl" color="gray.600">
                      <ArrowRightIcon />
                    </Column>

                    <Combobox
                      isDisabled={permission.unauthorized}
                      isInvalid={getIsValid({
                        errors,
                        index,
                        mappingDirection: "to",
                        mappingName: name,
                      })}
                      isOptionDisabled={(option) => (option as any).disabled}
                      optionAccessory={(option) => {
                        const type = isExtendedTypes(option.extendedType)
                          ? option.extendedType.type
                          : undefined;

                        return type && type in NEW_ICON_MAP
                          ? {
                              type: "icon",
                              icon: NEW_ICON_MAP[type],
                            }
                          : undefined;
                      }}
                      optionLabel={(option) => {
                        return option.objectLabel
                          ? `${option.label} (${option.objectLabel})`
                          : option.label;
                      }}
                      options={destinationFieldOptions ?? []}
                      placeholder={
                        data.field.value.to
                          ? ""
                          : `Select a field from ${destinationDefinition?.name}`
                      }
                      value={data.field.value.to}
                      width="100%"
                      onChange={(value) => {
                        const option = destinationFieldOptions?.find(
                          (option) => {
                            return option.value === value;
                          },
                        );

                        data.field.onChange({
                          ...data.field.value,
                          to: option?.value,
                          lookup: {
                            object: option?.objectType || option?.value,
                            objectLabel:
                              option?.objectLabel ||
                              option?.objectType ||
                              option?.value,
                            from: association?.lookup?.from,
                          },
                        });
                      }}
                    />

                    <IconButton
                      aria-label="Remove mapping"
                      icon={CloseIcon}
                      onClick={() => {
                        remove(index);
                        clearMappingError(errors, index, setErrors);
                      }}
                    />
                    <Box boxSize={6}>
                      {(retrieveErrorMessage(errors, index, name) || error) && (
                        <Tooltip
                          message={
                            retrieveErrorMessage(errors, index, name) ||
                            error ||
                            ""
                          }
                        >
                          <Row fontSize="24px">
                            <Box as={ErrorIcon} color="danger.600" />
                          </Row>
                        </Tooltip>
                      )}
                    </Box>
                  </>
                );
              }}
            />
          </Fragment>
        ))}
      </Box>

      <Row>
        <Button
          onClick={() => {
            append({ type: "reference" });
          }}
        >
          Add mapping
        </Button>
      </Row>
    </Column>
  );
};
