import { FC, useMemo } from "react";

import {
  Badge,
  Box,
  Column,
  Combobox,
  GroupedCombobox,
  IconButton,
  RefreshIcon,
  Text,
  TraitIcon,
} from "@hightouchio/ui";
import { isPresent } from "ts-extras";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { usePermissionContext } from "src/components/permission/permission-context";
import { NEW_ICON_MAP } from "src/utils/destinations";

import {
  ExtendedOption,
  FormkitGraphQLReference,
} from "../../../../../formkit";
import { ExtendedAssociationOption } from "../../../../../formkit/src/api/components/associationOption";
import { toExtendedOption } from "../../../../../formkit/src/api/components/option";
import { useQuery } from "../../formkit";
import { useFormkitContext } from "../formkit-context";

type Lookup = {
  by: string | undefined;
  byType: string | undefined;
  from: string | undefined;
  object: string;
};

type Value = {
  lookup: Lookup;
  to: string;
  type: "reference";
};

export type AssociationOptions =
  | ExtendedAssociationOption
  | FormkitGraphQLReference;

type Props = {
  name: string;
  // Static association options or handler reference to fetch props base on asc object
  associationOptions?: AssociationOptions;
  associationObjectLabels?: Record<string, unknown>;
  index: number;
  onChange?: (value: Value) => void;
  value: Value;
};

export const AssociationMapper: FC<Readonly<Props>> = ({
  associationOptions,
  index,
  name,
  onChange,
  value,
}) => {
  const {
    columns,
    destination,
    destinationDefinition,
    model,
    sourceDefinition,
  } = useFormkitContext();
  const { errors } = useDestinationForm();
  const asyncAscOptions =
    associationOptions?.["query"] &&
    typeof associationOptions?.["query"] === "string" &&
    isPresent(associationOptions);

  const variables = {
    ...associationOptions?.variables?.["input"]?.variables,
    ...{ object: value?.lookup?.object ?? value?.to },
  };

  const useAsyncOptions = Boolean(
    asyncAscOptions && (value?.lookup?.object ?? value?.to),
  );

  const {
    data: associatedObjectFields,
    isFetching,
    refetch,
  } = useQuery(
    JSON.stringify({
      name,
      variables: {
        ...associationOptions?.variables,
        input: { ...associationOptions?.variables?.["input"], variables },
      },
    }),
    {
      enabled: useAsyncOptions,
      fetchProps: {
        destinationId: destination?.id,
        modelId: model?.id,
        query: associationOptions?.["query"],
        variables: {
          ...associationOptions?.variables,
          input: { ...associationOptions?.variables?.["input"], variables },
        },
      },
    },
  );

  const ascFields: ExtendedOption[] = toExtendedOption(
    asyncAscOptions
      ? associatedObjectFields
      : associationOptions?.[value?.lookup?.object],
  );

  const permission = usePermissionContext();

  // Items in `columns` have `options` field as optional,
  // but `GroupedCombobox` expects it to be required
  const columnOptionGroups = useMemo(() => {
    return (columns ?? []).map((group) => ({
      ...group,
      options: group.options ?? [],
    }));
  }, [columns]);

  return (
    <Box aria-label={`${name} association mapper`}>
      <Column gap={2}>
        <Box
          alignItems="center"
          display="grid"
          gap={2}
          gridTemplateColumns="56px max-content"
        >
          <Text>Find</Text>
          <Badge>{value?.lookup?.object ?? value?.to}</Badge>
        </Box>

        <Box
          alignItems="center"
          display="grid"
          gap={2}
          gridTemplateColumns="56px 16px minmax(0, 1fr) 24px"
        >
          <Text>where</Text>

          {destinationDefinition?.icon && (
            <Box as="img" src={destinationDefinition.icon} width="4" />
          )}

          <Combobox
            isDisabled={permission.unauthorized}
            isInvalid={Boolean(errors?.[`${name}[${index}].lookup.by`])}
            optionAccessory={(option) => {
              const type = option?.extendedType?.type;

              return type
                ? {
                    type: "icon",
                    icon: NEW_ICON_MAP[type],
                  }
                : undefined;
            }}
            optionLabel={(option) => {
              return option.object?.label
                ? `${option.label} (${option.object.label})`
                : option.label;
            }}
            options={ascFields ?? []}
            placeholder={value?.lookup?.by ? "" : "Select a field"}
            value={value?.lookup?.by}
            width="100%"
            onChange={(newValue) => {
              const option = ascFields.find(
                (option) => option.value === newValue,
              );

              onChange?.({
                ...value,
                lookup: {
                  ...value?.lookup,
                  by: newValue,
                  byType: option?.extendedType?.type,
                },
              });
            }}
          />

          {useAsyncOptions && (
            <IconButton
              aria-label="Refresh"
              icon={RefreshIcon}
              isLoading={isFetching}
              size="sm"
              variant="secondary"
              onClick={() => {
                void refetch();
              }}
            />
          )}
        </Box>

        <Box
          alignItems="center"
          display="grid"
          gap={2}
          gridTemplateColumns="56px 16px minmax(0, 1fr)"
        >
          <Text>equals</Text>

          {sourceDefinition?.icon && (
            <Box as="img" src={sourceDefinition.icon} width="4" />
          )}

          <GroupedCombobox
            isDisabled={permission.unauthorized}
            isInvalid={Boolean(errors?.[`${name}[${index}].lookup.from`])}
            optionAccessory={(option) => {
              return typeof option?.value === "object" &&
                option?.value?.type === "additionalColumnReference"
                ? {
                    type: "icon",
                    icon: TraitIcon,
                  }
                : undefined;
            }}
            optionGroups={columnOptionGroups}
            placeholder={value?.lookup?.from ? "" : "Select a column"}
            value={value?.lookup?.from}
            width="100%"
            onChange={(newValue) => {
              onChange?.({
                ...value,
                lookup: {
                  ...value?.lookup,
                  from: newValue,
                },
              });
            }}
          />
        </Box>
      </Column>
    </Box>
  );
};
