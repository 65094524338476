import { FC } from "react";

import { CopyIcon, IconButton, Row, useToast } from "@hightouchio/ui";

import { useClipboard } from "use-clipboard-copy";
import { TextWithTooltip, TextWithTooltipProps } from "./text-with-tooltip";

export const ClickToCopy: FC<
  Readonly<TextWithTooltipProps & { value: string }>
> = ({ children, value }) => {
  const { toast } = useToast();
  const clipboard = useClipboard({
    onSuccess: () => {
      toast({
        variant: "success",
        id: "copy-to-clipboard",
        title: "Copied to clipboard",
      });
    },
  });

  return (
    <Row
      display="flex"
      gap={2}
      alignItems="center"
      overflow="hidden"
      cursor="pointer"
      onClick={() => clipboard.copy(value)}
    >
      <TextWithTooltip message="message">{children}</TextWithTooltip>

      <IconButton
        icon={CopyIcon}
        onClick={() => clipboard.copy(value)}
        aria-label="Copy text to clipboard"
      />
    </Row>
  );
};
