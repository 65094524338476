import { FC, useState } from "react";

import {
  Column,
  Row,
  EditableHeading,
  MenuActionsButton,
  MenuList,
  MenuItem,
  Menu,
  DeleteIcon,
  EditableDescription,
  Avatar,
  Text,
  useToast,
  DestinationIcon,
  Button,
} from "@hightouchio/ui";
import { LinkButton } from "src/router";
import * as Sentry from "@sentry/react";
import { Outlet, useParams, useNavigate, useOutletContext } from "src/router";

import contractEventsPlaceholder from "src/assets/placeholders/event-contracts.svg";
import { DetailPage } from "src/components/layout";
import { DeleteConfirmationModal } from "src/components/modals/delete-confirmation-modal";
import { Warning } from "src/components/warning";
import { useUser } from "src/contexts/user-context";
import {
  EventPlansSetInput,
  EventSchemaEventType,
  EventSchemaOnSchemaViolation,
  EventSchemaOnUndeclaredFields,
  useDeleteEventPlanMutation,
  useEventPlanQuery,
  useUpdateEventPlanMutation,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { PageSpinner } from "src/components/loading";
import { Table, TableColumn } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { formatDate } from "src/utils/time";

import { Contract } from "src/events/contracts/types";
import { createEventPath } from "src/events/contracts/utils";
import { EnforcementSettingsModal } from "./enforcement-settings-modal";
import { UpdateEventSourcesModal } from "./update-event-sources-modal";
import { capitalize, isEmpty } from "lodash";
import { EventTypeIcon } from "./event-schema/icons";

export const ContractLoader: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { workspace } = useUser();

  const eventPlanQuery = useEventPlanQuery(
    { id: id ?? "", workspace_id: workspace?.id },
    {
      enabled: Boolean(id),
      select: (data) => data.event_plans_by_pk,
    },
  );

  const contract = eventPlanQuery.data;

  if (eventPlanQuery.isLoading) {
    return <PageSpinner />;
  }

  if (!id) {
    return null;
  }

  if (!contract) {
    return (
      <Warning subtitle="It may have been deleted" title="Contract not found" />
    );
  }

  return <Outlet context={{ contract }} />;
};

export const ContractDetails: FC = () => {
  const { workspace } = useUser();
  const { toast } = useToast();
  const navigate = useNavigate();
  const { contract } = useOutletContext<{ contract: Contract }>();

  const updateEventPlanMutation = useUpdateEventPlanMutation();
  const deleteEventPlanMutation = useDeleteEventPlanMutation();

  const [isDeleting, setIsDeleting] = useState(false);
  const [showEnforcementSettings, setShowEnforcementSettings] = useState(false);
  const [showSources, setShowSources] = useState(false);

  const updatedByUser =
    contract.updated_by_user?.name ?? contract.created_by_user?.name;
  const events = contract.event_schemas ?? [];

  const updateEventPlan = async (input: EventPlansSetInput) => {
    try {
      await updateEventPlanMutation.mutateAsync({
        id: contract.id,
        workspace_id: workspace?.id,
        object: input,
      });

      analytics.track("Event Plan Updated", {
        contract_id: contract.id,
        contract_name: contract.name,
      });

      toast({
        id: "update-event-plan",
        title: "Contract updated",
        variant: "success",
      });
    } catch (error) {
      Sentry.captureException(error);
      toast({
        id: "update-event-plan",
        title: "Failed to update contract",
        message: error.message,
        variant: "error",
      });
    }
  };

  const deleteContract = async () => {
    try {
      await deleteEventPlanMutation.mutateAsync({
        id: contract.id,
        workspace_id: workspace?.id,
      });

      navigate("..");
    } catch (error) {
      Sentry.captureException(error);
      toast({
        id: "delete-event-schema-version",
        title: "Failed to delete contract",
        message: error.message,
        variant: "error",
      });
    }
  };

  return (
    <DetailPage
      title={contract.name}
      crumbs={[{ label: "All contracts", link: "/events/contracts" }]}
      header={
        <Column minWidth={0} gap={2}>
          <Row flex={1} justify="space-between" minWidth={0} pt={1}>
            <Row gap={4} align="center">
              <EditableHeading
                placeholder="Add a name..."
                size="lg"
                value={contract.name ?? ""}
                onChange={(data) => updateEventPlan({ name: data })}
              />
            </Row>

            <Row align="center" gap={4}>
              <Row alignItems="center" gap={2}>
                <Text fontWeight="medium">Undeclared events</Text>
                <Button onClick={() => setShowEnforcementSettings(true)}>
                  {contract.on_undeclared_schema === "ALLOW_EVENT"
                    ? "Allow"
                    : "Block"}
                </Button>
              </Row>
              <Menu>
                <MenuActionsButton variant="secondary" />
                <MenuList>
                  <MenuItem
                    icon={DestinationIcon}
                    onClick={() => {
                      setShowSources(true);
                    }}
                  >
                    Edit event sources
                  </MenuItem>
                  <MenuItem
                    icon={DeleteIcon}
                    variant="danger"
                    onClick={() => {
                      setIsDeleting(true);
                    }}
                  >
                    Delete
                  </MenuItem>
                </MenuList>
              </Menu>
            </Row>
          </Row>
          <Row>
            <EditableDescription
              value={contract.description ?? ""}
              onChange={(data) => updateEventPlan({ description: data })}
            />
          </Row>
          <Row gap={4} flexShrink={0}>
            <Column
              justify="center"
              pr={4}
              borderRight="1px solid"
              borderColor="base.border"
            >
              <Text>
                Used by:
                <Text ml={1} fontWeight="medium">
                  {contract.event_sources_aggregate.aggregate?.count} sources
                </Text>
              </Text>
            </Column>
            <Column>
              <Row align="center" gap={2}>
                <Text>
                  Last updated:{" "}
                  {formatDate(contract.updated_at ?? contract.created_at)}{" "}
                  {updatedByUser && "by"}
                </Text>
                {updatedByUser && (
                  <Avatar
                    name={
                      contract.updated_by_user?.name ??
                      contract.created_by_user?.name
                    }
                    size="sm"
                  />
                )}
              </Row>
            </Column>
          </Row>
        </Column>
      }
    >
      {events.length > 0 && (
        <Row justify="space-between" mb={2}>
          <Text fontWeight="medium" size="lg">
            Event contracts
          </Text>
          <LinkButton variant="primary" href="new_event">
            Add event
          </LinkButton>
        </Row>
      )}

      <Table
        columns={columns}
        data={events}
        onRowClick={({ event_name, event_type, event_version }) =>
          navigate(
            createEventPath({
              eventName: event_name,
              eventType: event_type as EventSchemaEventType,
              eventVersion: event_version,
            }),
          )
        }
        placeholder={{
          image: contractEventsPlaceholder,
          title: "No events added",
          body: "Get started with this contract by adding an event and defining its required properties.",
          error: "Events failed to load, please try again.",
          button: (
            <LinkButton variant="primary" href="new_event">
              Add event
            </LinkButton>
          ),
        }}
      />

      <EnforcementSettingsModal
        contract={contract}
        isOpen={showEnforcementSettings}
        onClose={() => setShowEnforcementSettings(false)}
      />

      <UpdateEventSourcesModal
        isOpen={showSources}
        onClose={() => setShowSources(false)}
      />

      <DeleteConfirmationModal
        isOpen={isDeleting}
        label="contract"
        onClose={() => {
          setIsDeleting(false);
        }}
        onDelete={deleteContract}
      />
    </DetailPage>
  );
};

const columns: TableColumn<Contract["event_schemas"][0]>[] = [
  {
    name: "Name",
    cell: ({ event_name }) => {
      return (
        <TextWithTooltip fontWeight="medium">
          {event_name || "--"}
        </TextWithTooltip>
      );
    },
  },
  {
    name: "Type",
    cell: ({ event_type }) => {
      return (
        <Row gap={1} fontSize="20px">
          <EventTypeIcon eventType={event_type as EventSchemaEventType} />
          <TextWithTooltip fontWeight="medium">
            {capitalize(event_type)}
          </TextWithTooltip>
        </Row>
      );
    },
  },
  {
    name: "Description",
    cell: ({ schema_description }) => {
      return (
        <TextWithTooltip>
          {isEmpty(schema_description) ? "--" : schema_description}
        </TextWithTooltip>
      );
    },
    breakpoint: "sm",
  },
  {
    name: "Enforcement",
    cell: ({ on_schema_violation, on_undeclared_fields }) => {
      const onUndeclaredFields =
        on_undeclared_fields as EventSchemaOnUndeclaredFields;
      const onSchemaViolation =
        on_schema_violation as EventSchemaOnSchemaViolation;

      return (
        <Column minWidth={0}>
          <TextWithTooltip>{`${undeclaredFieldsText[onUndeclaredFields]} undeclared fields`}</TextWithTooltip>
          <TextWithTooltip>{`${schemaViolationText[onSchemaViolation]} invalid fields`}</TextWithTooltip>
        </Column>
      );
    },
    breakpoint: "sm",
  },
  {
    name: "Latest version",
    cell: ({ event_version }) => {
      return <TextWithTooltip>{event_version}</TextWithTooltip>;
    },
  },
  { ...LastUpdatedColumn, breakpoint: "md" },
];

const undeclaredFieldsText: Record<EventSchemaOnUndeclaredFields, string> = {
  ALLOW_EVENT: "Allow",
  OMIT_FIELDS: "Filter",
  BLOCK_EVENT: "Block",
};

const schemaViolationText: Record<EventSchemaOnSchemaViolation, string> = {
  ALLOW_EVENT: "Allow",
  BLOCK_EVENT: "Block",
};
