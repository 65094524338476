import { useEffect, useMemo, useRef, useState } from "react";

import { useToast } from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import partition from "lodash/partition";
import { isPresent } from "ts-extras";

import { validatePropertyCondition } from "src/components/explore/visual/condition-validation";
import {
  MetricResult,
  MetricResultFromCache,
  useCancelAnalyticsQueryMutation,
  useEvaluateTimeSeriesMetricQuery,
  useGetMetricResultBackgroundQuery,
} from "src/graphql";
import {
  AnalyticsFrequency,
  ConditionType,
  PredefinedMetric,
  PropertyCondition,
} from "src/types/visual";

import { TimeMap } from "../state/constants";
import {
  GroupByColumn,
  Metric,
  MetricSelection,
  SelectedAudience,
  TimeOptions,
} from "../types";
import {
  formatMetricsAndMetricDefinitions,
  getInclusiveTimeWindow,
  isValidDateRange,
  removeDisabledSplitGroupsFromMetricResult,
  separateCohortsAndCohortDefinitions,
} from "../utils";

type UseMetricSeriesArgs = {
  enabled: boolean;
  audiences: SelectedAudience[];
  frequency: AnalyticsFrequency;
  groupByColumns: GroupByColumn[];
  metricSelection: MetricSelection[];
  metrics: Metric[];
  parentModelId: string | number | undefined | null;
  timeValue: TimeOptions;
  customDateRange: Date[];
};

// Type to capture both return types, either data from cache or from a live
// query. The only real difference between the two is that MetricResult contains
// a background job ID, but GraphQL's generated types make it annoying to use
// one type in place of another.
export type MetricResultMaybeFromCache = MetricResult | MetricResultFromCache;

const DISALLOWED_METRICS_FOR_ANALYTICS_BREAKDOWNS = new Set<string>([
  PredefinedMetric.AudienceSize,
]);

export const useMetricSeries = ({
  enabled,
  audiences,
  frequency,
  groupByColumns,
  metrics,
  metricSelection: unfilteredMetricSelection,
  parentModelId,
  timeValue,
  customDateRange,
}: UseMetricSeriesArgs) => {
  const { toast } = useToast();
  const [isPolling, setIsPolling] = useState(false);
  const previousJobIds = useRef<string[]>([]);

  const cancelAnalyticsQuery = useCancelAnalyticsQueryMutation({
    onSuccess: () => {
      // set `onSuccess` as a noop so running the mutation
      // does not invalidate cache on cancellations
    },
  });

  const isBreakdownCall = frequency === "all";
  // Do not pass pre-defined metric selections to breakdowns
  const [metricSelection, disallowedBreakdownSelections] = partition(
    unfilteredMetricSelection,
    ({ id }) =>
      !isBreakdownCall || !DISALLOWED_METRICS_FOR_ANALYTICS_BREAKDOWNS.has(id),
  );

  const isMetricSelectionValid = metricSelection
    .flatMap(({ id, conditions }): Record<string, string | null>[] => {
      // No id if metric is not selected yet
      if (!id) {
        return [{ id: "No metric has been selected yet" }];
      }

      // Will be wrapped in an 'and' condition
      if (
        conditions?.[0]?.type === ConditionType.And ||
        conditions?.[0]?.type === ConditionType.Or
      ) {
        const propertyConditions = conditions?.[0]
          ?.conditions as PropertyCondition[];
        return propertyConditions?.map((propertyCondition) =>
          validatePropertyCondition(propertyCondition),
        );
      }

      if (conditions.length > 0) {
        Sentry.captureException(
          `Conditions are malformed. First item condition type is 'type: ${conditions?.[0]?.type}'`,
        );
      }
      return [];
    })
    .every(
      (validationResult) =>
        !validationResult || !Object.values(validationResult).some(Boolean),
    );

  const { metricIds, metricDefinitions } = formatMetricsAndMetricDefinitions(
    parentModelId?.toString() ?? "",
    metricSelection,
    metrics,
  );

  const { cohortIds, cohortDefinitions } = useMemo(
    () =>
      separateCohortsAndCohortDefinitions(
        // parentModelId _is_ a number, but the type system doesn't know that
        parentModelId,
        audiences,
      ),
    [audiences, parentModelId],
  );

  const evaluteTimeSeriesMetricQuery = useEvaluateTimeSeriesMetricQuery(
    {
      metricIds,
      metricDefinitions,
      cohortIds,
      cohortDefinitions,
      groupByColumns: groupByColumns
        .map((column) => {
          if (column.type === "raw") {
            return {
              column: {
                ...column,
                modelId: column.modelId.toString(),
              },
            };
          } else if (
            column.type === "related" &&
            column.column.type === "raw"
          ) {
            return {
              column: {
                ...column,
                column: {
                  ...column.column,
                  modelId: column.column.modelId.toString(),
                },
              },
            };
          } else {
            Sentry.captureException(
              new Error(
                "Column must be raw or related, no trait column are supported.",
              ),
            );
          }

          return null;
        })
        .filter(isPresent),
      lookbackWindow:
        timeValue !== TimeOptions.Custom ? TimeMap[timeValue] : undefined,
      timeWindow:
        timeValue === TimeOptions.Custom && isValidDateRange(customDateRange)
          ? getInclusiveTimeWindow(customDateRange)
          : undefined,
      frequency,
    },
    {
      enabled:
        enabled &&
        isMetricSelectionValid &&
        (metricIds.length > 0 || metricDefinitions.length > 0) &&
        (cohortIds.length > 0 || cohortDefinitions.length > 0) &&
        (Boolean(TimeMap[timeValue]) || isValidDateRange(customDateRange)),
      keepPreviousData: true,
      notifyOnChangeProps: "tracked",
      onSettled: (data) => {
        if (data) {
          const backgroundJobs = data.evaluateTimeSeriesMetric.backgroundJobs;

          // cancel previous requests
          const newJobsIds = backgroundJobs.map((job) => job.jobId);

          if (newJobsIds.some((id) => !previousJobIds.current.includes(id))) {
            previousJobIds.current.forEach((jobId) =>
              cancelAnalyticsQuery.mutate({ jobId }),
            );
          }

          previousJobIds.current = newJobsIds;
        }
      },
    },
  );

  const backgroundJobs =
    evaluteTimeSeriesMetricQuery.data?.evaluateTimeSeriesMetric.backgroundJobs;

  // If data is empty, keep polling.
  const polledTimeSeriesMetricQuery = useGetMetricResultBackgroundQuery(
    {
      jobIds: backgroundJobs?.map(({ jobId }) => jobId) ?? [],
    },
    {
      enabled: isPolling && Boolean(backgroundJobs?.length),
      refetchInterval: 3000,
      // when this finishes we should remove the old job ids.
      onSettled: (data) => {
        if (
          data &&
          data.getMetricResultBackground.data.length ===
            previousJobIds.current.length
        ) {
          previousJobIds.current = [];
        }
      },
    },
  );

  // XXX: CohortDefinition internal type includes an optional `name` property but
  // GraphQL generates the type as "required" with `string | null` type. So the
  // AnalyticsCohortDefinitionOutput types require `name` but it can be nullish.
  // Easiest workaround is to just map the result to make TypeScript happy, but
  // it shouldn't affect underlying data or behavior.
  const immediateData: MetricResultMaybeFromCache[] | undefined = useMemo(
    () =>
      evaluteTimeSeriesMetricQuery.data?.evaluateTimeSeriesMetric?.immediateData?.map(
        (data) => ({
          ...data,
          ids: {
            ...data.ids,
            cohortDefinition:
              data.ids.cohortDefinition != null
                ? { name: null, ...data.ids.cohortDefinition }
                : null,
          },
        }),
      ),
    [evaluteTimeSeriesMetricQuery.data],
  );
  const delayedData: MetricResultMaybeFromCache[] | undefined = useMemo(
    () =>
      polledTimeSeriesMetricQuery.data?.getMetricResultBackground?.data?.map(
        (data) => ({
          ...data,
          ids: {
            ...data.ids,
            cohortDefinition:
              data.ids.cohortDefinition != null
                ? { name: null, ...data.ids.cohortDefinition }
                : null,
          },
        }),
      ),
    [polledTimeSeriesMetricQuery.data],
  );

  const pollingError = polledTimeSeriesMetricQuery.error;

  // Create a stable reference of allData so that there
  // is a stable reference for side effects.
  const allData = useMemo(() => {
    let result = immediateData ?? [];

    if (backgroundJobs?.length) {
      result = result.concat(delayedData ?? []);
    }

    result = removeDisabledSplitGroupsFromMetricResult(result, audiences);

    return result;
  }, [immediateData, backgroundJobs?.length, delayedData, audiences]);

  const errorMessagesByMetricId = useMemo(() => {
    const errorMessageDictionary = {};

    disallowedBreakdownSelections.forEach(({ id }) => {
      errorMessageDictionary[id] =
        "Breakdowns are not supported for this metric";
    });

    return errorMessageDictionary;
  }, [disallowedBreakdownSelections]);

  const errorMessagesByCohortId = useMemo(() => {
    let hasError = false;
    const errorMessagesDictionary = {};

    delayedData?.forEach(({ ids, result }) => {
      const id = ids.cohortDefinition?.parentModelId ?? ids.cohortId;

      if (id) {
        if ("error" in result) {
          // Ignore  group by errors. These are listed as a warning
          // under the groupby columns in the sidebar.
          if (result.error === "Error: unsupported group by column") {
            return;
          }

          errorMessagesDictionary[id] = result.error;
          hasError = true;
        } else if ("data" in result && result.data.length === 0) {
          errorMessagesDictionary[id] = "Audience returned no analytics data";
          hasError = true;
        }
      }
    });

    if (hasError) {
      toast({
        id: "metric-series",
        title: "There was an error in one or more calculations",
        message: "Check the sidebar for more information",
        variant: "error",
      });
    }

    return errorMessagesDictionary;
  }, [delayedData]);

  // Poll data as long as data array is empty and the initial request didn't
  // return any data in immediateData.
  useEffect(() => {
    const hasBackgroundJobs =
      backgroundJobs !== undefined && backgroundJobs.length > 0;

    const analyticsStarted =
      evaluteTimeSeriesMetricQuery.isLoading || hasBackgroundJobs;

    const shouldPoll =
      !pollingError &&
      analyticsStarted &&
      (!delayedData ||
        !backgroundJobs ||
        delayedData.length < backgroundJobs.length);

    setIsPolling(shouldPoll);
  }, [delayedData, immediateData, backgroundJobs, pollingError]);

  return {
    isPolling:
      isPolling ||
      evaluteTimeSeriesMetricQuery.isLoading ||
      evaluteTimeSeriesMetricQuery.isRefetching ||
      polledTimeSeriesMetricQuery.isLoading,
    data: allData,
    pollingError,
    errors: { ...errorMessagesByMetricId, ...errorMessagesByCohortId },
  };
};
