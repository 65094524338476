import { Checkbox, Spinner, Tooltip } from "@hightouchio/ui";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useUser } from "src/contexts/user-context";
import {
  useUserGroupGrantDefaultsByTypeQuery,
  useUserGroupGrantsForResourceQuery,
  useUserGroupsForWorkspaceQuery,
} from "src/graphql";
import {
  isBuiltInPermissionSet,
  isDefaultRole,
} from "src/pages/organizations/roles/utils";
import { Table } from "src/ui/table";
import { TextWithTooltip } from "../text-with-tooltip";
import {
  GrantObject,
  GrantableResource,
  Grants as GrantsType,
  RESOURCE_GRANT_OPTIONS,
} from "./types";
import { getBuiltInGrantValue, getCheckboxProps } from "./util";

type Group<R extends GrantableResource> = {
  id: string;
  name: string;
  grants: GrantObject<R>;
  isWorkspaceAdmin: boolean;
  builtInPermissionSet: string | null;
  isBuiltIn: boolean;
};

type GrantsProps<R extends GrantableResource> = {
  groups: Array<Group<R>> | undefined;
  type: R;
};

function Grants<R extends GrantableResource>({
  groups = [],
  type,
}: GrantsProps<R>) {
  const { control } = useFormContext<{
    grants: GrantsType<R>;
  }>();

  const { workspace } = useUser();

  const grantOptions = RESOURCE_GRANT_OPTIONS(
    workspace?.approvals_required ?? false,
  )[type];

  const { data: defaultGrants } = useUserGroupGrantDefaultsByTypeQuery(
    {
      resourceType: type,
      workspaceId: workspace?.id,
    },
    {
      enabled: Boolean(workspace?.id),
      select: (data) => data.user_group_grants,
    },
  );

  const defaultGrantByUserGroup = defaultGrants?.reduce(
    (acc, grant) => ({
      ...acc,
      [grant.user_group_id]: grant,
    }),
    {},
  );

  return (
    <Table
      columns={[
        {
          name: "Role",
          max: "1fr",
          cell: ({ name }) => (
            <TextWithTooltip fontWeight="medium">{name}</TextWithTooltip>
          ),
        },
        ...grantOptions.map((grant) => ({
          name: grant.label,
          max: "0.75fr",
          cell: (group) => {
            return (
              <Controller
                key={`${group.id}-${grant.value}`}
                name={`grants.${group.id}.${grant.value}` as any}
                defaultValue={group.grants[grant.value]}
                control={control}
                render={({ field }) => {
                  const { checkboxProps, tooltipProps } = getCheckboxProps({
                    value: field.value,
                    defaultValue: group.builtInPermissionSet
                      ? getBuiltInGrantValue(
                          type,
                          grant.value,
                          group.builtInPermissionSet,
                        )
                      : defaultGrantByUserGroup?.[group.id]?.[grant.value],
                    isWorkspaceAdmin: group.isWorkspaceAdmin,
                    isBuiltIn: group.isBuiltIn,
                  });
                  return (
                    <Tooltip {...tooltipProps}>
                      <Checkbox {...checkboxProps} onChange={field.onChange} />
                    </Tooltip>
                  );
                }}
              />
            );
          },
        })),
      ]}
      data={groups}
    />
  );
}

export function GrantSelect({ type }: { type: "source" | "destination" }) {
  const { workspace } = useUser();

  const { data, isLoading } = useUserGroupsForWorkspaceQuery(
    {
      workspaceId: workspace?.id ?? "",
    },
    {
      enabled: Boolean(workspace?.organization),
      select: (data) =>
        data.user_group_workspaces.map(
          ({ user_group, is_workspace_admin, built_in_permission_set }) => ({
            isWorkspaceAdmin: is_workspace_admin,
            isBuiltIn:
              isDefaultRole(user_group.built_in_role_type) ||
              isBuiltInPermissionSet(built_in_permission_set),
            builtInPermissionSet: built_in_permission_set,
            id: user_group.id,
            name: user_group.name,
            grants: {
              can_edit: is_workspace_admin ? true : false,
              can_draft: is_workspace_admin ? true : false,
              can_sync: is_workspace_admin ? true : false,
              can_manage_schema: is_workspace_admin ? true : false,
              can_access_data: is_workspace_admin ? true : false,
              can_run: is_workspace_admin ? true : false,
            },
          }),
        ),
    },
  );

  if (isLoading) {
    return <Spinner />;
  }

  return <Grants groups={data} type={type} />;
}

export const ResourceGrantSelect: FC<
  Readonly<{ id: string | number; type: "source" | "destination" }>
> = ({ id, type }) => {
  const { workspace } = useUser();

  const { data, isLoading } = useUserGroupGrantsForResourceQuery(
    {
      type,
      workspaceId: String(workspace?.id),
      id: String(id),
    },
    {
      select: (data) =>
        data.user_group_grants.map(
          ({
            user_group_workspace,
            user_group,
            can_draft,
            can_edit,
            can_sync,
            can_manage_schema,
            can_access_data,
            can_run,
          }) => ({
            id: user_group.id,
            name: user_group.name,
            isBuiltIn:
              isDefaultRole(user_group.built_in_role_type) ||
              isBuiltInPermissionSet(
                user_group_workspace?.built_in_permission_set ?? null,
              ),
            isWorkspaceAdmin: Boolean(user_group_workspace?.is_workspace_admin),
            builtInPermissionSet:
              user_group_workspace?.built_in_permission_set ?? null,
            grants: {
              can_edit,
              can_draft,
              can_sync,
              can_manage_schema,
              can_access_data,
              can_run,
            },
          }),
        ),
    },
  );

  if (isLoading) {
    return <Spinner />;
  }

  return <Grants groups={data} type={type} />;
};
