import { FC, ReactNode, useMemo, useState } from "react";

import {
  Button,
  Column,
  Spinner,
  Row,
  Text,
  ButtonGroup,
  Dialog,
} from "@hightouchio/ui";

import { Link } from "src/router";

import { Editor } from "src/components/editor";
import { formatSourceSql } from "src/components/sql-editor";
import {
  GetSigmaWorkbooksQuery,
  useGetSigmaWorkbooksQuery,
  useGetSigmaPagesQuery,
  useGetSigmaElementsQuery,
  useGetSigmaSqlQuery,
  GetSigmaPagesQuery,
  GetSigmaElementsQuery,
} from "src/graphql";
import { Sigma } from "src/types/models";
import { SigmaIcon } from "src/ui/icons";
import { Strike } from "src/utils/strike";

type SigmaNames = {
  workbookName: string;
  pageName: string;
  elementName: string;
};

function getSigmaNames(
  sigma: Sigma,
  workbooks:
    | GetSigmaWorkbooksQuery["getSigmaWorkbooks"]["workbooks"]
    | undefined,
  pages: GetSigmaPagesQuery["getSigmaPages"]["pages"] | undefined,
  elements: GetSigmaElementsQuery["getSigmaElements"]["elements"] | undefined,
): SigmaNames {
  const sigmaQuery = sigma.query;
  const workbookName =
    workbooks?.find((w) => w?.workbookId === sigmaQuery.workbookId)?.name ?? "";
  const pageName =
    pages?.find((p) => p?.pageId === sigmaQuery.pageId)?.name ?? "";
  const elementName =
    elements?.find((e) => e?.elementId === sigmaQuery.elementId)?.name ?? "";
  return {
    elementName,
    pageName,
    workbookName,
  };
}

type Props = {
  oldSigma?: Sigma;
  sigma: Sigma;
  source: {
    id: string;
    name: string;
    type: string;
    definition: {
      name: string;
      icon: string;
      isSampleDataSource: boolean;
    };
  };
  actions?: ReactNode;
};

export const SigmaQuery: FC<Readonly<Props>> = ({
  sigma,
  oldSigma,
  source,
  actions,
}) => {
  const [showSql, setShowSql] = useState<boolean>(false);
  const sigmaQuery = sigma?.query;
  const oldSigmaQuery = oldSigma?.query;

  // Using the current sigma IDs in model.query_integrations,
  // load Sigma data so we can derive human readable names
  const {
    data: workbooks,
    isLoading: workbooksLoading,
    error: workbooksError,
  } = useGetSigmaWorkbooksQuery(
    {},
    { select: (data) => data.getSigmaWorkbooks.workbooks },
  );

  const {
    data: pages,
    error: pagesError,
    isLoading: pagesLoading,
  } = useGetSigmaPagesQuery(
    { workbookId: String(sigmaQuery.workbookId) },
    {
      enabled: Boolean(sigmaQuery.workbookId),
      select: (data) => data.getSigmaPages.pages,
    },
  );

  const {
    data: elements,
    error: elementsError,
    isLoading: elementsLoading,
  } = useGetSigmaElementsQuery(
    {
      workbookId: String(sigmaQuery.workbookId),
      pageId: String(sigmaQuery.pageId),
    },
    {
      enabled: Boolean(sigmaQuery.workbookId) && Boolean(sigmaQuery.pageId),
      select: (data) => data.getSigmaElements.elements,
    },
  );

  const {
    data: elementSql,
    error: elementSqlError,
    isLoading: elementSqlLoading,
  } = useGetSigmaSqlQuery(
    {
      workbookId: String(sigmaQuery.workbookId),
      elementId: String(sigmaQuery.elementId),
    },
    {
      enabled: Boolean(sigmaQuery.workbookId) && Boolean(sigmaQuery.elementId),
      select: (data) => data.getSigmaQuery?.sql,
    },
  );

  // If we're passed the old sigma IDs that used to be in model.query_integrations,
  // load more Sigma data so we can diff their human readable names against the current ones
  const {
    data: oldPages,
    error: oldPagesError,
    isLoading: oldPagesLoading,
  } = useGetSigmaPagesQuery(
    { workbookId: String(oldSigmaQuery?.workbookId) },
    {
      enabled: Boolean(oldSigmaQuery?.workbookId),
      select: (data) => data.getSigmaPages.pages,
    },
  );

  const {
    data: oldElements,
    error: oldElementsError,
    isLoading: oldElementsLoading,
  } = useGetSigmaElementsQuery(
    {
      workbookId: String(oldSigmaQuery?.workbookId),
      pageId: String(oldSigmaQuery?.pageId),
    },
    {
      enabled:
        Boolean(oldSigmaQuery?.workbookId) && Boolean(oldSigmaQuery?.pageId),
      select: (data) => data.getSigmaElements.elements,
    },
  );

  const sigmaQueryError =
    elementSqlError ||
    workbooksError ||
    pagesError ||
    elementsError ||
    oldPagesError ||
    oldElementsError;
  if (sigmaQueryError) {
    return (
      <Column
        sx={{
          pt: 10,
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Text color="danger.base">
          Please check that your Sigma credentials are configured correctly in{" "}
          <Link href="/extensions/sigma">settings</Link>.
        </Text>
      </Column>
    );
  }

  // Using the Sigma info we just queried for, convert sigma IDs to human readable names
  const sigmaInfo: SigmaNames = getSigmaNames(
    sigma,
    workbooks,
    pages,
    elements,
  );
  let oldSigmaInfo;
  if (oldSigma) {
    oldSigmaInfo = getSigmaNames(oldSigma, workbooks, oldPages, oldElements);
  }

  const formattedSql = useMemo(
    () => (elementSql ? formatSourceSql(source, elementSql) : ""),
    [elementSql, source],
  );

  const sigmaQueryLoading =
    elementSqlLoading ||
    workbooksLoading ||
    pagesLoading ||
    elementsLoading ||
    oldPagesLoading ||
    oldElementsLoading;

  return (
    <Column
      width="100%"
      border="1px"
      borderColor="base.border"
      borderRadius="md"
      overflow="hidden"
      minWidth={0}
    >
      <Row
        align="center"
        p={4}
        borderBottom="1px"
        borderColor="base.border"
        gap={4}
        justify="space-between"
      >
        <Row align="center" gap={2}>
          <SigmaIcon />
          <Text fontWeight="medium" size="lg">
            Sigma workbook
          </Text>
        </Row>
        <ButtonGroup>
          {elementSql && (
            <Button onClick={() => setShowSql(true)}>View SQL</Button>
          )}
          {actions}
        </ButtonGroup>
      </Row>

      <Column p={4} gap={4} overflow="auto">
        {sigmaQueryLoading ? (
          <Spinner size="lg" m="auto" />
        ) : (
          <>
            <Column>
              <Text fontWeight="medium">Workbook</Text>
              <Strike
                _new={sigmaInfo?.workbookName}
                old={oldSigmaInfo?.workbookName}
              />
            </Column>
            <Column>
              <Text fontWeight="medium">Page</Text>
              <Strike _new={sigmaInfo?.pageName} old={oldSigmaInfo?.pageName} />
            </Column>
            <Column>
              <Text fontWeight="medium">Element</Text>
              <Strike
                _new={sigmaInfo?.elementName}
                old={oldSigmaInfo?.elementName}
              />
            </Column>
          </>
        )}
      </Column>

      <Dialog
        isOpen={showSql}
        variant="info"
        width="auto"
        title="SQL"
        actions={<Button onClick={() => setShowSql(false)}>Close</Button>}
        onClose={() => setShowSql(false)}
      >
        <Editor readOnly language="sql" value={formattedSql} />
      </Dialog>
    </Column>
  );
};
