import { FC } from "react";

import {
  Box,
  CheckIcon,
  ClickIcon,
  Column,
  FormField,
  NumberInput,
  Row,
  Select,
  Text,
  TextInput,
} from "@hightouchio/ui";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";

import { useListInteractionModelsQuery } from "src/graphql";
import { TIME_OPTIONS } from "src/pages/metrics/constants";
import { AttributionMethodType } from "src/types/visual";

import { WeightingOptions } from "./constants";

export const AttributionMethodForm: FC<{
  parentModelId: string;
}> = ({ parentModelId }) => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();
  const weightingOption = watch("weighting");

  const { fields } = useFieldArray({
    control,
    name: "interactions",
    rules: {
      minLength: 1,
    },
  });

  const { data: interactionModelData, isLoading } =
    useListInteractionModelsQuery(
      { parentModelId },
      {
        select: (data) => data.segments ?? [],
      },
    );

  return (
    <Column gap={4}>
      <Controller
        control={control}
        name="name"
        render={({ field, fieldState }) => (
          <FormField
            label="Name"
            description="Something simple like “14 day email open”"
            error={fieldState.error?.message}
          >
            <TextInput
              placeholder="Enter a name..."
              isInvalid={Boolean(fieldState.error?.message)}
              {...field}
            />
          </FormField>
        )}
      />

      <Column gap={4}>
        {fields.map((field, index) => (
          <FormField
            key={field.id}
            label="Interaction"
            description="Select the interaction that attributes users"
            error={
              errors.interactions?.[index]
                ? String(errors.interactions[index].interactionModelId.message)
                : undefined
            }
            {...field}
          >
            <Column gap="4">
              <Controller
                control={control}
                name={`interactions.${index}.interactionModelId`}
                render={({ field }) => (
                  <Select
                    isLoading={isLoading}
                    placeholder="Select an interaction model..."
                    isInvalid={
                      errors.interactions?.[index].interactionModelId.message
                    }
                    {...field}
                    options={interactionModelData ?? []}
                    optionLabel={(option) => option.name}
                    optionValue={(option) => option.id}
                  />
                )}
              />
              <Row pl="4" gap={2} alignItems="center">
                <Text>Lookback window</Text>
                <Box maxWidth="50px">
                  <Controller
                    control={control}
                    name={`interactions.${index}.lookbackWindow.quantity`}
                    render={({ field }) => (
                      <NumberInput isInvalid={false} min={1} {...field} />
                    )}
                  />
                </Box>
                <Controller
                  control={control}
                  name={`interactions.${index}.lookbackWindow.unit`}
                  render={({ field }) => (
                    <Select options={TIME_OPTIONS} {...field} />
                  )}
                />
              </Row>
            </Column>
          </FormField>
        ))}
      </Column>

      <FormField
        label="Weighting"
        description="How should assets be attributed when multiple are touched?"
      >
        <Controller
          control={control}
          name="weighting"
          render={({ field }) => (
            <Select options={WeightingOptions} {...field} />
          )}
        />
      </FormField>
      <WeightingGraphics weightingOption={weightingOption} />
    </Column>
  );
};

export const WeightingGraphics: FC<{
  weightingOption: AttributionMethodType;
}> = ({ weightingOption }) => {
  return (
    <Column>
      <Row gap={4} alignItems="center">
        <Row gap={8}>
          <InteractionGraphic
            isHighlighted={[
              AttributionMethodType.FirstTouch,
              AttributionMethodType.Participation,
            ].includes(weightingOption)}
          />
          <InteractionGraphic
            isHighlighted={[AttributionMethodType.Participation].includes(
              weightingOption,
            )}
          />
          <InteractionGraphic
            isHighlighted={[
              AttributionMethodType.Participation,
              AttributionMethodType.LastTouch,
            ].includes(weightingOption)}
          />
        </Row>
        <Text>=</Text>

        <ConversionGraphic />
      </Row>

      <Row gap={10}>
        <Text color="text.secondary">Interaction</Text>
        <Text color="text.secondary">Interaction</Text>
        <Text color="text.secondary">Interaction</Text>
        <Text fontWeight="semibold" color="success.base">
          Conversion
        </Text>
      </Row>
    </Column>
  );
};

const InteractionGraphic: FC<{
  isHighlighted: boolean;
}> = ({ isHighlighted }) => {
  return (
    <Box
      padding="4"
      background={
        isHighlighted
          ? "radial-gradient(50% 50% at 50% 50%, rgba(239, 245, 243, 0.00) 0%, rgba(239, 245, 243, 0.12) 50.5%, #EFF5F3 95%), rgba(14, 107, 147, 0.60)"
          : "white"
      }
      borderRadius="full"
    >
      <Box
        padding="2"
        color="text.secondary"
        fontSize="24px"
        lineHeight="24px"
        background={isHighlighted ? "link.default" : "white"}
        filter="drop-shadow(0px 33.529px 13.554px rgba(0, 0, 0, 0.01)) drop-shadow(0px 19.261px 11.414px rgba(0, 0, 0, 0.02)) drop-shadow(0px 8.561px 8.561px rgba(0, 0, 0, 0.04)) drop-shadow(0px 2.14px 4.28px rgba(0, 0, 0, 0.05));"
        borderRadius="full"
      >
        <ClickIcon color={isHighlighted ? "white" : "gray.500"} />
      </Box>
    </Box>
  );
};

const ConversionGraphic = () => (
  <Box mr="4" ml="3">
    <Box
      padding="2"
      borderWidth="2px"
      borderColor="success.base"
      fontSize="24px"
      lineHeight="24px"
      borderRadius="full"
    >
      <CheckIcon color="success.base" />
    </Box>
  </Box>
);
