import { Box, Tag, Text } from "@hightouchio/ui";
import { format as formatNumber } from "numerable";
import { ColumnType } from "src/types/visual";
import { ClickToCopy } from "src/components/click-to-copy";

export const DataRow: React.FC<{ value: any; type: ColumnType }> = ({
  value,
  type,
}) => {
  if (value === undefined || value === null) {
    return (
      <Tag>
        <Box px={1}>NULL</Box>
      </Tag>
    );
  }

  if (type === ColumnType.Boolean) {
    return <Text isMonospace>{String(value).toUpperCase()}</Text>;
  }

  if (type === ColumnType.Number) {
    return (
      <ClickToCopy isMonospace value={value}>
        {formatNumber(value)}
      </ClickToCopy>
    );
  }

  if (type === ColumnType.String) {
    if (value === "") {
      return <Tag>EMPTY</Tag>;
    }
    return <ClickToCopy value={value}>{value}</ClickToCopy>;
  }

  return <ClickToCopy value={value}>{value}</ClickToCopy>;
};
