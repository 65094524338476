import { useMemo } from "react";

import {
  useAudiencesAndMetricsForParentModelQuery,
  useParentModelAndRelationshipsQuery,
  useParentModelsForAnalyticsQuery,
} from "src/graphql";
import {
  AudienceAggregationType,
  PerUserAggregationType,
} from "src/types/visual";

import { STATIC_ARRAY } from "./constants";

type UseChartDependencies = {
  parentModelId: number | undefined;
};

/**
 * Hook for fetching the schema information for charts
 */
export const useChartDependencies = ({
  parentModelId,
}: UseChartDependencies) => {
  const parentModelsForAnalyticsQuery = useParentModelsForAnalyticsQuery(
    undefined,
    {
      refetchOnMount: "always",
      select: (data) => data.segments,
    },
  );

  const parentModelOptions = useMemo(
    () =>
      parentModelsForAnalyticsQuery.data?.map((option) => ({
        value: option.id,
        label: option.name,
        connection: option.connection,
      })) ?? [],
    [parentModelsForAnalyticsQuery.data],
  );

  const parentModelAndRelationships = useParentModelAndRelationshipsQuery(
    { id: parentModelId?.toString() ?? "" },
    { enabled: Boolean(parentModelId) },
  );

  const audiencesAndMetrics = useAudiencesAndMetricsForParentModelQuery(
    { id: parentModelId?.toString() ?? "" },
    {
      enabled: Boolean(parentModelId),
      select: (data) => {
        const { segments, goals } = data;

        return {
          ...data,
          goals: goals.map(({ audience_aggregation, ...goal }) => ({
            ...goal,
            aggregation: goal.aggregation as PerUserAggregationType,
            audienceAggregation:
              audience_aggregation as AudienceAggregationType,
          })),
          segments: segments.map((segment) => ({
            ...segment,
            splits: segment.splits.map(({ friendly_name, ...split }) => ({
              ...split,
              name: friendly_name,
              enabled: true,
            })),
          })),
        };
      },
      keepPreviousData: true,
    },
  );

  const parentModel = parentModelAndRelationships.data?.segments_by_pk ?? null;

  const events = useMemo(
    () =>
      parentModel?.relationships?.filter(({ to_model: { event } }) =>
        Boolean(event),
      ) ?? STATIC_ARRAY,
    [parentModel?.relationships],
  );

  const values = useMemo(
    () => ({
      events,
      parentModel,
      parentModelId,
      parentModelOptions,
      audienceOptions: audiencesAndMetrics.data?.segments ?? STATIC_ARRAY,
      metricOptions: audiencesAndMetrics.data?.goals ?? STATIC_ARRAY,
      parentModelLoading: parentModelAndRelationships.isLoading,
      parentModelOptionsLoading: parentModelsForAnalyticsQuery.isLoading,
      dependenciesLoading: audiencesAndMetrics.isLoading,
    }),
    [
      audiencesAndMetrics.data,
      parentModelAndRelationships.data,
      events,
      parentModel,
      parentModelId,
      parentModelOptions,
    ],
  );

  return values;
};
