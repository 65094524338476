import * as Yup from "yup";
import {
  BooleanType,
  Code,
  ContextReference,
  Field,
  Form,
  Input,
  Section,
  ShowModifier,
  showIfKeyIsTruthy,
} from "@hightouch/formkit";
import { FieldMappingSection } from "./field-mapping";

const ConnectionSection = Section({
  children: [
    Field({
      heading: "GCP Project ID",
      component: Input("projectId", {
        validation: Yup.string().required(),
        placeholder: "project-1",
      }),
    }),

    Field({
      heading: "Subscription",
      subheading:
        "The subscription to consume events from, messages must contain properly formatted JSON strings.",
      component: Input("subscription", {
        validation: Yup.string().required(),
        placeholder: "subscription-1",
      }),
    }),

    Field({
      heading: "Regional API Endpoint",
      component: Input("apiEndpoint", {
        validation: Yup.string(),
        placeholder: "https://us-east4-pubsub.googleapis.com",
      }),
      optional: true,
    }),

    ShowModifier({
      condition: {
        type: BooleanType.Unary,
        operand: ContextReference({ key: "credentialId" }),
      },
      children: [
        showIfKeyIsTruthy({
          key: "projectId",
          children: [
            Field({
              heading: "Grant permissions",
              subheading:
                "Run these commands in your Google Cloud Shell to grant the service account access to PubSub.",
              component: Code({
                title: "Add viewer role",
                content: [
                  "gcloud projects add-iam-policy-binding <project-id> \\",
                  "--member serviceAccount:<email> \\",
                  "--role roles/pubsub.viewer",
                ],
              }),
            }),
            Field({
              component: Code({
                title: "Add subscriber role",
                content: [
                  "gcloud projects add-iam-policy-binding <project-id> \\",
                  "--member serviceAccount:<email> \\",
                  "--role roles/pubsub.subscriber",
                ],
              }),
            }),
          ],
        }),
      ],
    }),
  ],
});

export const PubSubForm = Form({
  children: [ConnectionSection, FieldMappingSection],
});
