import { FC, useMemo } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Form as FormkitForm } from "src/formkit/components/form";
import { processFormNode } from "src/formkit/formkit";
import { ProviderSection } from "src/components/sources/setup/providers";
import {
  SharedFormMethodProps,
  Step,
  Tunnel,
} from "src/components/sources/setup/form-method";
import { Section } from "src/components/sources/setup/types";

/**
 * Forked from packages/app/src/components/sources/setup/form-method.tsx.
 */
export const FormMethod: FC<Readonly<SharedFormMethodProps>> = ({
  definition,
  config,
  setupMethods,
  tunnelId,
  setTunnelId,
  credentialId,
  setCredentialId,
}) => {
  const { sourceSnowflakeEnableTunnel } = useFlags();
  const matchingDestination = setupMethods?.find(
    (o) => o.key === config?.methodKey,
  );

  const Form = useMemo(
    () =>
      matchingDestination?.form && (
        <FormkitForm compact>
          {processFormNode(matchingDestination.form)}
        </FormkitForm>
      ),
    [matchingDestination?.key],
  );

  const CredentialsForm = useMemo(
    () =>
      matchingDestination?.credentialsForm && (
        <FormkitForm compact>
          {processFormNode(matchingDestination.credentialsForm)}
        </FormkitForm>
      ),
    [matchingDestination?.key],
  );

  const showTunneling =
    // Does the destination form support tunneling?
    matchingDestination?.tunneling &&
    // If the destination is Snowflake, check that the user has the matching feature flag.
    (definition.type !== "snowflake" ||
      (definition.type === "snowflake" && sourceSnowflakeEnableTunnel));

  const sections: Section[] = useMemo(() => {
    const steps: Section[] = [];
    if (showTunneling) {
      steps.push(Section.Tunneling);
    }

    if (["gcp", "aws", "azure"].includes(matchingDestination?.provider)) {
      steps.push(Section.Providers);
    }

    if (matchingDestination?.form) {
      steps.push(Section.Form);
    }

    if (matchingDestination?.credentialsForm) {
      steps.push(Section.CredentialsForm);
    }

    return steps;
  }, [
    matchingDestination,
    definition.supportsInWarehouseDiffing,
    showTunneling,
  ]);

  const hasMultipleSetupMethods = setupMethods.length > 1;

  return (
    <>
      <Step
        description={`Hightouch can connect directly to ${definition.name} if it's exposed to the internet. However, if you need to open a connection within a private network or VPC, you will need to set up an SSH tunnel.`}
        hasMultipleSetupMethods={hasMultipleSetupMethods}
        section={Section.Tunneling}
        sections={sections}
        title="Choose your connection type"
      >
        <Tunnel
          name={definition.name}
          value={tunnelId}
          onChange={setTunnelId}
        />
      </Step>
      <Step
        hasMultipleSetupMethods={hasMultipleSetupMethods}
        section={Section.Providers}
        sections={sections}
        title="Configure your credentials"
      >
        <ProviderSection
          credentialId={credentialId}
          provider={matchingDestination.provider}
          setCredentialId={setCredentialId}
        />
      </Step>
      <Step
        hasMultipleSetupMethods={hasMultipleSetupMethods}
        section={Section.Form}
        sections={sections}
        title={`Configure your ${definition.name} destination`}
      >
        {Form}
      </Step>
      <Step
        hasMultipleSetupMethods={hasMultipleSetupMethods}
        section={Section.CredentialsForm}
        sections={sections}
        title={`Provide your ${definition.name} credentials`}
      >
        {CredentialsForm}
      </Step>
    </>
  );
};
