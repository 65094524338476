import { DEFAULT_LIGHTNING_SCHEMA_CONFIG } from "../form-method";

export const DEFAULT_LIGHTNING_REASON = ({
  definitionName,
  schema = DEFAULT_LIGHTNING_SCHEMA_CONFIG,
}) => {
  const schemaNames = new Set(Object.values(schema));
  const schemaLabel = schemaNames.size === 1 ? "a schema" : "schemas";
  return `This SQL snippet creates a dedicated ${definitionName} user for Hightouch. It also provisions ${schemaLabel} for storing logs of previously-synced data. These caches are used by the Lightning engine to improve sync speed.`;
};

export const DEFAULT_LIGHTNING_CODE_TITLE = () =>
  "Set up a service user to write to your instance";
