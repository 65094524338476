import {
  AnalyticsSummaryStats,
  isDecisionEngineAnalyticsMetricsDefinition,
  isPredefinedAnalyticsMetricDefinition,
} from "@hightouch/lib/query/visual/types";
import {
  AudienceAggregationType,
  PerUserAggregationType,
  PredefinedMetric,
  predefinedMetricNames,
} from "@hightouch/lib/query/visual/types/goals";
import { toZonedTime } from "date-fns-tz";
import set from "lodash/set";
import { format as formatNumber } from "numerable";
import { isPresent } from "ts-extras";
import { isEqualWith, uniq, uniqBy } from "lodash";

import {
  getModelIdFromColumn,
  getPropertyNameFromColumn,
} from "src/components/explore/visual/utils";
import {
  AnalyticsCohortDefinitionOutput,
  MetricIdentifier,
  MetricJobIdentifier,
} from "src/graphql";
import {
  GroupByColumn,
  ParentModel,
  SelectedAudience,
} from "src/pages/analytics/types";
import { AggregationOption } from "src/pages/metrics/constants";
import { mapAggregationConfigurationToConfigurationOption } from "src/pages/metrics/utils";
import {
  AnalyticsMetricDefinition,
  FilterableColumn,
  RawColumn,
  RelatedColumn,
  Relationship,
  SumMetricConfig,
  exhaustiveCheck,
} from "src/types/visual";
import { accurateCommaNumber } from "src/utils/numbers";

import { MetricResultMaybeFromCache } from "src/pages/analytics/hooks/use-metric-series";
import { graphColors } from "src/pages/analytics/constants";
import { GraphScale } from "./constants";
import { DataPoint, Graph, GroupColumn, SummaryData } from "./types";
import {
  findAudienceFromCohort,
  isGroupByColumnRelatedToEvent,
  isGroupByColumnRelatedToParent,
  numberAndStringValidator,
} from "src/pages/analytics/utils";

export const transformMetricDataForGraph = ({
  audiences = [],
  cumulative = false,
  events = [],
  groupByColumns = [],
  numberOfSelectedMetrics,
  metricResults = [],
  metrics = [],
  parent,
  transformForPerformance = false,
}: {
  audiences: SelectedAudience[];
  cumulative?: boolean;
  events: Relationship[];
  groupByColumns: GroupByColumn[];
  numberOfSelectedMetrics: number;
  metricResults: MetricResultMaybeFromCache[];
  metrics: { id: string; name: string }[];
  parent: ParentModel | null;
  transformForPerformance?: boolean;
}) => {
  const graph: Graph = { series: [], summary: [] };

  if (!metricResults.length) return graph;

  const metricAndEventNamesById: Record<string, string> = {};
  metrics.forEach((metric) => {
    metricAndEventNamesById[metric.id] = metric.name;
  });
  events.forEach((event) => {
    if (event.id !== null) {
      metricAndEventNamesById[event.id] = event.to_model.name ?? "";
    }
  });

  // Use a different color for each series
  let colorIndex = 0;

  let dateRange: number[] = [];

  const ids = metricResults.map(({ ids }) => ids);

  const hasMultipleSeries = audiences.some(({ splits }) =>
    splits?.some(({ enabled }) => enabled),
  );
  const hasMultipleMetrics = numberOfSelectedMetrics > 1;
  const hasMultipleCohorts = uniqueCohorts(ids).size > 1;
  const uniqueEventModelIds = uniq(
    metricResults.map((m) =>
      m.ids.metricDefinition?.config?.eventModelId?.toString(),
    ),
  ).filter(isPresent);

  metricResults.forEach(({ ids, result }, index) => {
    const audienceId = Number(
      ids.cohortDefinition?.parentModelId ?? ids.cohortId,
    );

    const foundAudience = findAudienceFromCohort(
      audiences,
      ids.cohortDefinition?.filter,
      audienceId,
    );

    const audienceName = foundAudience?.name ?? "--";

    if ("data" in result) {
      const metricAndCohortCombinationKey = getMetricAndCohortCombinationKey(
        ids.cohortId,
        ids.metricId,
        ids.metricDefinition,
      );

      const metricName = getMetricName(ids, metricAndEventNamesById, events, {
        includeDescription: true,
      });
      const rawMetricName = getMetricName(ids, metricAndEventNamesById, events);
      const metricDefinition = ids.metricId
        ? metrics.find(({ id }) => id === ids.metricId)
        : ids.metricDefinition;

      if (!metricDefinition) {
        // No metric definition found, so don't add metric to graph.
        return;
      }

      const { data, summaryStats } = result;

      // We'll only show summary stats if one audience is selected.
      // At the moment, it's difficult to compare audiences because of the variation between
      // audiences in split names, split %, presence of splits, etc.
      // The UI needs to be tweaked if we want to do that in the future.
      if (!hasMultipleCohorts && summaryStats && summaryStats.data.length) {
        graph.summary.push({
          metricName,
          isSavedMetric: Boolean(ids.metricId),
          timeWindow: summaryStats.timeWindow,
          data: transformSummaryStatsData(summaryStats.data, audienceName),
        });
      }

      const eventsByModelId = {};
      for (const e of events) {
        eventsByModelId[e.to_model.id] = e;
      }

      data.forEach((metricSeries) => {
        const relevantGroupByColumns = filterRelevantGroupByColumns(
          groupByColumns,
          eventsByModelId[metricDefinition?.config?.eventModelId?.toString()],
          parent,
          uniqueEventModelIds.length,
        );

        const grouping = relevantGroupByColumns
          .map((groupByColumn) => {
            const modelId = getModelIdFromColumn(groupByColumn);
            const isParentModelId = parent && parent.id.toString() === modelId;

            const model = isParentModelId
              ? parent
              : events.find(
                  ({ to_model }) => modelId === to_model.id.toString(),
                )?.to_model;

            const columnNameOrAlias = getColumnName(groupByColumn, model);

            const groupBy = metricSeries.groupBy?.find((gb) =>
              isEqualWith(groupByColumn, gb.column, numberAndStringValidator),
            );

            // Returned match
            if (groupBy) {
              return { ...groupBy, alias: columnNameOrAlias };
            }

            return {
              column: groupByColumn,
              alias: columnNameOrAlias,
              value: null,
            };
          })
          .filter(isPresent);

        const aggregation =
          mapAggregationConfigurationToConfigurationOption(
            getAggregationConfiguration(
              metricDefinition as AnalyticsMetricDefinition,
            ),
          ) ?? AggregationOption.Count;

        const seriesKey = getSeriesKey({
          metricAndCohortCombinationKey,
          audienceId,
          audienceName,
          metricName,
          splitId: metricSeries.splitId,
          groupByColumns: grouping,
          index,
        });

        const seriesName = getSeriesName({
          audienceName,
          ...(transformForPerformance
            ? {
                metricName,
                splitName: metricSeries.splitId,
                groupByColumns: grouping,
              }
            : {}),
        });

        const legendName = getLegendName({
          audienceName,
          metricName,
          splitName: metricSeries.splitId,
          groupByColumns: grouping,

          hasMultipleSeries,
          hasMultipleMetrics,
          hasMultipleCohorts,
        });

        const data: DataPoint[] = [];
        let currentSum = 0;

        metricSeries.data.forEach(({ timestamp, value }) => {
          // We know the date values coming back from the backend are "in UTC"
          // (meaning they have no time zone, and represent the date we care
          // about in UTC). So, we convert them so they represent the same
          // timestamp, but in the browser's local time zone instead. That way,
          // when we call any date format methods, they'll show the date
          // correctly (since the browser renders dates in the local time zone).
          const calculatedAt = toZonedTime(timestamp, "UTC").getTime();

          dateRange.push(calculatedAt);

          data.push({
            calculatedAt,
            metricValue: cumulative ? (currentSum += value) : value,
            seriesKey,

            // TODO(samuel): pass in custom cohort id in version 2.0
            audienceId: ids.cohortId?.toString() ?? "",
            aggregation,
            metricName,
            splitId: metricSeries.splitId,
            grouping,
          });
        });

        graph.series.push({
          key: seriesKey,
          metricName,
          rawMetricName,
          name: seriesName,
          legendName,
          audienceName,
          splitName: metricSeries.splitId ?? undefined,
          grouping,
          aggregation,
          color: graphColors[colorIndex++ % graphColors.length]!.color,
          data,
        });
      });
    }
  });

  dateRange = dateRange.filter((date, index, self) => {
    return self.findIndex((d) => d === date) === index;
  });
  dateRange.sort((dateA, dateB) => (dateA > dateB ? 1 : -1));

  // fill in missing values for performance graph
  if (transformForPerformance) {
    graph.series = graph.series.map((series) => {
      return {
        ...series,
        data: fillInMissingDateValues(series.key, series.data, dateRange),
      };
    });
  }

  return graph;
};

const getMetricAndCohortCombinationKey = (
  cohortId: string | null,
  metricId: string | null,
  metricDefinition: AnalyticsMetricDefinition,
) => {
  let metricAndCohortCombinationKey: string = cohortId?.toString() ?? "";
  // There are three kinds of metrics
  // 1) "saved" metrics -- in which case we have the ID
  // 2) "predefined" metrics -- in which case we use the predefinedMetric ID
  // 3) "live" metric -- an event model that we use to query, so we use the event model ID
  // They are all different types, so we have to build the unique key conditionally
  if (metricId) {
    metricAndCohortCombinationKey += metricId.toString();
  } else if (isPredefinedAnalyticsMetricDefinition(metricDefinition)) {
    metricAndCohortCombinationKey +=
      metricDefinition.predefinedMetric.toString();
  } else if (isDecisionEngineAnalyticsMetricsDefinition(metricDefinition)) {
    metricAndCohortCombinationKey += metricDefinition.flowId;
  } else if (metricDefinition?.config) {
    metricAndCohortCombinationKey +=
      metricDefinition.config.eventModelId.toString();
  }

  return metricAndCohortCombinationKey;
};

export const valueFormatter = (
  value: number,
  format: GraphScale = GraphScale.Linear,
): string => {
  if (format === GraphScale.Linear) {
    return formatNumber(value, "0,0[.]0a");
  } else {
    return `${(value * 100).toFixed(2)}%`;
  }
};

const getColumnName = (
  column: RawColumn | RelatedColumn,
  model?: { filterable_audience_columns: FilterableColumn[] } | undefined,
) => {
  const columnName = getPropertyNameFromColumn(column) ?? "";

  if (!model) {
    return columnName;
  }

  const columnDefinition = model.filterable_audience_columns.find(
    ({ name }) => name === columnName,
  );

  return columnDefinition?.alias ?? columnDefinition?.name ?? columnName;
};

const getSeriesKey = ({
  audienceId,
  audienceName,
  groupByColumns = [],
  index,
  metricAndCohortCombinationKey,
  metricName,
  splitId,
}: {
  // We can have multiple cohort definitions with the same ID but different
  // filters (i.e. parent model vs ad hod audience)
  audienceId: number;
  audienceName: string;
  groupByColumns?: GroupColumn[] | null;
  index?: number;
  metricAndCohortCombinationKey: string;
  metricName: string;
  splitId?: string | null;
}): string => {
  return [
    index,
    metricAndCohortCombinationKey,
    audienceId,
    audienceName,
    metricName,
    splitId,
    ...(groupByColumns ?? []).map(
      ({ column, alias, value }) =>
        `${alias ?? getPropertyNameFromColumn(column)} ${value}`,
    ),
  ]
    .filter(isPresent)
    .join(":");
};

export const getSeriesName = ({
  audienceName,
  splitName,
  metricName,
  groupByColumns = [],
}: {
  audienceName?: string;
  metricName?: string;
  splitName?: string | null;
  groupByColumns?: GroupColumn[] | null;
} = {}): string => {
  return [
    metricName,
    audienceName,
    splitName,
    ...(groupByColumns ?? []).map(
      ({ column, alias, value }) =>
        `${alias ?? getPropertyNameFromColumn(column)} = ${value}`,
    ),
  ]
    .filter(isPresent)
    .join(" / ");
};

/**
 * This function is used to get the legend name for the graph
 *
 * - If an /element/ of the legend is shared by all series, omit it
 * - If there's only 1 series, don't omit anything (show <metrics> / <audience>)
 */
export const getLegendName = ({
  audienceName,
  metricName,
  splitName,
  groupByColumns = [],
  hasMultipleSeries,
  hasMultipleMetrics,
  hasMultipleCohorts,
}: {
  audienceName?: string;
  metricName: string;
  splitName?: string | null;
  groupByColumns?: GroupColumn[];
  hasMultipleSeries: boolean;
  hasMultipleMetrics: boolean;
  hasMultipleCohorts: boolean;
}) => {
  const onlyOneSeries =
    !hasMultipleSeries && !hasMultipleMetrics && !hasMultipleCohorts;

  return getSeriesName({
    audienceName:
      onlyOneSeries || hasMultipleSeries || hasMultipleCohorts
        ? audienceName
        : undefined,
    metricName: onlyOneSeries || hasMultipleMetrics ? metricName : undefined,
    splitName,
    groupByColumns,
  });
};

export const getMetricName = (
  ids: Omit<MetricIdentifier, "__typename">,
  metricAndEventNamesById: Record<string, string>,
  events: Relationship[],
  options?: {
    includeDescription: boolean;
  },
): string => {
  // Saved metric
  if (ids.metricId) {
    return metricAndEventNamesById[ids.metricId] ?? "Metric";
  }

  // Live metric - predefined or ad-hoc
  const metricDefinition = ids.metricDefinition as AnalyticsMetricDefinition;

  // Predefined metric
  if (isPredefinedAnalyticsMetricDefinition(metricDefinition)) {
    return getPredefinedMetricName(
      metricDefinition.predefinedMetric as PredefinedMetric,
    );
  }

  // Decision engine metric
  if (isDecisionEngineAnalyticsMetricsDefinition(metricDefinition)) {
    // TODO(mitch & jen): figure out what to show here.
    return metricDefinition.flowId;
  }

  // Ad-hoc metric
  const metricName =
    metricAndEventNamesById[metricDefinition.config.relationshipId] ?? "Event";

  if (!options?.includeDescription) {
    return metricName;
  }

  const aggregation =
    mapAggregationConfigurationToConfigurationOption(metricDefinition);

  if (!aggregation) {
    return metricName;
  }

  const config = metricDefinition.config as SumMetricConfig;
  const columns = events.find(
    ({ id }) => ids.metricDefinition?.config?.relationshipId === id.toString(),
  )?.to_model.filterable_audience_columns;
  const column = columns?.find(
    ({ name }) => name === getPropertyNameFromColumn(config.column),
  );

  return `${metricName} [${getMetricDescription({
    aggregationMethod: aggregation,
    column,
  })}]`;
};

export const getMetricDescription = ({
  aggregationMethod,
  column,
}: {
  aggregationMethod: AggregationOption;
  column: { alias: string | null; name: string | null } | undefined;
}) => {
  const aggregation = aggregationMethod;
  const columnName = column?.alias ?? column?.name;

  switch (aggregation) {
    case AggregationOption.Count:
      return "Total events";
    case AggregationOption.UniqueUsers:
      return "Unique users";
    case AggregationOption.PercentOfAudience:
      return "% of audience";
    case AggregationOption.CountDistinctProperty:
      return `Distinct count of ${columnName}`;
    case AggregationOption.AverageOfProperty:
      return `Average of ${columnName}`;
    case AggregationOption.AverageOfPropertyPerUser:
      return `Average of ${columnName} per user`;
    case AggregationOption.SumOfProperty:
      return `Sum of ${columnName}`;
    case AggregationOption.SumOfPropertyPerUser:
      return `Sum of ${columnName} per user - Averaged`;
    default:
      exhaustiveCheck(aggregation);
  }
};

const getPredefinedMetricName = (metricEnum: PredefinedMetric) => {
  return predefinedMetricNames[metricEnum];
};

const fillInMissingDateValues = (
  seriesKey: string,
  data: DataPoint[],
  dateArray: number[],
): DataPoint[] => {
  const result: DataPoint[] = [];

  const dataByDate = data.reduce((all, dataPoint) => {
    all[dataPoint.calculatedAt] = dataPoint;

    return all;
  }, {});

  dateArray.map((date) => {
    const entry = dataByDate[date];
    if (entry) {
      result.push(entry);
    } else {
      const { metricName, audienceId, splitId, grouping, aggregation } =
        entry ?? {};

      result.push({
        calculatedAt: date,
        metricValue: 0,
        seriesKey,

        aggregation,
        metricName,
        audienceId,
        splitId,
        grouping,
      });
    }
  });

  return result;
};

export const getTooltipSuffixTextFromAggregation = (
  aggregation: AggregationOption,
) => {
  switch (aggregation) {
    case AggregationOption.Count:
      return "events";
    case AggregationOption.UniqueUsers:
      return "users";
    case AggregationOption.AverageOfPropertyPerUser:
    case AggregationOption.SumOfPropertyPerUser:
      return "per user";
    case AggregationOption.SumOfProperty:
    case AggregationOption.AverageOfProperty:
    case AggregationOption.PercentOfAudience:
    case AggregationOption.CountDistinctProperty:
      return "";
    default:
      exhaustiveCheck(aggregation);
  }
};

export const getSummaryFromAggregation = (
  aggregation: AggregationOption,
  eventName: string,
  columnName?: string,
) => {
  switch (aggregation) {
    case AggregationOption.Count:
      return `Total number of "${eventName}" events performed`;
    case AggregationOption.CountDistinctProperty:
      return `Total number of distinct "${columnName}" across all events`;
    case AggregationOption.UniqueUsers:
      return `Total number of users who performed "${eventName}"`;
    case AggregationOption.PercentOfAudience:
      return `% of users who performed "${eventName}"`;
    case AggregationOption.SumOfProperty:
      return `SUM of "${columnName}" of "${eventName}" events`;
    case AggregationOption.SumOfPropertyPerUser:
      return `SUM of "${columnName}" per user of "${eventName}" events`;
    case AggregationOption.AverageOfProperty:
      return `Average of "${columnName}" across all "${eventName}" events`;
    case AggregationOption.AverageOfPropertyPerUser:
      return `Average of "${columnName}" per user of "${eventName}" events`;
    default:
      exhaustiveCheck(aggregation);
  }
};

export const formatMetricValue = (
  metricValue: number,
  isPercentage = false,
) => {
  return isPercentage
    ? `${(metricValue * 100).toFixed(2)}%`
    : accurateCommaNumber(metricValue.toFixed(2));
};

const getAggregationConfiguration = (
  metricDefinition: AnalyticsMetricDefinition,
): {
  aggregation: PerUserAggregationType;
  audienceAggregation: AudienceAggregationType;
} => {
  if (isPredefinedAnalyticsMetricDefinition(metricDefinition)) {
    const predefinedMetric =
      metricDefinition.predefinedMetric as PredefinedMetric;

    if (predefinedMetric === PredefinedMetric.AudienceSize) {
      return {
        aggregation: PerUserAggregationType.UniqueUsers,
        audienceAggregation: AudienceAggregationType.Sum,
      };
    }

    throw new Error(`Unrecognized predefined metric: ${predefinedMetric}`);
  } else {
    return {
      aggregation: metricDefinition.aggregation,
      audienceAggregation: metricDefinition.audienceAggregation,
    };
  }
};

const transformSummaryStatsData = (
  data: AnalyticsSummaryStats[],
  audienceName: string | undefined,
): SummaryData[] => {
  const hasSplits = data.length > 1;

  const baselineValue =
    (hasSplits ? data.find((s) => s.isBaseline)?.value : data[0]?.value) ?? 0;

  // Compute the percent difference for each split
  const transformedSummaryStats = data.map((summary) => ({
    ...summary,
    percentDifference: (summary.value - baselineValue) / baselineValue,
    audienceName: audienceName,
  }));

  // If there are splits, find the winning split group so we can highlight it
  if (hasSplits) {
    let largestIndex = -1;
    let largestValue = Number.NEGATIVE_INFINITY;
    for (let i = 0; i < data.length; i++) {
      const current = data[i];
      if (current == null) {
        // This shouldn't be null, but do this to appease typescript
        continue;
      }

      if (current.value > largestValue) {
        largestValue = current.value;
        largestIndex = i;
      }
    }
    set(transformedSummaryStats, [largestIndex, "isWinner"], true);
  }

  return transformedSummaryStats;
};

const uniqueCohorts = (
  metrics: (MetricIdentifier | MetricJobIdentifier)[],
): Set<string | AnalyticsCohortDefinitionOutput> => {
  const cohorts = new Set<string | AnalyticsCohortDefinitionOutput>();

  for (const { cohortId, cohortDefinition } of metrics) {
    // Each metric is assigned to a cohort consisting of either a cohortId or cohortDefinition
    // so we check for both

    if (cohortId && !cohorts.has(cohortId)) {
      cohorts.add(cohortId);
    }

    if (cohortDefinition && !cohorts.has(cohortDefinition)) {
      cohorts.add(cohortDefinition);
    }
  }

  return cohorts;
};

// Determine which groupByColumns to map for the specific metric result's data
// Assume that when there are multiple event models from the metrics results,
// we only want to the groupByColumns that are related to the specific event
// bc we only allow groupBys of the same column names
const filterRelevantGroupByColumns = (
  groupByColumns: GroupByColumn[],
  metricEvent: Relationship | undefined,
  parent: ParentModel | null,
  numberOfMetricsEventModels: number,
): GroupByColumn[] => {
  if (numberOfMetricsEventModels < 2) {
    return groupByColumns;
  }

  // For metric definitions without an event model, return all parent groupByColumns
  // and unique event groupByColumns by name since we are only going to display
  // one column in the graph for an event column name
  const metricEventModelId = metricEvent?.to_model?.id;
  if (!metricEventModelId) {
    const parentGroupByColumns = groupByColumns.filter((gb) =>
      isGroupByColumnRelatedToParent(parent, gb),
    );

    const uniqueEventGroupByColumns = uniqBy(
      groupByColumns.filter((gb) => {
        return !isGroupByColumnRelatedToParent(parent, gb);
      }),
      (gb) => getPropertyNameFromColumn(gb),
    );

    return parentGroupByColumns.concat(uniqueEventGroupByColumns);
  }

  // Only return groupByColumns related to this metric's event or parent model
  return groupByColumns.filter((gb) => {
    return Boolean(
      isGroupByColumnRelatedToParent(parent, gb) ||
        isGroupByColumnRelatedToEvent(metricEvent, gb),
    );
  });
};
