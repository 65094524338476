import { FormkitComponent } from "@hightouch/formkit";
import {
  Row,
  InformationIcon,
  Switch,
  Text,
  Tooltip,
  Box,
} from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { FC, PropsWithChildren } from "react";
import { useFormContext, Controller } from "react-hook-form";

import { useFormkitContext } from "./formkit-context";

type SyncTemplateLockProps = {
  node: FormkitComponent;
};

export const SyncTemplateLock: FC<PropsWithChildren<SyncTemplateLockProps>> = ({
  node,
}) => {
  const { enableSyncTemplateOverrides } = useFlags();
  const { allowOverrides } = useFormkitContext();
  const { control } = useFormContext();

  // TODO: determine message from overrides
  const message = "";

  if (!enableSyncTemplateOverrides || !allowOverrides) {
    return null;
  }

  return (
    <Row
      bg="base.background"
      px={4}
      py={2}
      height="min-content"
      borderRadius="md"
      sx={{
        label: {
          display: "flex",
          gap: 4,
          alignItems: "center",
          whiteSpace: "nowrap",
        },
      }}
    >
      <Text as="label" fontWeight="medium">
        <Row gap={1}>
          Lock this field
          {message && (
            <Tooltip message={message}>
              <Box as={InformationIcon} color="text.secondary" />
            </Tooltip>
          )}
        </Row>
        <Controller
          name={`__overrideConfig.${node.key}.overridable`}
          control={control}
          render={({ field }) => (
            <Switch
              size="sm"
              isChecked={!field.value}
              onChange={(value) => {
                field.onChange(!value);
              }}
            />
          )}
        />
      </Text>
    </Row>
  );
};
