import { FC, ReactNode } from "react";

import { FormkitComponent } from "@hightouch/formkit";

import { Card } from "src/components/card";
import { SyncTemplateLock } from "./sync-template-lock";

type Props = {
  heading?: string;
  subheading?: string;
  children?: ReactNode;
  componentChildren?: FormkitComponent;
  isOptional?: boolean;
};

export const Section: FC<Readonly<Props>> = ({
  heading,
  subheading,
  children,
  componentChildren,
  isOptional,
}) => {
  return (
    <Card
      gap={6}
      heading={heading}
      subheading={subheading}
      rightContent={
        componentChildren && <SyncTemplateLock node={componentChildren} />
      }
      sx={
        isOptional
          ? {
              "h3::after": {
                content: "' (optional)'",
                color: "text.secondary",
                fontWeight: "normal",
              },
            }
          : undefined
      }
    >
      {children}
    </Card>
  );
};
