import {
  AverageTraitConfig,
  ColumnType,
  CountDedupedTraitConfig,
  CountTraitConfig,
  FormulaTraitConfig,
  isMergedColumn,
  OrderDedupedTraitConfig,
  RawColumn,
  RawSqlTraitConfig,
  RelatedColumn,
  SumTraitConfig,
  TraitCondition,
  TraitConfig,
  TraitType,
} from "@hightouch/lib/query/visual/types";
import uniq from "lodash/uniq";

import { SyncableColumn } from "src/graphql";
import { exhaustiveCheck } from "src/types/visual";

export enum CalculationMethod {
  Aggregation = "aggregation",
  Count = "count",
  Occurrence = "occurrence",
  Sql = "sql",
  Formula = "formula",
}

export const CALCULATION_METHODS = {
  [CalculationMethod.Aggregation]: {
    label: "Aggregation",
    value: CalculationMethod.Aggregation,
    description: "Calculate the sum or average",
    examples: [
      "Sum of all customer order values",
      "Average user session length",
    ],
  },
  [CalculationMethod.Count]: {
    label: "Count",
    value: CalculationMethod.Count,
    description: "The number of occurrences",
    examples: ["Orders completed", "Page views", "User sessions"],
  },
  [CalculationMethod.Occurrence]: {
    label: "Occurrence",
    value: CalculationMethod.Occurrence,
    description: "Determine the first, last, most or least frequent",
    examples: [
      "First song listened to",
      "Last product viewed",
      "Most frequent product viewed",
      "Least frequent user login",
    ],
  },
  [CalculationMethod.Sql]: {
    label: "SQL Aggregation",
    value: CalculationMethod.Sql,
    description:
      "Aggregate across related events or entities (sums, averages, counts, arrays, etc)",
    examples: [
      "Building a JSON object",
      "Building a list",
      "Grabbing a specific field out of a JSON object",
      "Custom aggregation",
    ],
  },
  [CalculationMethod.Formula]: {
    label: "SQL Formula",
    value: CalculationMethod.Formula,
    description: "Calculate based on properties and traits with SQL",
    examples: [
      "Using LTV to classify customer loyalty tiers",
      'Creates a "true" string if the user meets a series of criteria',
    ],
  },
};

export const traitTypeToCalculationMethod = {
  [TraitType.Sum]: CalculationMethod.Aggregation,
  [TraitType.Average]: CalculationMethod.Aggregation,
  [TraitType.Count]: CalculationMethod.Count,
  [TraitType.First]: CalculationMethod.Occurrence,
  [TraitType.Last]: CalculationMethod.Occurrence,
  [TraitType.LeastFrequent]: CalculationMethod.Occurrence,
  [TraitType.MostFrequent]: CalculationMethod.Occurrence,
  [TraitType.RawSql]: CalculationMethod.Sql,
  [TraitType.Formula]: CalculationMethod.Formula,
};

export const defaultTypeByCalculationMethod = {
  [CalculationMethod.Aggregation]: TraitType.Sum,
  [CalculationMethod.Count]: TraitType.Count,
  [CalculationMethod.Occurrence]: TraitType.First,
  [CalculationMethod.Sql]: TraitType.RawSql,
  [CalculationMethod.Formula]: TraitType.Formula,
};

// Returns true if validation passes
export const validateConfig = (
  type: TraitType | undefined,
  rawConfig: TraitConfig | undefined,
): boolean => {
  if (type == undefined || rawConfig == undefined) {
    return false;
  }

  switch (type) {
    case TraitType.Sum:
      return (rawConfig as SumTraitConfig).column != undefined;
    case TraitType.Average:
      return (rawConfig as AverageTraitConfig).column != undefined;
    case TraitType.Count:
      return (rawConfig as CountTraitConfig).column != undefined;
    case TraitType.MostFrequent:
    case TraitType.LeastFrequent: {
      return (rawConfig as CountDedupedTraitConfig).toSelect != undefined;
    }
    case TraitType.First:
    case TraitType.Last: {
      const config = rawConfig as OrderDedupedTraitConfig;
      return config.toSelect != undefined && config.orderBy != undefined;
    }
    case TraitType.RawSql: {
      const config = rawConfig as RawSqlTraitConfig;
      return (
        config.aggregation != undefined && config.resultingType != undefined
      );
    }
    case TraitType.Formula: {
      const config = rawConfig as FormulaTraitConfig;
      return (
        config.transformation !== undefined &&
        config.resultingType !== undefined
      );
    }
    default:
      return false;
  }
};

export const TRAIT_TYPE_LABELS = {
  [TraitType.Count]: "Count",
  [TraitType.Sum]: "Sum",
  [TraitType.Average]: "Average",
  [TraitType.RawSql]: "Raw SQL",
  [TraitType.LeastFrequent]: "Least frequent",
  [TraitType.MostFrequent]: "Most frequent",
  [TraitType.First]: "First",
  [TraitType.Last]: "Last",
  [TraitType.Formula]: "Formula",
};

export const TRAIT_TYPE_OPTIONS = Object.keys(TRAIT_TYPE_LABELS).map(
  (traitType) => ({
    label: TRAIT_TYPE_LABELS[traitType],
    value: traitType,
  }),
);

export const INLINE_TRAIT_TYPE_OPTIONS = Object.keys(TRAIT_TYPE_LABELS)
  .filter(
    (traitType) =>
      // RawSql and Formula require writing SQL which isn't great UX
      // with the inline trait interface we have in the audience builder.
      // Therefore, we won't support it here. Users can just create these types
      // of traits using the trait builder.
      traitType !== TraitType.RawSql && traitType !== TraitType.Formula,
  )
  .map((traitType) => ({
    label: TRAIT_TYPE_LABELS[traitType],
    value: traitType,
  }));

export const RAW_SQL_COMMON_RES_TYPES = [
  {
    value: ColumnType.Boolean,
    label: "Boolean",
  },
  {
    value: ColumnType.Number,
    label: "Number",
  },
  {
    value: ColumnType.String,
    label: "String",
  },
  {
    value: ColumnType.Timestamp,
    label: "Timestamp",
  },
  {
    value: ColumnType.Date,
    label: "Date",
  },
];

export const RAW_SQL_BIGINT_RES_TYPES = [
  {
    value: ColumnType.BigInt,
    label: "Big Integer",
  },
];

export const RAW_SQL_JSON_ARR_RES_TYPES = [
  {
    value: ColumnType.JsonArrayNumbers,
    label: "JSON Array (Numbers)",
  },
  {
    value: ColumnType.JsonArrayStrings,
    label: "JSON Array (Strings)",
  },
];

const InjectedColumnRegexp = /{{\s*"(.*?)"\s*}}/g;
export const MergedColumnRegexp = /^merged\..+\./;
const TraitIdentifier = "trait.";
const MergeColumnIdentifier = "merged.";

export const getReferencedColumns = (
  traitConfig: FormulaTraitConfig,
  columns: SyncableColumn[],
): FormulaTraitConfig["referencedColumns"] => {
  if (traitConfig?.transformation === undefined) return [];

  let matches: string[] = [];
  let match: string[] | null;

  while (
    (match = InjectedColumnRegexp.exec(traitConfig.transformation)) !== null
  ) {
    match?.[1] && matches.push(match[1]);
  }

  matches = uniq(matches);

  const supportedColumns = columns.filter(({ column_reference }) =>
    ["raw", "related", "transformed"].includes(column_reference.type),
  );

  const result: FormulaTraitConfig["referencedColumns"] = [];

  matches.forEach((match) => {
    const isTraitMatch = match.startsWith(TraitIdentifier);
    const isMergedColumnMatch = match.startsWith(MergeColumnIdentifier);

    const column = supportedColumns.find(({ alias, column_reference }) => {
      // Transformed columns cannot map to other transformed columns
      if (column_reference.type === "transformed") return false;

      // Traits are only mapped to related columns
      if (isTraitMatch && column_reference.type === "related") {
        // Event traits and inline traits are not supported
        if (column_reference.column.type !== "trait") return false;
        const textToMatch = match.replace("trait.", "").replace("", "");
        return (
          alias === textToMatch || column_reference.column.name === textToMatch
        );
      }

      // NOTE: this could lead to a bug if a user adds an alias
      // that has the same name as a different column.
      if (alias === match) return true;

      if (column_reference.type === "raw") {
        return column_reference.name === match;
      }

      if (column_reference.type === "related") {
        // Event traits and inline traits are not supported
        if (column_reference.column.type !== "raw") return false;

        // Merged columns have a different template
        if (isMergedColumnMatch && isMergedColumn(column_reference)) {
          // Needs to find the syncable column and then check the alias there
          const textToMatch = match.replace(MergedColumnRegexp, "");
          return (alias ?? column_reference.column.name) === textToMatch;
        }

        return column_reference.column.name === match;
      }

      return false;
    });

    if (column)
      result.push({
        alias: match,
        column: column.column_reference,
      });
  });

  return result;
};

const hasEmptyConditions = (config: TraitConfig) => {
  return config.conditions?.every(
    (condition) => condition.conditions.length === 0,
  );
};

export const parseTraitConfig = (
  type: TraitType,
  rawConfig: TraitConfig,
): {
  aggregatedColumn?: RawColumn | RelatedColumn;
  orderByColumn?: RawColumn | RelatedColumn;
  aggregation?: string;
  transformation?: string;
  conditions?: TraitCondition[];
} => {
  switch (type) {
    case TraitType.Sum: {
      const config = rawConfig as SumTraitConfig;
      return {
        aggregatedColumn: config.column,
        conditions: config.conditions,
      };
    }
    case TraitType.Average: {
      const config = rawConfig as SumTraitConfig;
      return {
        aggregatedColumn: config.column,
        conditions: config.conditions,
      };
    }
    case TraitType.Count: {
      const config = rawConfig as CountTraitConfig;
      return {
        aggregatedColumn: config.column,
        conditions: config.conditions,
      };
    }
    case TraitType.MostFrequent:
    case TraitType.LeastFrequent: {
      const config = rawConfig as CountDedupedTraitConfig;
      return {
        aggregatedColumn: config.toSelect,
        conditions: config.conditions,
      };
    }
    case TraitType.First:
    case TraitType.Last: {
      const config = rawConfig as OrderDedupedTraitConfig;
      return {
        aggregatedColumn: config.toSelect,
        orderByColumn: config.orderBy,
        conditions: config.conditions,
      };
    }
    case TraitType.RawSql: {
      const config = rawConfig as RawSqlTraitConfig;
      return {
        aggregation: config.aggregation,
        conditions: config.conditions,
      };
    }
    case TraitType.Formula: {
      const config = rawConfig as FormulaTraitConfig;
      return {
        aggregation: config.transformation,
      };
    }
    default:
      exhaustiveCheck(type);
  }
};

export const formatTraitConfig = (
  type: TraitType,
  config: TraitConfig,
  parentModel?: { syncable_columns: SyncableColumn[] },
): TraitConfig => {
  if (type === TraitType.Formula) {
    const formulaConfig = config as FormulaTraitConfig;
    return {
      ...formulaConfig,
      referencedColumns: getReferencedColumns(
        formulaConfig,
        parentModel?.syncable_columns.filter(
          ({ type }) => type !== TraitType.Formula,
        ) ?? [],
      ),
    };
  }

  if (hasEmptyConditions(config)) {
    return {
      ...config,
      conditions: [],
    };
  }

  return config;
};

// TODO(samuel): remove this function once audience builder is upgraded to use 'yup'.
// Have to do this to trigger the validation in the trait conditions
export const validateTraitConfig = async (
  hasValidationErrorsFn: () => boolean,
  toast: (options: any) => void,
) => {
  const validationErrors = hasValidationErrorsFn();

  if (validationErrors) {
    toast({
      id: "trait-validation-error",
      title: "Trait has validation errors",
      message: "Please fix the errors and try again.",
      variant: "error",
    });

    return Promise.reject();
  }

  return Promise.resolve();
};
