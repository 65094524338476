import { Box, Button, Dialog, Row } from "@hightouchio/ui";
import { Link, LinkButton, useNavigate } from "src/router";
import { useSearchParam } from "src/components/routeable-tabs/utils";
import {
  useMigrateToV2AlertsMutation,
  useUpdateWorkspaceMutation,
} from "src/graphql";
import { useUser } from "src/contexts/user-context";

export const MigrateV1AlertsToV2 = () => {
  const [_, setMigrating] = useSearchParam("migrating");
  const { mutateAsync: migrateMutation } = useMigrateToV2AlertsMutation();
  const { mutateAsync: enableAlertingMutation } = useUpdateWorkspaceMutation();
  const navigate = useNavigate();
  const { workspace } = useUser();

  return (
    <Dialog
      title="Almost there! Please watch this video before migrating."
      width={"4xl" as any}
      isOpen
      variant="form"
      actions={
        <Row alignItems="baseline" justifyContent="space-between" w="100%">
          <Box
            onClick={async (event) => {
              await enableAlertingMutation({
                id: workspace?.id,
                input: {
                  alerting_v2_enabled: false,
                },
              });
              event.preventDefault();
              setMigrating(undefined);
            }}
          >
            {/* Bit goofy but it gets us the link styling without navigating on click */}
            <Link href="javascript:undefined">
              Never mind, do not enable beta features
            </Link>
          </Box>
          <Row alignItems="baseline" gap={4}>
            <LinkButton href="/alerting">Continue without migrating</LinkButton>
            <Button
              variant="primary"
              onClick={async () => {
                await migrateMutation({});
                navigate("/alerting");
              }}
            >
              Migrate my existing alerts
            </Button>
          </Row>
        </Row>
      }
      onClose={() => navigate("/alerting")}
    >
      <video
        controls={true}
        src="https://cdn.sanity.io/files/pwmfmi47/production/494785d34ebf8fa1bc7086370f750a9696d21ae3.mp4"
        poster="https://cdn.sanity.io/images/pwmfmi47/production/228a3f8aba24888f9b90ce33a61cc41fd8c469f6-1891x1064.jpg?fm=png"
      ></video>
    </Dialog>
  );
};
