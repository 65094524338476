import { FC, useEffect, useState } from "react";

import {
  Box,
  Column,
  FormField,
  Heading,
  Row,
  Text,
  TextInput,
  useToast,
} from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import Helmet from "react-helmet";
import omit from "lodash/omit";

import { useNavigate } from "src/router";
import { DestinationSelect } from "src/components/destinations/destination-select";
import { DestinationForm } from "src/components/destinations/sync-form";
import { ParentModelSelect } from "src/components/models/parent-model-select";
import { useSchemaObjectCreationPermissions } from "src/components/permission/creation/schema";
import { ScheduleManager } from "src/components/schedule";
import { useScheduleState } from "src/components/schedule/schedule-manager";
import { Schedule, ScheduleType } from "src/components/schedule/types";
import { WizardStep, DeprecatedWizard } from "src/components/wizard";
import { FormkitDestination } from "src/formkit/components/formkit-context";
import {
  CreateSyncTemplateMutationVariables,
  DestinationDefinitionFragment as DestinationDefinition,
  useCreateSyncTemplateMutation,
  useParentModelForSyncTemplateWizardQuery,
} from "src/graphql";
import { SlugResourceType, useResourceSlug } from "src/utils/slug";
import { PageSpinner } from "src/components/loading";

type Config = CreateSyncTemplateMutationVariables["object"]["config"];

export const CreateSyncTemplate: FC = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const { schemaV2 } = useFlags();

  const [destination, setDestination] = useState<
    FormkitDestination | undefined
  >();
  const [destinationDefinition, setDestinationDefinition] = useState<
    DestinationDefinition | undefined
  >();
  const [parentModelId, setParentModelId] = useState<string | undefined>();
  const [step, setStep] = useState<number>(0);
  const [config, setConfig] = useState<Config | undefined>();
  const { validateSchedule } = useScheduleState("sync");
  const [schedule, setSchedule] = useState<Schedule | null>({
    type: ScheduleType.MANUAL,
  });
  const [name, setName] = useState("");
  const { getSlug } = useResourceSlug(SlugResourceType.SyncTemplates);

  const parentModelForQueryBuilderQuery =
    useParentModelForSyncTemplateWizardQuery(
      {
        parentModelId: parentModelId ?? "",
      },
      {
        enabled: Boolean(parentModelId),
        select: (data) => data.segments_by_pk,
      },
    );

  const parentModelDataLoading = parentModelForQueryBuilderQuery.isLoading;
  const parentModel = parentModelForQueryBuilderQuery.data;
  const source = parentModel?.connection;

  const { mutateAsync: createSyncTemplate, isLoading: creating } =
    useCreateSyncTemplateMutation();

  useEffect(() => {
    if (parentModel?.name && destination?.name) {
      setName(`${parentModel.name} to ${destination.name}`);
    }
  }, [parentModel?.name, destination?.name]);

  const create = async () => {
    if (!destination || !parentModel) {
      return;
    }

    const slug = await getSlug(name);

    const { overrideConfig, ...restConfig } = config;

    createSyncTemplate({
      object: {
        slug,
        name,
        config: {
          ...restConfig,
          configVersion: destinationDefinition?.configVersion,
        },
        override_config: overrideConfig,
        destination_id: destination.id,
        segment_id: parentModel.id,
        schedule: schedule?.type === "manual" ? null : schedule,
        // TODO: (calum) do we need isStreaming here? (not sure what "sync templates" are?)
      },
    });

    toast({
      id: "create-sync-template",
      title: "Sync template was created",
      variant: "success",
    });

    navigate(
      schemaV2
        ? `/schema-v2/settings/sync-templates`
        : "/schema/sync-templates",
    );
  };

  const { parentModelFilter } = useSchemaObjectCreationPermissions();

  const steps: WizardStep[] = [
    {
      title: "Select parent model",
      continue: "Click on a parent model to continue",
      header: (
        <Column gap={2}>
          <Heading>Parent models</Heading>
          <Text color="text.secondary">
            Only audiences that are built on the selected parent model can be
            synced with this template.
          </Text>
        </Column>
      ),
      render: () => (
        <ParentModelSelect
          isOptionAvailable={parentModelFilter}
          onSelect={(model) => {
            setParentModelId(model.id);
            setStep(1);
          }}
        />
      ),
    },
    {
      title: "Select destination",
      continue: "Click on a destination to continue",
      header: parentModelDataLoading ? null : (
        <Column>
          <Heading>Select a destination</Heading>
        </Column>
      ),
      render: () =>
        parentModelDataLoading ? (
          <PageSpinner />
        ) : (
          <DestinationSelect
            allowedDestinations={source?.definition?.allowedDestinations}
            onSelect={(destination) => {
              setDestination(destination);
              setDestinationDefinition(destination.definition);
              setStep(2);
            }}
          />
        ),
    },
    {
      title: "Configure sync template",
      continueProps: { form: "destination-form", type: "submit" },
      onContinue: () => {},
      header: destination && destinationDefinition && (
        <Row alignItems="center" gap={4}>
          <Box
            as="img"
            alt={destinationDefinition.name}
            src={destinationDefinition.icon}
            width="32px"
            objectFit="contain"
          />
          <Heading>
            Configure sync template for{" "}
            {destination.name || destinationDefinition.name}
          </Heading>
        </Row>
      ),
      bg: "base.lightBackground",
      render: () => (
        <>
          {destination && destinationDefinition && parentModel && (
            <DestinationForm
              testPermission={{
                v1: { resource: "sync_template", grant: "create" },
                v2: {
                  resource: "model",
                  grant: "can_update",
                  id: parentModel.id,
                },
              }}
              permission={{
                v1: { resource: "sync_template", grant: "create" },
                v2: {
                  resource: "model",
                  grant: "can_update",
                  id: parentModel.id,
                },
              }}
              hideSave
              destination={destination}
              destinationDefinition={destinationDefinition}
              model={parentModel}
              slug={destinationDefinition.type}
              sourceDefinition={source?.definition}
              syncConfig={omit(config, "overrideConfig")}
              overrideConfig={config?.overrideConfig}
              onSubmit={(config) => {
                setConfig(config);
                setStep(step + 1);
                return Promise.resolve();
              }}
            />
          )}
        </>
      ),
    },
    {
      title: "Finalize sync template",
      disabled: !name || !validateSchedule(schedule),
      submitting: creating,
      header: <Heading>Finalize settings for this sync template</Heading>,
      render: () => {
        return (
          <Column gap={8} maxWidth="600px">
            <FormField label="Template name">
              <TextInput
                placeholder={destination?.name ?? undefined}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FormField>

            <ScheduleManager schedule={schedule} setSchedule={setSchedule} />
          </Column>
        );
      },
    },
  ];

  return (
    <>
      <Helmet>
        <title>New sync template</title>
      </Helmet>

      <DeprecatedWizard
        setStep={setStep}
        step={step}
        steps={steps}
        title="New sync template"
        onCancel={() => {
          navigate(
            schemaV2
              ? "/schema-v2/settings/sync-templates"
              : "/schema/sync-templates",
          );
        }}
        onSubmit={create}
      />
    </>
  );
};
