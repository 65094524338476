import { FC, ReactNode, useCallback, useMemo } from "react";

import { Completion } from "@codemirror/autocomplete";
import {
  schemaCompletionSource,
  SQLConfig,
  StandardSQL,
} from "@codemirror/lang-sql";
import { LanguageSupport } from "@codemirror/language";
import {
  Button,
  ButtonGroup,
  Column,
  Row,
  SparkleIcon,
  Text,
} from "@hightouchio/ui";
import { format, FormatFnOptions as SqlFormatterOptions } from "sql-formatter";

import { Editor, Props as EditorProps } from "src/components/editor";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import {
  Maybe,
  SyncableColumn,
  useSourceObjectDefinitionsQuery,
} from "src/graphql";
import { UnsavedValue } from "src/hooks/use-unsaved-value";
import {
  isMergedColumn,
  isRelatedColumn,
  isTraitColumn,
  Relationship,
  Column as SyncableColumnReference,
  TraitDefinition,
} from "src/types/visual";

import { getTraitPropertyType } from "../explore/visual/utils";
import { getAppliedText } from "./utils";

const formatterLanguageBySourceType: Record<
  string,
  SqlFormatterOptions["language"]
> = {
  athena: "hive",
  bigquery: "bigquery",
  mssql: "tsql",
  mysql: "mysql",
  postgres: "postgresql",
  redshift: "redshift",
  // What is the best default for these?
  // postgresql seems to be more correct than sql
  // 1 known issue is 'sql' causes || to convert to | | which is invalid
  snowflake: "postgresql",
  trino: "postgresql",
  clickhouse: "postgresql",
  databricks: "postgresql",
  looker: "postgresql",
  metabase: "postgresql",
};

export function formatSourceSql(
  source: {
    id: string;
    type: string;
    name: string;
    definition: { name: string; icon: string; isSampleDataSource: boolean };
  },
  sql: string,
): string {
  const formatterLanguage = source
    ? formatterLanguageBySourceType[source.type]
    : undefined;
  return format(sql, {
    keywordCase: "upper",
    language: formatterLanguage,
  });
}

interface Props extends Omit<EditorProps, "language"> {
  /**
   * Line number to highlight with a red background, in case there's an error
   */
  highlightErroredLine?: number;

  /**
   * Source to show database schema autocomplete for
   */
  source:
    | Maybe<{
        id: string;
        type: string;
        name: string;
        definition: { name: string; icon: string; isSampleDataSource: boolean };
        columns?: never;
        traits?: never;
        relationships?: never;
      }>
    | {
        columns: SyncableColumn[];
        traits: TraitDefinition[];
        relationships: Relationship[];
        id?: never;
        type?: never;
        name?: never;
        definition?: never;
      }
    | undefined;

  /**
   * Result of `useUnsavedValue` hook to support storing drafts in local storage
   */
  unsavedValue?: UnsavedValue<string>;

  /**
   * Additional actions to show in the editor header
   */
  actions?: ReactNode;

  /**
   * Whether the editor should show the beautify button
   * @default true
   */
  isBeautifyable?: boolean;

  /**
   * Width of the editor
   *  - Defaults to 100%
   */
  width?: string;
}

export const SqlEditor: FC<Props> = ({
  highlightErroredLine: highlightErroredLineNumber,
  source,
  value,
  unsavedValue,
  onChange,
  actions,
  isBeautifyable = true,
  width = "100%",
  minHeight = "150px",
  ...props
}) => {
  const { data } = useSourceObjectDefinitionsQuery(
    { sourceId: String(source?.id) },
    {
      enabled: Boolean(source?.id),
    },
  );

  const config = useMemo<SQLConfig | undefined>(() => {
    const columns = source?.columns;
    const traits = source?.traits;

    if (!source && !data) {
      return undefined;
    }

    if (columns) {
      return {
        schema: columns
          .filter(
            (column) =>
              column.column_reference.type !== "splitTest" &&
              column.column_reference.type !== "transformed",
          )
          .map((column) => {
            const columnReference =
              column.column_reference as SyncableColumnReference;
            const mergedColumn = isMergedColumn(columnReference);

            const traitDefinitionId =
              isRelatedColumn(columnReference) &&
              isTraitColumn(columnReference.column)
                ? columnReference.column.traitDefinitionId
                : undefined;
            const trait =
              traitDefinitionId &&
              traits?.find(({ id }) => id === traitDefinitionId);

            let type = column.custom_type ?? column.type;

            // Trait column type must be determined from the trait definition
            if (trait) {
              type = getTraitPropertyType(trait);
            }

            return {
              label: column.alias ?? column.name,
              apply: `{{"${getAppliedText({
                columnReference: column.column_reference,
                alias: column.alias,
                relationshipName: mergedColumn ? column.model_name : null,
              })}"}}`,
              detail: mergedColumn
                ? `Merged from ${column.model_name}`
                : undefined,
              type,
            };
          }),
      } as SQLConfig;
    }

    if (!data) {
      return undefined;
    }

    const schema: Record<string, Completion[]> = {};
    const tables: Completion[] = [];

    for (const objectDefinition of data.object_definitions) {
      const schemaName = objectDefinition.object_definition_group?.name;

      const tableName = schemaName
        ? `${schemaName}.${objectDefinition.name}`
        : objectDefinition.name;

      tables.push({
        label: tableName,
        type: "table",
      });

      schema[tableName] = [];

      for (const objectSchema of objectDefinition.object_schemas) {
        schema[tableName]?.push({
          label: objectSchema.key,
          type: "column",
        });
      }
    }

    return {
      schema,
      tables,
    };
  }, [source, data]);

  const language = useMemo(() => {
    if (!config || config?.tables?.length === 1000) {
      return new LanguageSupport(StandardSQL.language);
    }

    return new LanguageSupport(StandardSQL.language, [
      StandardSQL.language.data.of({
        autocomplete: schemaCompletionSource({
          ...config,
          defaultSchema: source?.columns ? "" : "public",
        }),
      }),
    ]);
  }, [config, source?.columns]);

  const changeValue = useCallback(
    (value: string) => {
      if (unsavedValue) {
        unsavedValue.set(value);
      }

      if (typeof onChange === "function") {
        onChange(value);
      }
    },
    [unsavedValue, onChange],
  );

  const restoreValue = useCallback(() => {
    if (!unsavedValue) {
      return;
    }

    if (
      typeof onChange === "function" &&
      typeof unsavedValue.value === "string"
    ) {
      onChange(unsavedValue.value);
    }

    unsavedValue.restore();
  }, [unsavedValue, onChange]);

  const formatterLanguage =
    source && source.type
      ? formatterLanguageBySourceType[source.type]
      : undefined;

  const beautify = useCallback(() => {
    if (!value) return;

    const formattedSql = format(value, {
      keywordCase: "upper",
      language: formatterLanguage,
    })
      // The format function often capitalizes the Handlebars `column` expression.
      // We need it to be lowercase so that it can be parsed correctly.
      .replace(/{{\s*COLUMN/g, "{{column");

    changeValue(formattedSql);
  }, [value, formatterLanguage, changeValue]);

  return (
    <Column
      width={width}
      border="1px solid"
      borderColor="base.border"
      borderRadius="md"
      overflow="hidden"
      minHeight={props.readOnly ? "250px" : undefined}
      flex={1}
    >
      {!source?.columns && (
        <Row
          align="center"
          px={4}
          height="64px"
          borderBottom="1px"
          borderColor="base.border"
          gap={4}
          justify="space-between"
          flexShrink={0}
        >
          <Row align="center" gap={2}>
            {source?.definition && (
              <IntegrationIcon
                src={source.definition.icon}
                name={source.definition.name}
              />
            )}
            <Text fontWeight="medium" size="lg">
              {source?.name ?? "Private source"}
            </Text>
          </Row>
          <ButtonGroup>
            {isBeautifyable && formatterLanguage && (
              <Button isDisabled={!value} onClick={beautify} icon={SparkleIcon}>
                Beautify
              </Button>
            )}
            {actions}
          </ButtonGroup>
        </Row>
      )}

      {unsavedValue?.value && (
        <Row
          border="1px"
          borderColor="base.border"
          p={2}
          bg="warning.background"
          align="center"
          justify="space-between"
        >
          <Text>You have unsaved changes from last session.</Text>
          <ButtonGroup>
            <Button onClick={unsavedValue.clear}>Dismiss</Button>
            <Button onClick={restoreValue}>Restore</Button>
          </ButtonGroup>
        </Row>
      )}

      <Editor
        minHeight={minHeight}
        highlightErroredLine={highlightErroredLineNumber}
        language={language}
        value={value}
        onChange={onChange}
        {...props}
      />
    </Column>
  );
};
