import { ReactElement } from "react";

import { FormField, Checkbox, TextInput, Select } from "@hightouchio/ui";
import * as Yup from "yup";

import {
  CustomQuery,
  CustomQueryFormProps,
  CustomQueryViewProps,
} from "./custom-query";

/**
 * @param [opts]
 * @param [opts.objectLabel="object"] What to call an object ("object", "S3 object", "file", ...)
 * @param [opts.enableUseLastModified=false] Whether to enable the "Use last modified object" field
 */
export default function getUploadForm(opts?: {
  objectLabel?: string;
  enableUseLastModified?: boolean;
}) {
  const { objectLabel = "object", enableUseLastModified = false } = opts ?? {};

  const PATH_LABEL_EDIT = `Relative path to ${objectLabel} inside bucket`;
  const PATH_LABEL_VIEW = "Object path";
  const PATH_DESCRIPTION = `The ${objectLabel} must be a CSV file (with header row) or a JSON file.`;

  const USE_LAST_MODIFIED_LABEL = `Use last modified ${objectLabel} at path`;
  const USE_LAST_MODIFIED_DESCRIPTION =
    `If checked, Hightouch uses the last modified ${objectLabel} at the given ` +
    `path. If you want to search the entire bucket, leave the path empty.`;

  const FIELD_SEPARATOR_LABEL_EDIT = "Custom separator (CSV only)";
  const FIELD_SEPARATOR_LABEL_VIEW = "CSV separator";
  const FIELD_SEPARATOR_DESCRIPTION = "Field separator for CSV files";

  const FILE_TYPE_LABEL = "File type";
  const FILE_TYPE_DESCRIPTION = "File type (optional, defaults to extension)";
  const FILE_TYPE_OPTIONS = [
    { label: "Automatic", value: "automatic" },
    { label: "CSV", value: "csv" },
    { label: "JSON", value: "json" },
    { label: "NDJSON", value: "ndjson" },
    { label: "Parquet", value: "parquet" },
  ];

  function QueryForm({
    query,
    onChange,
  }: Readonly<CustomQueryFormProps>): ReactElement<any, any> {
    return (
      <>
        <FormField description={PATH_DESCRIPTION} label={PATH_LABEL_EDIT}>
          <TextInput
            placeholder="file.csv"
            value={query?.path ?? ""}
            onChange={(event) =>
              onChange({
                ...query,
                type: "string",
                path: event.target.value,
              })
            }
          />
        </FormField>
        {enableUseLastModified && (
          <FormField
            description={USE_LAST_MODIFIED_DESCRIPTION}
            label={USE_LAST_MODIFIED_LABEL}
          >
            <Checkbox
              label={USE_LAST_MODIFIED_LABEL}
              isChecked={Boolean(query?.useLastModified)}
              onChange={(event) =>
                onChange({ ...query, useLastModified: event.target.checked })
              }
            />
          </FormField>
        )}
        <FormField description={FILE_TYPE_DESCRIPTION} label={FILE_TYPE_LABEL}>
          <Select
            options={FILE_TYPE_OPTIONS}
            placeholder="Automatic"
            value={query?.fileType ?? "automatic"}
            onChange={(value) =>
              onChange({
                ...query,
                type: "string",
                fileType: value ?? "automatic",
              })
            }
          />
        </FormField>
        <FormField
          description={FIELD_SEPARATOR_DESCRIPTION}
          label={FIELD_SEPARATOR_LABEL_EDIT}
        >
          <TextInput
            placeholder=","
            value={query?.fieldSeparator ?? ""}
            onChange={(event) =>
              onChange({
                ...query,
                type: "string",
                fieldSeparator: event.target.value,
              })
            }
          />
        </FormField>
      </>
    );
  }

  function QueryView(
    props: Readonly<CustomQueryViewProps>,
  ): ReactElement<any, any> {
    return (
      <>
        <FormField label={PATH_LABEL_VIEW}>{props.query.path}</FormField>
        <FormField label={FIELD_SEPARATOR_LABEL_VIEW}>
          {props.query.fieldSeparator || ","}
        </FormField>
        <FormField label={FILE_TYPE_LABEL}>
          {props.query.fileType?.toUpperCase() || "Automatic"}
        </FormField>
        {enableUseLastModified && (
          <FormField
            description={USE_LAST_MODIFIED_DESCRIPTION}
            label={USE_LAST_MODIFIED_LABEL}
          >
            <Checkbox
              isDisabled
              label={USE_LAST_MODIFIED_LABEL}
              isChecked={Boolean(props.query.useLastModified)}
              onChange={() => null}
            />
          </FormField>
        )}
      </>
    );
  }

  const querySchema = Yup.lazy<CustomQuery | undefined>((_) => {
    if (enableUseLastModified) {
      return Yup.object().shape({
        type: Yup.string().required(),
        useLastModified: Yup.boolean().required(),
        // path is not required if useLastModified is true
        path: Yup.string().when("useLastModified", {
          is: true,
          then: Yup.string().notRequired(),
          else: Yup.string().required(),
        }),
      });
    }
    return Yup.object().shape({
      type: Yup.string().required(),
      path: Yup.string().required(),
    });
  });

  return { QueryForm, QueryView, querySchema };
}
