import { DraftBadge } from "src/components/drafts/draft-badge";
import { SyncHealthBadge } from "src/components/syncs/sync-health-badge";
import { SyncRunStatusBadge } from "src/components/syncs/sync-run-status-badge";
import { useUser } from "src/contexts/user-context";
import { syncHealthOrUnknown, SyncHealthStatus } from "src/utils/syncs";
import { Sync } from "src/pages/syncs/sync/utils/types";

type Props = {
  status: string | null;
  health: string | null;
  syncRequests: Sync["sync_requests"];
  schedulePaused: boolean;
  lastRunAt: string | null;
  isInitialDraft: boolean;
};

export const SyncHealthOrLastStatusBadge = ({
  status,
  health,
  syncRequests,
  schedulePaused,
  lastRunAt,
  isInitialDraft,
}: Props) => {
  const { workspace } = useUser();

  if (workspace?.alerting_v2_enabled) {
    return (
      <SyncHealthBadge
        health={syncHealthOrUnknown(
          lastRunAt ? health : SyncHealthStatus.Pending,
        )}
        syncEnabled={!schedulePaused}
      />
    );
  }

  if (isInitialDraft) {
    return <DraftBadge />;
  }

  const syncRequest = syncRequests?.[0];
  const request = syncRequest ? syncRequest : { status_computed: status };

  return <SyncRunStatusBadge request={request} status={status} />;
};
