import { FC, Fragment, ReactNode } from "react";

import { TraitConfig, TraitType } from "@hightouch/lib/query/visual/types";
import { Code, Column, Row, Text } from "@hightouchio/ui";

import { IconBox } from "src/components/icon-box";

import {
  EventColumn,
  RelationColumn,
} from "src/components/explore/filter-popover/constants";
import { PropertyConditions } from "src/components/explore/visual/property-conditions";
import { getPropertyNameFromColumn } from "src/components/explore/visual/utils";

import { parseTraitConfig, TRAIT_TYPE_LABELS } from "./utils";

type Props = {
  type: TraitType;
  config: TraitConfig;
  model:
    | {
        event: { timestamp_column: string } | null | undefined;
        name: string;
      }
    | undefined;
  hideConditions?: boolean;
};

export const CalculationSummary: FC<Readonly<Props>> = ({
  type,
  config: rawConfig,
  model,
  hideConditions,
}) => {
  const {
    aggregatedColumn,
    orderByColumn,
    aggregation,
    conditions = [],
  } = parseTraitConfig(type, rawConfig);

  const isEventModel = model && model.event;

  const ModelWithIcon = () => (
    <Row gap={1} alignItems="center" ml={0.5}>
      <IconBox
        bg={isEventModel ? EventColumn.color : RelationColumn.color}
        boxSize={4}
        icon={isEventModel ? EventColumn.icon : RelationColumn.icon}
        iconSize={3}
      />
      <BoldText>{model ? model.name : "--"}</BoldText>
    </Row>
  );

  const summary: {
    value: string | undefined | null;
    Component: FC<{ children: ReactNode }>;
  }[] = [];

  if (type === TraitType.RawSql || type === TraitType.Formula) {
    summary.push({ value: aggregation, Component: Code });
  } else {
    summary.push(
      {
        value: TRAIT_TYPE_LABELS[type],
        Component: BoldText,
      },
      {
        value: "of",
        Component: Text,
      },
      {
        value: getPropertyNameFromColumn(aggregatedColumn),
        Component: BoldText,
      },
      {
        value: orderByColumn ? "ordered by" : null,
        Component: orderByColumn ? Text : Fragment,
      },
      {
        value: orderByColumn ? getPropertyNameFromColumn(orderByColumn) : null,
        Component: orderByColumn ? BoldText : Fragment,
      },
    );
  }

  if (model) {
    // Formula traits do not have a single related model, so only include
    // this content for non-formula traits.
    summary.push(
      {
        value: isEventModel ? "of events in" : "of rows in",
        Component: Text as FC,
      },
      {
        value: null,
        Component: ModelWithIcon,
      },
    );
  }

  return (
    <Column gap={4}>
      <Row gap={1} flexWrap="wrap">
        {summary.map(({ value, Component }, index) => (
          <Component key={`${value}-${index}`}>{value}</Component>
        ))}
      </Row>
      {!hideConditions && conditions.length > 0 && (
        <Column pl={8} gap={4}>
          <PropertyConditions conditions={conditions} />
        </Column>
      )}
    </Column>
  );
};

const BoldText = ({ children }: { children: ReactNode }) => (
  <Text color="text.primary" fontWeight="medium">
    {children}
  </Text>
);
