import { FC, useRef } from "react";

import { Column } from "@hightouchio/ui";

import grain from "src/assets/backgrounds/grain.png";

import { WorkspaceSelector } from "src/components/layout/nav/workspace-selector";
import { NAV_WIDTHS_PER_BREAKPOINT } from "src/components/layout/constants";
import { Activate } from "./sections/activate";
import { CustomerStudio } from "./sections/customer-studio";
import { Integrations } from "./sections/integrations";
import { EventCollection } from "./sections/event-collection";
import { Footer } from "./sections/footer";
import { Logo } from "./logo";
import { Intelligence } from "./sections/intelligence";
import { useActiveSection } from "./utils";
import { GetStarted } from "./sections/get-started";
import { IdentityResolution } from "./sections/identity-resolution";
import { DecisionEngines } from "./sections/decision-engine";

export const Nav: FC = () => {
  const navRef = useRef<HTMLDivElement>(null);
  const activeState = useActiveSection();

  return (
    <>
      <Column
        ref={navRef}
        alignItems="center"
        background={`url(${grain}), linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), linear-gradient(175.93deg, #044747 0%, #065655 11.46%, #002B2E 82.29%)`}
        height="100vh"
        overflowX="hidden"
        position="sticky"
        top={0}
        transition="100ms width ease-in-out"
        width={NAV_WIDTHS_PER_BREAKPOINT}
        flexShrink={0}
        sx={{
          colorScheme: "dark",
          a: {
            display: "block",
            width: "100%",
          },
        }}
      >
        <Column width="100%" gap={4} py={4}>
          <Logo />
          <WorkspaceSelector />
        </Column>

        <Column
          flex={1}
          justifyContent="space-between"
          minHeight={0}
          width="100%"
        >
          <Column px={2} gap={2}>
            <GetStarted />
            <Activate {...activeState} />
            <CustomerStudio {...activeState} />
            <Intelligence {...activeState} />
            <DecisionEngines {...activeState} />
            <IdentityResolution />
            <Integrations {...activeState} />
            <EventCollection {...activeState} />
          </Column>
          <Footer {...activeState} />
        </Column>
      </Column>
    </>
  );
};
