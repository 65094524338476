import { FC, ReactNode, useMemo, useState } from "react";

import {
  Box,
  Checkbox,
  Column,
  Combobox,
  InformationIcon,
  NumberInput,
  Row,
  Select,
  TagInput,
  Text,
  TextInput,
  Tooltip,
  useToast,
} from "@hightouchio/ui";
import { uniqBy } from "lodash";
import Papa from "papaparse";
import pluralize from "pluralize";
import Dropzone, { FileRejection } from "react-dropzone";

import { useConditionSelect } from "src/components/explore/filter-popover/use-condition-select";
import { getModelName } from "src/components/explore/filter-popover/utils";
import {
  BooleanOperator,
  ColumnDateTypes,
  ColumnType,
  FilterableColumn,
  MultiValueColumnTypes,
  MultiValueOperators,
  NumberOperator,
  OperatorsWithoutValue,
  PercentileOperators,
  PropertyCondition,
} from "src/types/visual";
import { abbreviateNumber } from "src/utils/numbers";

import { ErrorMessage } from "./error-message";
import { NumberRangeInput } from "./number-range-input";
import { TimestampInput } from "./timestamp-input";
import { RequiredParentModelFieldsForQueryBuilder } from "src/components/audiences/types";

export type PropertyInputProps = {
  condition: PropertyCondition;
  onChange: (updates: Partial<PropertyCondition>) => void;
  loading?: boolean;
  suggestions:
    | Array<{ value: string | number | null; count: number | undefined }>
    | undefined
    | null;
  error?: ReactNode;
  columns?: FilterableColumn[] | undefined;
  parent?: RequiredParentModelFieldsForQueryBuilder | undefined;
  allowMultiValue?: boolean;
  allowPercentile?: boolean;
};

export const PropertyInput: FC<Readonly<PropertyInputProps>> = ({
  condition,
  error,
  loading,
  suggestions,
  onChange,
  columns,
  parent,
  allowMultiValue = true,
  allowPercentile = true,
}) => {
  const { toast } = useToast();

  const { selectedColumn } = useConditionSelect({ condition });
  const modelName = getModelName(selectedColumn);

  const suggestionOptions = useMemo(
    () =>
      suggestions?.map(({ value, count }) => ({
        label: String(value),
        description: count ? `(${abbreviateNumber(count)})` : undefined,
        value,
      })),
    [suggestions],
  );

  const [createdOptions, setCreatedOptions] = useState<
    Array<{ label: string; value: string; description?: string }>
  >([]);

  const multiSelectOptions = useMemo(
    () =>
      uniqBy(
        [
          ...createdOptions,
          ...(suggestionOptions || []),
          ...(condition.value
            ? (Array.isArray(condition.value)
                ? condition.value
                : [condition.value]
              ).map((value) => ({ label: value, value }))
            : []),
        ],
        "value",
      ),
    [createdOptions, suggestionOptions, condition.value],
  );

  const multiSelectSuggestionsTip = useMemo(() => {
    return `${
      multiSelectOptions.length
        ? `${multiSelectOptions.length} ${pluralize(
            "value",
            multiSelectOptions.length,
          )} found in ${modelName ?? "model"}, ordered by prevalence. `
        : ""
    }Type to create a new value.`;
  }, [multiSelectOptions.length, modelName]);

  const suggestionsTip = useMemo(() => {
    return `${
      suggestionOptions?.length
        ? `${suggestionOptions.length} ${pluralize(
            "value",
            suggestionOptions.length,
          )} found in ${modelName ?? "model"}, ordered by prevalence. `
        : ""
    }Type to create a new value.`;
  }, [suggestionOptions?.length, modelName]);

  if (OperatorsWithoutValue.includes(condition.operator)) {
    return null;
  }

  if (
    MultiValueOperators.includes(condition.operator) &&
    condition.propertyType &&
    MultiValueColumnTypes.includes(condition.propertyType) &&
    allowMultiValue
  ) {
    const handleFileDrop = (
      acceptedFiles: File[],
      fileRejections: FileRejection[],
    ) => {
      if (fileRejections.length > 0) {
        toast({
          id: "file-upload",
          title:
            'Each file must be in ".csv" or ".txt" format and uploaded one at a time',
          variant: "error",
        });
      }

      if (acceptedFiles.length === 1) {
        const file = acceptedFiles[0];

        if (file) {
          Papa.parse(file, {
            complete: (results) => {
              const values = results.data
                .flat()
                .filter(Boolean)
                .map((value) => (value as string).trim());

              toast({
                id: "file-upload",
                title: `Values from '${file.name}' were added`,
                variant: "success",
              });

              setCreatedOptions([
                ...createdOptions,
                ...values.map((value) => ({ label: value, value })),
              ]);
              onChange({ value: [...(condition.value || []), ...values] });
            },
          });
        }
      }
    };

    return (
      <Dropzone
        accept={[".csv", ".txt"]}
        maxFiles={1}
        multiple={false}
        noClick={true}
        noKeyboard={true}
        onDrop={handleFileDrop}
      >
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <Column>
              <Row align="start" gap={2} {...getRootProps()}>
                <TagInput
                  removePortal
                  {...getInputProps()}
                  supportsCreatableOptions
                  size="md"
                  width="md"
                  isLoading={loading}
                  isInvalid={Boolean(error)}
                  options={multiSelectOptions}
                  placeholder={
                    isDragActive
                      ? "Upload your file here..."
                      : "Add values or drag/drop a text file..."
                  }
                  value={
                    !Array.isArray(condition.value) && condition.value
                      ? [condition.value]
                      : (condition.value ?? [])
                  }
                  onChange={(options) => {
                    const values = options.map((option) =>
                      typeof option === "string" ? option.trim() : option,
                    );
                    onChange({ value: values });
                  }}
                  onCreateOption={(createdValue) => {
                    const newValue = Array.isArray(condition.value)
                      ? condition.value
                      : condition.value === null
                        ? []
                        : [condition.value];

                    setCreatedOptions([
                      ...createdOptions,
                      {
                        label: createdValue,
                        value: createdValue,
                      },
                    ]);
                    onChange({ value: [...newValue, createdValue] });
                  }}
                />

                <Tooltip message={multiSelectSuggestionsTip}>
                  <Text size="lg" mt={1}>
                    <InformationIcon />
                  </Text>
                </Tooltip>
              </Row>

              {error && <ErrorMessage>{error}</ErrorMessage>}
            </Column>
          );
        }}
      </Dropzone>
    );
  }

  if (
    condition.propertyType === ColumnType.Number &&
    condition.operator === NumberOperator.Between
  ) {
    return (
      <NumberRangeInput
        condition={condition}
        error={error}
        suggestionsLoading={loading}
        suggestionOptions={suggestionOptions ?? []}
        suggestionsTip={suggestionsTip}
        onChange={onChange}
      />
    );
  }

  if (
    condition.propertyType &&
    !ColumnDateTypes.includes(condition.propertyType) &&
    suggestions
  ) {
    return (
      <Column>
        <Row gap={2} align="start">
          <Combobox
            removePortal
            isClearable
            supportsCreatableOptions
            isLoading={loading}
            isInvalid={Boolean(error)}
            optionLabel={(option) => option.label}
            options={suggestionOptions ?? []}
            placeholder="search..."
            value={condition.value}
            onChange={(value) => onChange({ value })}
            onCreateOption={(value) => onChange({ value })}
          />

          <Tooltip message={suggestionsTip}>
            <Text size="lg" mt={1}>
              <InformationIcon />
            </Text>
          </Tooltip>
        </Row>
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </Column>
    );
  }

  if (condition.propertyType === ColumnType.Boolean) {
    return (
      <Column>
        <Select
          removePortal
          isInvalid={Boolean(error)}
          options={[
            {
              value: true,
              label: "True",
            },
            {
              value: false,
              label: "False",
            },
          ]}
          isLoading={loading}
          placeholder="True / False"
          value={condition.value}
          width="auto"
          onChange={(value) => {
            onChange({
              operator: BooleanOperator.Equals,
              value,
            });
          }}
        />
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </Column>
    );
  }

  if (
    condition.propertyType &&
    ColumnDateTypes.includes(condition.propertyType)
  ) {
    return (
      <TimestampInput
        condition={condition}
        error={error}
        hideTime={condition.propertyType === ColumnType.Date}
        onChange={onChange}
        columns={columns}
        parent={parent}
      />
    );
  }

  return (
    <Box display="flex" gap={2}>
      <Column>
        {condition.propertyType &&
        [ColumnType.Number, ColumnType.JsonArrayNumbers].includes(
          condition.propertyType,
        ) ? (
          <NumberInput
            isInvalid={Boolean(error)}
            value={condition.value ?? undefined}
            onChange={(value) => {
              onChange({ value });
            }}
            placeholder="Enter a number..."
          />
        ) : (
          <TextInput
            isInvalid={Boolean(error)}
            value={condition.value}
            onChange={(event) => {
              onChange({ value: event.target.value });
            }}
          />
        )}
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </Column>
      {condition.propertyType === ColumnType.Number &&
        PercentileOperators.includes(condition.operator) &&
        allowPercentile && (
          <Box py="6px">
            <Checkbox
              label="Percentile"
              isChecked={Boolean(condition.propertyOptions?.percentile)}
              onChange={(event) => {
                onChange({
                  propertyOptions: {
                    ...condition.propertyOptions,
                    percentile: event.target.checked,
                  },
                });
              }}
            />
          </Box>
        )}
    </Box>
  );
};
