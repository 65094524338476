import { useEffect, useMemo } from "react";

import immutableUpdate from "immutability-helper";
import { UseFormSetValue } from "react-hook-form";

import { useHightouchForm } from "src/components/form";
import { AnalyticsFrequency, exhaustiveCheck } from "src/types/visual";

import {
  AnalyticsActions,
  ChartFormState,
  GraphType,
  GroupByColumn,
  TimeOptions,
} from "src/pages/analytics/types";
import {
  DEFAULT_GROUP_BY_COLUMNS,
  defaultFormState,
  defaultFunnelChartState,
  defaultPerformanceChartState,
} from "./constants";

type UseChartStateArgs = {
  useUrlState?: boolean;
  parentModelId?: number;
};

export const useChartState = ({
  useUrlState,
  parentModelId,
}: UseChartStateArgs) => {
  const form = useHightouchForm({
    // not using submit in this product.
    // instead, the polling happens when form is valid
    onSubmit: () => Promise.resolve(),
    values: { ...defaultFormState, parentModelId },
    queryKey: useUrlState ? "data" : undefined,
  });

  const setValue: UseFormSetValue<ChartFormState> = (name, value) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - Circular reference problem with Column types
    form.setValue(name, value, { shouldDirty: true });

  const selectParentModel = (value: number, modelName: string | undefined) => {
    // This value is a bigint. Must be a number, but it's typed as a string
    form.reset({
      ...defaultFormState,
      graphType: form.getValues("graphType"),
      parentModelId: value,
      selectedAudiences: modelName
        ? [
            {
              id: value,
              name: modelName,
              splits: [],
            },
          ]
        : defaultFormState.selectedAudiences,
    });
  };

  const addGroupByColumn = (
    column: GroupByColumn | undefined,
    index: number,
  ) => {
    const groupByColumns = form.getValues("groupByColumns") ?? [];
    setValue(
      "groupByColumns",
      immutableUpdate(groupByColumns, { [index]: { $set: column } }),
    );
  };

  // TODO: combine these two functions into one
  const addGroupByColumns = (
    columns: (GroupByColumn | undefined)[],
    fromIndex: number,
  ) => {
    const groupByColumns = form.getValues("groupByColumns") ?? [];

    const prevGroupBys = groupByColumns.slice(0, fromIndex);
    const groupByUpdate = prevGroupBys.concat(columns);

    setValue("groupByColumns", groupByUpdate);
  };

  const removeGroupByColumns = (startIndex: number, endIndex?: number) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - Circular reference problem with Column types
    const groupByColumns = form.getValues("groupByColumns") ?? [];
    const newGroupByColumns = groupByColumns
      .slice(0, startIndex)
      .concat(groupByColumns.slice((endIndex ?? startIndex) + 1));

    setValue(
      "groupByColumns",
      newGroupByColumns.length > 0
        ? newGroupByColumns
        : DEFAULT_GROUP_BY_COLUMNS,
    );
  };

  const resetView = (view: GraphType) => {
    switch (view) {
      // Uses the same state as performance
      case GraphType.Breakdown:
      case GraphType.Performance:
        form.reset({
          ...form.getValues(),
          ...defaultPerformanceChartState,
        });
        break;
      case GraphType.Funnel:
        form.reset({
          ...form.getValues(),
          ...defaultFunnelChartState,
        });
        break;
      default:
        exhaustiveCheck(view as never);
    }
  };

  const setLookbackWindow = (value: TimeOptions) => {
    setValue("selectedDates", []);
    setValue("timeValue", value);
  };

  const setSelectedDates = (dates: Date[]) => {
    setValue(
      "selectedDates",
      dates.map((date) => date.toISOString()),
    );
    setValue("timeValue", TimeOptions.Custom);
  };

  const graphType = form.watch("graphType");

  // Ensure that rollupFrequency is not 'all' if graphType is not breakdowns
  useEffect(() => {
    if (
      graphType !== GraphType.Breakdown &&
      form.getValues("rollupFrequency") === AnalyticsFrequency.All
    ) {
      setValue("rollupFrequency", AnalyticsFrequency.Daily);
    }
  }, [graphType]);

  const actions: AnalyticsActions = useMemo(
    () => ({
      addGroupByColumn,
      addGroupByColumns,
      removeGroupByColumns,
      resetView,
      selectParentModel,
      setLookbackWindow,
      setSelectedDates,
    }),
    [
      addGroupByColumn,
      addGroupByColumns,
      removeGroupByColumns,
      resetView,
      selectParentModel,
      setLookbackWindow,
      setSelectedDates,
    ],
  );

  return {
    form,
    ...actions,
  };
};
