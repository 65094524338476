import { Heading, Row, Spinner, useToast } from "@hightouchio/ui";
import { FC, Suspense, useEffect } from "react";
import { capitalize } from "lodash";
import { useFlags } from "launchdarkly-react-client-sdk";

import { Navigate, Outlet, useSearchParams } from "src/router";
import { useUser } from "src/contexts/user-context";
import { DetailPage } from "src/components/layout";

export const Layout: FC = () => {
  const { toast } = useToast();
  const { workspace } = useUser();
  const { appAlertingV2Eligible } = useFlags();
  const [search] = useSearchParams();

  const connectedChannel = search.get("connectedChannel");

  useEffect(() => {
    if (connectedChannel) {
      toast({
        id: "connected-channel",
        variant: "success",
        title: `Connected ${capitalize(connectedChannel)}`,
      });
    }
  }, [connectedChannel]);

  if (!workspace?.alerting_v2_enabled) {
    return appAlertingV2Eligible ? (
      <Navigate to="/alerting/enable" />
    ) : (
      <Navigate to="/" />
    );
  }

  return (
    <DetailPage
      size="full"
      title="Alerting"
      tabs={[
        { title: "Alerts", path: "alerts" },
        { title: "Recipients", path: "recipients" },
      ]}
      tabDepth={3}
      header={
        <Row pt={6}>
          <Heading size="xl">Alerting</Heading>
        </Row>
      }
      contentFullHeight
      contentFullWidth
    >
      <Suspense fallback={<Spinner size="lg" m="auto" />}>
        <Outlet />
      </Suspense>
    </DetailPage>
  );
};
