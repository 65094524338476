import { FC, useEffect, useState } from "react";

import {
  ArrowRightIcon,
  Box,
  Column,
  Combobox,
  ErrorIcon,
  GroupedCombobox,
  Row,
  SparkleIcon,
  TextInput,
  Tooltip,
  TraitIcon,
} from "@hightouchio/ui";
import { Controller, useFormContext } from "react-hook-form";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { usePermissionContext } from "src/components/permission/permission-context";
import { convertType, NEW_ICON_MAP } from "src/utils/destinations";

import {
  ExtendedOption,
  isExtendedOption,
  Option,
} from "../../../../../formkit";
import { suggest } from "../../../utils/automap";
import { ColumnOption, useFormkitContext } from "../formkit-context";
import { JsonColumnProps, MappingType } from "../types";
import { resolveFromValue } from "../utils";
import { Mapper } from "./mapper";
import { GetIsValidProps } from "./mappings";
import { MappingsHeader } from "./mappings-header";
import { TypeSelect } from "./type-select";

type Props = {
  name: string;
  options?: ExtendedOption[];
  /**
  @default Columns in the model. Use ExtendedOptions for overriding that from model from destination.
  */
  fromOptions?: ExtendedOption[];
  /**
   * Type of mapping override for the left side of the mapping.
   */
  fromType?: "destinationOnlyMapping";
  /**
  @default Name of model
  */
  fromLabel?: string;
  /**
  @default Icon of source
  */
  fromIcon?: string;
  fromLoadingOptions?: boolean;
  fromReloadOptions?: () => void;
  creatable?: boolean;
  /** `creatableTypes` are the types the user can select from when creating their own field. */
  creatableTypes?: Option[];
  loading?: boolean;
  object?: string;
  error?: string;
  reload?: () => void;
  advanced?: boolean;
  mappingTypes?: MappingType[];
  templates?: { name: string; description: string; placeholders?: string[] }[];
};

const retrieveErrorMessage = (errors: any, name: string): string => {
  for (const direction of ["from", "to"]) {
    const mappingPath = `${name}.${direction}`;
    const errorMessage = errors?.[mappingPath];
    if (typeof errorMessage === "string") {
      return errorMessage.replace(mappingPath, "This");
    }
  }
  return "";
};

/**
 * XXX: This is for making the options backward compatible
 *      since the new hightouchui needs the options to be a grouped options only.
 */
const toGroupedOption = (
  options: (ColumnOption | ExtendedOption)[],
  ungroupedOptionsLabel: string,
): { label: string; options: (ColumnOption | ExtendedOption)[] }[] => {
  const groupedOptions: {
    label: string;
    options: (ColumnOption | ExtendedOption)[];
  }[] = [];
  const unGroupedOptions: (ColumnOption | ExtendedOption)[] = [];

  for (const option of options) {
    if (!isExtendedOption(option))
      groupedOptions.push({
        label: option.label,
        options: option.options || [],
      });
    else unGroupedOptions.push(option);
  }

  if (unGroupedOptions.length)
    groupedOptions.push({
      label: ungroupedOptionsLabel,
      options: unGroupedOptions,
    });

  return groupedOptions;
};

export const Mapping: FC<Readonly<Props>> = ({
  name,
  options,
  fromType,
  fromOptions,
  fromLabel,
  fromIcon,
  fromLoadingOptions,
  fromReloadOptions,
  creatable,
  creatableTypes,
  loading,
  reload,
  error,
  advanced = false,
  mappingTypes,
  templates,
}) => {
  const { columns: formkitColumns, model } = useFormkitContext();
  const columns = fromOptions ? fromOptions : formkitColumns;
  const { destinationDefinition, errors, sourceDefinition } =
    useDestinationForm();
  const { watch, setValue } = useFormContext();

  const [jsonColumnProperties, setJsonColumnProperties] =
    useState<JsonColumnProps>({
      selectedColumnProps: undefined,
      allColumnsProps: undefined,
    });

  const value = watch(name);

  useEffect(() => {
    if (!value) {
      return setValue(name, {});
    }
  }, [value]);

  const permission = usePermissionContext();

  const getIsValid = ({
    errors,
    mappingDirection,
    mappingName,
  }: GetIsValidProps): boolean => {
    const error = errors?.[`${mappingName}.${mappingDirection}`];
    return Boolean(error);
  };

  if (!value) {
    return null;
  }

  return (
    <Column aria-label={`${name} mapping`} gap={3}>
      <Box
        alignItems="center"
        display="grid"
        gap={4}
        gridTemplateColumns="minmax(0, 1fr) 32px minmax(0, 1fr) 24px 24px"
      >
        <MappingsHeader
          fromIcon={fromIcon}
          fromLabel={fromLabel}
          fromLoadingOptions={fromLoadingOptions}
          fromReloadOptions={fromReloadOptions}
          loading={loading}
          reload={reload}
          usingCustomFromOptions={Boolean(fromOptions)}
        />
      </Box>
      <Box>
        <Controller
          name={name}
          render={(data) => {
            const destinationOptions = [...(options || [])];

            const isDestinationOptionExists = destinationOptions.some(
              (option) => option.value === data.field.value?.to,
            );

            // If mapping destination is selected, but option doesn't exist, this value was entered by the user
            const isCustomDestinationOption = Boolean(
              data.field.value?.to && !isDestinationOptionExists,
            );

            if (isCustomDestinationOption) {
              destinationOptions.push({
                label: data.field.value?.to,
                value: data.field.value?.to,
              });
            }

            return (
              <Box
                alignItems="center"
                display="grid"
                gap={4}
                gridTemplateColumns="minmax(0, 1fr) 32px minmax(0, 1fr) 24px 24px"
              >
                {advanced ? (
                  <Mapper
                    isDisabled={permission.unauthorized}
                    isError={getIsValid({
                      errors,
                      mappingDirection: "from",
                      mappingName: data.field.name,
                    })}
                    jsonColumnProperties={jsonColumnProperties}
                    mappingTypes={mappingTypes}
                    onChangeJsonColumnProperties={setJsonColumnProperties}
                    onReloadEligibleInlineMapperColumns={() => {}}
                    placeholder={`Select a field from ${sourceDefinition?.name}`}
                    selectedOption={undefined}
                    templates={templates ?? []}
                    value={data.field.value || {}}
                    onChange={(value) => {
                      if (
                        !data.field.value?.to &&
                        destinationOptions?.length &&
                        value.type === MappingType.STANDARD
                      ) {
                        const fieldName = resolveFromValue(value.from);
                        data.field.onChange(
                          suggest(
                            { label: fieldName, value: value.from },
                            destinationOptions,
                          ),
                        );
                      } else {
                        data.field.onChange({
                          to: data.field.value?.to,
                          object: data.field.value?.object,
                          ...value,
                        });
                      }
                    }}
                  />
                ) : columns.length > 0 || model ? (
                  <GroupedCombobox
                    isDisabled={permission.unauthorized}
                    isInvalid={getIsValid({
                      errors,
                      mappingDirection: "from",
                      mappingName: data.field.name,
                    })}
                    optionAccessory={(option) => {
                      if (
                        option?.value?.["type"] === "additionalColumnReference"
                      ) {
                        return {
                          icon: TraitIcon,
                          type: "icon",
                        };
                      } else if (option?.value?.["type"] === "boosted") {
                        return {
                          icon: SparkleIcon,
                          type: "icon",
                        };
                      } else if (option?.["customType"] || option?.["type"]) {
                        return {
                          icon: NEW_ICON_MAP[
                            convertType(
                              option?.["customType"] || option?.["type"],
                            )
                          ],
                          type: "icon",
                        };
                      }
                      return undefined;
                    }}
                    optionDescription={(option) => {
                      return option.modelName ?? "";
                    }}
                    optionGroups={toGroupedOption(
                      columns,
                      fromLabel ?? "Columns",
                    )}
                    optionLabel={(option) => {
                      return isExtendedOption(option) && option.object?.label
                        ? `${option.label} (${option.object.label})`
                        : option.label;
                    }}
                    placeholder={
                      data.field.value?.from
                        ? ""
                        : `Select a field from ${sourceDefinition?.name}`
                    }
                    value={data.field.value?.from}
                    width="100%"
                    onChange={(value) => {
                      if (fromType) {
                        data.field.onChange({
                          ...data.field.value,
                          from: value || undefined,
                          type: fromType,
                        });
                      } else {
                        data.field.onChange({
                          ...data.field.value,
                          from: value || undefined,
                        });
                      }
                    }}
                  />
                ) : (
                  <TextInput
                    placeholder={
                      data.field.value?.from
                        ? ""
                        : `Enter a field from ${sourceDefinition?.name}`
                    }
                    value={data.field.value?.from}
                    width="100%"
                    onChange={({ target: { value } }) => {
                      if (fromType) {
                        data.field.onChange({
                          ...data.field.value,
                          from: value || undefined,
                          type: fromType,
                        });
                      } else {
                        data.field.onChange({
                          ...data.field.value,
                          from: value || undefined,
                        });
                      }
                    }}
                  />
                )}

                <Column gap={2} fontSize="xl" color="gray.600">
                  <ArrowRightIcon />
                </Column>

                <Column gap={2} position="relative">
                  {creatable ? (
                    <>
                      <Combobox
                        emptyOptionsMessage="Type in a field name and Hightouch will create that field in the destination"
                        isClearable
                        isDisabled={permission.unauthorized}
                        isInvalid={getIsValid({
                          errors,
                          mappingDirection: "to",
                          mappingName: data.field.name,
                        })}
                        optionAccessory={(option) => {
                          return option?.extendedType?.type
                            ? {
                                type: "icon",
                                icon: NEW_ICON_MAP[option.extendedType.type],
                              }
                            : undefined;
                        }}
                        optionLabel={(option) => {
                          return option.object?.label
                            ? `${option.label} (${option.object.label})`
                            : option.label;
                        }}
                        options={destinationOptions}
                        placeholder={
                          data.field.value?.to
                            ? ""
                            : `Select or enter a field from ${destinationDefinition?.name}`
                        }
                        supportsCreatableOptions
                        value={data.field.value?.to}
                        width="100%"
                        onChange={(value) => {
                          const option = (options || []).find(
                            (option) => option.value === value,
                          );

                          data.field.onChange({
                            ...data.field.value,
                            fieldType: option?.extendedType?.type || undefined,
                            to: value || undefined,
                          });
                        }}
                        onCreateOption={(inputValue) => {
                          data.field.onChange({
                            ...data.field.value,
                            to: inputValue || undefined,
                          });
                        }}
                      />

                      {creatableTypes && (
                        <TypeSelect
                          isDisabled={permission.unauthorized}
                          options={creatableTypes}
                          placeholder="Select a field type"
                          value={data.field.value}
                          created={!isCustomDestinationOption}
                          width="100%"
                          onTypeCreate={(value, typeOptions) => {
                            data.field.onChange({
                              ...data.field.value,
                              fieldType: value,
                              typeOptions,
                            });
                          }}
                        />
                      )}
                    </>
                  ) : (
                    <Combobox
                      isDisabled={permission.unauthorized}
                      isInvalid={getIsValid({
                        errors,
                        mappingDirection: "to",
                        mappingName: data.field.name,
                      })}
                      optionAccessory={(option) => {
                        return option?.extendedType?.type
                          ? {
                              type: "icon",
                              icon: NEW_ICON_MAP[option.extendedType.type],
                            }
                          : undefined;
                      }}
                      optionLabel={(option) => {
                        return option.object?.label
                          ? `${option.label} (${option.object.label})`
                          : option.label;
                      }}
                      options={destinationOptions}
                      placeholder={
                        data.field.value?.to
                          ? ""
                          : `Select a field from ${destinationDefinition?.name}`
                      }
                      value={data.field.value?.to}
                      width="100%"
                      onChange={(value) => {
                        data.field.onChange({
                          ...data.field.value,
                          to: value || undefined,
                        });
                      }}
                    />
                  )}
                </Column>
                {(retrieveErrorMessage(errors, name) || error) && (
                  <Tooltip
                    message={retrieveErrorMessage(errors, name) || error || ""}
                  >
                    <Row fontSize="24px">
                      <ErrorIcon color="danger.600" />
                    </Row>
                  </Tooltip>
                )}
              </Box>
            );
          }}
        />
      </Box>
    </Column>
  );
};
