import { createContext, ReactElement, ReactNode } from "react";

import { FieldValues, FormProvider, UseFormReturn } from "react-hook-form";

export type UseHightouchFormReturn<T extends FieldValues, R> = {
  submit: () => Promise<R | undefined>;
} & UseFormReturn<T>;

type HightouchFormContextType<R = any> = {
  submit: () => Promise<R>;
};

export const HightouchFormContext = createContext<HightouchFormContextType>(
  {} as any,
);

export const Form = <T extends FieldValues, R>({
  form,
  children,
}: {
  form: UseHightouchFormReturn<T, R>;
  children: ReactNode;
}): ReactElement => {
  const { submit, ...context } = form;

  return (
    <HightouchFormContext.Provider value={{ submit }}>
      <FormProvider {...context}>{children}</FormProvider>
    </HightouchFormContext.Provider>
  );
};
