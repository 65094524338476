import { FC, useEffect, useState } from "react";

import {
  Row,
  Column,
  Button,
  EditableHeading,
  RefreshIcon,
  Dialog,
  ButtonGroup,
} from "@hightouchio/ui";
import { isEqual, omit, omitBy } from "lodash";
import { useParams } from "src/router";

import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { SourceForm } from "src/components/sources/setup";
import { Testing } from "src/components/sources/testing";
import { useSourceTesting } from "src/components/sources/testing/hooks";
import { Warning } from "src/components/warning";
import {
  ListSourceTestStepsQueryVariables,
  SourceQuery,
  useSourceQuery,
  useUpdateSourceV2Mutation,
} from "src/graphql";
import { BottomBar } from "src/partner/bottom-bar";
import { SourceConfig } from "src/utils/sources";
import { useUser } from "src/contexts/user-context";
import {
  DEFAULT_LIGHTNING_SCHEMA_CONFIG,
  WarehouseSchemaConfig,
} from "src/components/sources/setup/form-method";

const Loader: FC = () => {
  const { id } = useParams<{ id: string }>();

  const { data: source } = useSourceQuery(
    { id: String(id) },
    {
      enabled: Boolean(id),
      select: (data) => data.connections_by_pk,
      suspense: true,
    },
  );

  if (!id) {
    return null;
  }

  if (!source) {
    return (
      <Warning subtitle="It may have been deleted" title="Source not found" />
    );
  }

  return <Source source={source} />;
};

const Source: FC<
  Readonly<{ source: NonNullable<SourceQuery["connections_by_pk"]> }>
> = ({ source }) => {
  const { user } = useUser();
  const [config, setConfig] = useState<SourceConfig | undefined>();
  const [tunnelId, setTunnelId] = useState<string | null>();
  const [credentialId, setCredentialId] = useState<string | undefined>();
  const [testConnectionModalOpen, setTestConnectionModalOpen] = useState(false);
  const [name, setName] = useState(source?.name ?? undefined);

  const [lightningEnabled, setLightningEnabled] = useState<
    boolean | undefined
  >();
  const [plannerDatabase, setPlannerDatabase] = useState<string | undefined>();
  const [schema, setSchema] = useState<WarehouseSchemaConfig>(
    DEFAULT_LIGHTNING_SCHEMA_CONFIG,
  );
  const [lightningSchemaMode, setLightningSchemaMode] = useState<
    "shared" | "separate" | undefined
  >();

  const {
    results: testResults,
    steps: testSteps,
    getTestSteps,
    runTest,
    cancelRunTest,
    timeElapsed,
  } = useSourceTesting();

  useEffect(() => {
    if (!testConnectionModalOpen) cancelRunTest();
  }, [testConnectionModalOpen]);

  const { isLoading: updateLoading, mutateAsync: updateSource } =
    useUpdateSourceV2Mutation();

  const reset = () => {
    setConfig(source?.config ?? undefined);
    setTunnelId(source?.tunnel?.id ?? undefined);
    setCredentialId(source?.credential_id ?? undefined);
    setName(source?.name ?? "");
    setLightningEnabled(source?.plan_in_warehouse ?? undefined);
    setPlannerDatabase(
      source?.plan_in_warehouse_config?.plannerDatabase ?? undefined,
    );

    const schema =
      source?.plan_in_warehouse_config?.schema ??
      DEFAULT_LIGHTNING_SCHEMA_CONFIG;
    const separateSchema = new Set(Object.values(schema)).size > 1;
    setSchema(schema);
    setLightningSchemaMode(separateSchema ? "separate" : "shared");
  };

  const save = async () => {
    let updatedConfig = config;

    if (tunnelId) {
      updatedConfig = {
        ...updatedConfig,
        host: null,
        server: null,
        port: null,
      };
    }

    await updateSource({
      id: String(source.id),
      source: {
        name,
        updated_by: user?.id != null ? String(user?.id) : undefined,
        tunnel_id: tunnelId ? tunnelId : null,
        credential_id: credentialId != null ? String(credentialId) : undefined,
        config: updatedConfig,
        plan_in_warehouse: lightningEnabled || undefined,
        plan_in_warehouse_config: lightningEnabled
          ? { plannerDatabase, schema }
          : undefined,
      },
    });
  };

  const configChanged = !isEqual(
    config
      ? omit(
          omitBy(config, (v) => v === undefined),
          ["methodKey"],
        )
      : undefined,
    omit(source.config, ["methodKey"]) ?? undefined,
  );
  const tunnelChanged = !isEqual(tunnelId, source.tunnel?.id ?? undefined);
  const credentialChanged = !isEqual(
    credentialId,
    source.credential_id ?? undefined,
  );
  const nameChanged = name !== source.name;
  const lightningEnabledChanged = !isEqual(
    lightningEnabled,
    source.plan_in_warehouse ?? undefined,
  );
  const plannerDatabaseChanged = !isEqual(
    plannerDatabase,
    source.plan_in_warehouse_config?.plannerDatabase ?? undefined,
  );
  const schemaConfigChanged = !isEqual(
    schema,
    source.plan_in_warehouse_config?.schema ?? undefined,
  );
  const dirty =
    tunnelChanged ||
    configChanged ||
    credentialChanged ||
    nameChanged ||
    lightningEnabledChanged ||
    plannerDatabaseChanged ||
    schemaConfigChanged;
  const complete = tunnelId !== null;

  const variables: ListSourceTestStepsQueryVariables = {
    sourceType: source.definition?.type,
    sourceId: String(source.id),
    configuration: config,
    credentialId: credentialId ? Number(credentialId) : undefined,
    tunnelId: tunnelId ? String(tunnelId) : undefined,
    warehousePlanConfig: lightningEnabled
      ? { plannerDatabase, schema }
      : undefined,
  };

  useEffect(() => {
    reset();
  }, [source]);

  return (
    <>
      <Column flex={1} width="100%">
        <Column flex={1} maxW="800px" width="100%" p={6} pb={10} gap={6}>
          <Row gap={4} align="center">
            <IntegrationIcon
              src={source.definition.icon}
              name={source.definition.name}
            />
            <EditableHeading
              size="lg"
              value={source?.name ?? ""}
              onChange={setName}
            />
          </Row>

          <Column flex={1}>
            {source.definition && (
              <SourceForm
                //Cheeky way to reset child componenents to initial state after save. (EX: Reseting senstive field edit state)
                key={`${updateLoading}`}
                hideSecret
                config={config}
                credentialId={credentialId}
                definition={source.definition}
                hasSetupLightning={Boolean(source.plan_in_warehouse)}
                isSetup={false}
                lightningEnabled={lightningEnabled}
                plannerDatabase={plannerDatabase}
                setConfig={setConfig}
                setCredentialId={setCredentialId}
                setLightningEnabled={setLightningEnabled}
                setPlannerDatabase={setPlannerDatabase}
                schema={schema}
                setSchema={setSchema}
                lightningSchemaMode={lightningSchemaMode}
                setLightningSchemaMode={setLightningSchemaMode}
                setTunnelId={setTunnelId}
                sourceId={source.id}
                tunnelId={tunnelId}
              />
            )}
          </Column>
        </Column>
      </Column>

      <BottomBar>
        <Button
          size="lg"
          variant="primary"
          isDisabled={!dirty || !complete}
          isLoading={updateLoading}
          onClick={save}
        >
          Save changes
        </Button>
        {!source.definition?.disableTest && config ? (
          <Button
            size="lg"
            isDisabled={!complete}
            onClick={async () => {
              await getTestSteps(variables);
              runTest(variables);
              setTestConnectionModalOpen(true);
            }}
          >
            Test connection
          </Button>
        ) : null}
      </BottomBar>

      <Dialog
        isOpen={testConnectionModalOpen}
        variant="form"
        title={`Test connection to ${source.definition?.name}`}
        actions={
          <ButtonGroup>
            <Button
              variant={
                testResults?.success !== false && !dirty
                  ? undefined
                  : "secondary"
              }
              onClick={() => {
                setTestConnectionModalOpen(false);
              }}
            >
              Close
            </Button>
            {testResults?.success === false ? (
              <Button icon={RefreshIcon} onClick={() => runTest(variables)}>
                Test again
              </Button>
            ) : dirty ? (
              <Button
                isDisabled={!testResults?.success}
                isLoading={updateLoading}
                onClick={async () => {
                  await save();
                  setTestConnectionModalOpen(false);
                }}
              >
                Save changes
              </Button>
            ) : null}
          </ButtonGroup>
        }
        onClose={() => {
          setTestConnectionModalOpen(false);
        }}
      >
        <Testing
          config={config}
          credentialId={credentialId}
          isSetup={false}
          plannerDatabase={undefined}
          results={testResults}
          sourceDefinition={source.definition}
          steps={testSteps}
          timeElapsed={timeElapsed}
        />
      </Dialog>
    </>
  );
};

export default Loader;
